.Popup-wrap {
  position: absolute;
  background: #141a29;
  padding: 25px;
  top: 130px;
  left: 18%;
  .popupDashboardWrapper {
    background: #141a29;
    width: 200px;

    .head_contain {
      position: relative;

      .arrowSvg {
        position: absolute;
        left: -14px;
        top: -34px;
        transform: rotate(90deg);
        .save-icon {
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            color: #fff;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px; /* 100% */
          }
        }

        // &.active-link {
        //   // Style for the active link
        //   background-color: $active-color;
        //   font-weight: bold; // Example styling for active link
        // }

        .dup-icon {
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            color: #fff;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px; /* 100% */
          }
        }
        .delete-icon {
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            color: #fff;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px; /* 100% */
          }
        }
      }
    }

    .sec-contain {
      .save-icon {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 12px;

        p {
          color: #fff;
          font-family: "Inter";
          font-size: 14px;
          font-weight: 400;
          line-height: 14px; /* 100% */
        }
        cursor: pointer;

        &:hover {
          // Define styles for the hover state
          background-color: $active-color;

          // Add any other styles you want for the hover effect
        }
      }

      .dup-icon {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 12px;
        p {
          color: #fff;
          font-family: "Inter";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px; /* 100% */
        }
        cursor: pointer;
        &:hover {
          background-color: $active-color;
        }
      }
      .delete-icon {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        p {
          color: #fff;
          font-family: "Inter";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px; /* 100% */
        }
        cursor: pointer;
        &:hover {
          background-color: $active-color;
        }
      }
    }
  }
}
