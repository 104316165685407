.delete_popup {
  background: $white-color !important;
  border-radius: 10px !important;
  padding: 30px !important;

  h5 {
    color: #141a29;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin-top: 15px;
  }
  p {
    color: #141a29;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
  .img_contain {
    text-align: center;
    width: 100%;
    .svg_trash {
      padding: 10px 28px;
      background-color: rgba(255, 111, 97, 0.1411764706);
      display: inline-block;
      border-radius: 8px;
    }
  }
  .bt_flex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 15px 30px;

    .delete-btn {
      background-color: #ff6f61;
      width: 108px;
      height: 36px;
      border: none;
      color: $white;
      font-weight: 600;
      outline: none;
    }
    .cancel-btn {
      width: 108px;
      height: 36px;
      border: 1px solid $navy-blue-color;
      color: $navy-blue-color;
      font-weight: 600;
      background-color: $white-color;
    }
  }
}
.share_popup {
  background: $white-color;
  border: 1.5px solid #c5c5ff;
  border-radius: 10px !important;
  padding: 40px 30px !important;
  width: 710px;
  height: 340px;

  .main_share_container {
    color: #141a29;
    .mainShare {
      .h2Head {
        font-family: "Montserrat";
        font-size: 28px;
        font-weight: 600;
        line-height: 22px;
        text-align: left;
        margin-bottom: 40px;
      }
      .lab_input {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        label {
          margin-bottom: 15px;
          font-family: "Montserrat";
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          text-align: left;
          color: #141a29;
        }
        .shareInput {
          padding: 10px 20px;
          width: 100%;
          border: 1.5px solid #c5c5ff;
          background: #f8f8ff;
        }
        margin-bottom: 20px;
      }
      .btn-Div {
        width: 100%;
        .shareBtns {
          background-color: $navy-blue-color;
          width: 100%;
          color: #fff;
          padding: 10px;
          font-family: "Montserrat";
          font-size: 18px;
          font-weight: 700;
          line-height: 22px;
          text-align: center;
          border: none;
          outline: none;
          padding: 15px 20px;
          border-radius: 6px;
        }
      }
    }
  }
}

.delete_popup {
  svg {
    fill: #fff;
    width: 20px;
  }
}
