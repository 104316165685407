/* General Modal Styles */
.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Overlay to dim the background */
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

/* Modal Content */
.modal-content {
  position: relative;
  z-index: 2;
  background: #ffffff;
  color: #000000;
  border-radius: 8px;
  width: 80%;
  max-width: 900px;
  height: 80%;
  max-height: 600px;
  display: flex;
  //   padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  flex-wrap: wrap;
}

/* Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  color: #070707;
  font-size: 24px;
  border: none;
  cursor: pointer;
}

/* Left Section: Icon Libraries */
.left-section {
  width: 30%;
  background: #1a237e;
  padding: 20px;
  height: 100%;
  //   border-right: 2px solid #c5c5ff;
  overflow-y: scroll;
  .icon-libraries {
    list-style: none;
    padding: 0;

    li {
      padding: 10px 15px;
      cursor: pointer;
      border-radius: 4px;
      font-weight: bold;
      margin-bottom: 5px;
      color: #fff;

      &:hover,
      &.active {
        background: #ffff;
        color: #1a237e;
      }
    }
  }
}

/* Right Section: Search and Icons */
.right-section {
  width: 70%;
  padding: 20px;

  .search-bar {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;

    input {
      width: 60%;
      padding: 8px;
      border-radius: 4px;
      border: none;
      background: #c1cade;
      color: #ffffff;

      &:focus {
        outline: 2px solid #1a237e;
      }
    }
  }

  .icons-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    gap: 15px;
    overflow-y: auto;

    .icon-item {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffff;
      border: 1px solid #1a237e;
      border-radius: 4px;
      cursor: pointer;
      height: 50px;
      width: 53px;
      font-size: 24px;

      &:hover {
        background: #d5d9e7;
        border: 1px solid #d5d9e7;
      }
    }
  }
}

/* Open Modal Button */
.open-modal-btn {
  background-color: #1a237e;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #3949ab;
  }
}
