.srcoll_section {
  padding: 40px 0;
  .md_text {
    text-align: center;
    p {
      font-family: "Montserrat";
      font-size: 14px;
      font-weight: 600;
      color: $blue-color;
    }
    h3 {
      font-family: "Montserrat";
      font-size: 40px;
      font-weight: 700;
      text-transform: capitalize;
      color: $black-color;
    }
  }
  .react-multi-carousel-list {
    ul {
      li {
        width: 210px !important;
      }
    }
    .slide_image {
      transform: scale(0.9);
      transition: 0.3s ease-in-out;
    .slide_image img:hover {
      transform: scale(1.1);
      transition: 0.3s ease-in-out;
          }

    }
  }
}
