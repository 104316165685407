.main {
  background: $off-white-color;
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 30px;

  @media screen {
  }

  .p-icon {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    align-items: center;

    .head {
      color: #141a29;
      p {
        color: #141a29;
        font-family: "Inter";
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
      }
    }

    .img-btn-box {
      button {
        background: transparent;
        border: none;
        color: #141a29;
        width: 305px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 18px;
        font-weight: 700;
        padding-top: 4px;
        border-radius: 4px;
        svg {
          font-size: 28px;
        }
      }
    }
  }

  .container h2 {
    color: #fff;

    font-size: 26px;

    font-weight: 600;
    line-height: 46px; /* 176.923% */
  }
  .container p {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    line-height: 10px; /* 55.556% */
  }
}
::-webkit-scrollbar {
  display: none;
}
