.h2 {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #283249;
}
.clone_para {
  font-family: "Inter";
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 34px !important;
  text-align: left;
  color: #283249;

  a {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 600;
    line-height: 34px;
    text-align: left;
    text-decoration: underline;
    color: #0aabe6;
    text-decoration-thickness: 2px;
  }
}
.video_iframe {
  width: 100%;
  height: 400px;
  max-width: 1280px;
  margin: 0 auto;
}
.clone_head {
  font-family: "Inter";
  font-size: 21px;
  font-weight: 600;
  // line-height: 40px;
  text-align: left;
  color: #283249;
  padding: 15px 0px;
  margin-bottom: 0px;
}
.clone_heading {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #000000;
}
.img_for_dashboard {
  max-width: 100%;
  background-image: cover;
  background-repeat: no-repeat;
  .img-for-dashboardDocs {
    width: 100%;
    height: auto;
  }
}
