// PreviewWrapper.scss

.preview-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin: 20px;
  
    .mode-buttons {
      // margin-bottom: 10px;
  
      .icon-button {
        margin-right: 5px;
  
        svg {
          font-size: 18px;
        }
      }
  
      .draft-mode {
        background-color: #404b69;
        border-radius: 3px 0 0 3px;
        padding: 5px;
        color: $white-color;
  
        &:hover {
          background-color: #3366ff;
        }
  
        &.active {
          background-color: #3366ff;
        }
      }
  
      .web-mode {
        background-color: #404b69;
        border-radius: 0px;
        padding: 5px;
        color: $white-color;
  
        &:hover {
          background-color: #3366ff;
        }
  
        &.active {
          background-color: #3366ff;
        }
      }
    }
  }
  