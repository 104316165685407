.navbar_wrapper {
  color: #002522;
  position: sticky;
  top: 0;
  padding: 10px 60px;
  background-color: #42a5f5;
  z-index: 22;

  @media (max-width: 576px) {
    padding: 10px;
  }
}

.top-line {
  border-top: 1px solid #6cb3ef;
}

.navbar_menu_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .navbar_logo {
    @media (max-width: 1023px) {
      display: none;
    }
  }
  // .search_navlist {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   gap: 128px;

  .search_container {
    width: 28%;
    p {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      text-align: left;
    }
    .input_bar {
      border-radius: 4px;
      border: solid 1px #ffffff;
      outline: none;
      display: flex;
      justify-content: space-between;
      box-shadow: 0 0 0 4px rgba(66, 165, 245, 0.5019607843);
      align-items: center;
      border: none;
      outline: none;
      max-width: 100%;
      position: relative;
      // left: 88px;
      border: 1px solid #fff;
      padding: 10px;
      img svg {
        fill: white;
      }

      .search_bar {
        position: relative;
        // left: 214px;
      }
      // @media (max-width: 1399px) {
      //   left: 22px;
      // }
    }

    @media (max-width: 1023px) {
      display: none;
    }
  }
  // .responsive_logo {
  //   @media (max-width: 2174px) {
  //     display: none;
  //   }

  //   @media (max-width: 1023px) {
  //     display: block;
  //   }
  // }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 15px;
  }
}

.humburger_bar {
  display: none !important;
  color: #fff;

  @media (max-width: 1023px) {
    display: block;
    font-size: 24px;
  }
}

.fa-times {
  display: none;

  @media (max-width: 1023px) {
    display: block;
    font-size: 24px;
  }
}

.responsivenavbar-wrapper {
  display: none;

  @media (max-width: 1023px) {
    display: block;
    background-color: #42a5f5;
    color: #fff;
    height: 100%;
    left: -400px;
    padding: 10px 30px;
    position: fixed;
    top: 0;
    transition: all 0.5s ease;
    width: 400px;
    z-index: 5;
  }
  @media (max-width: 1023px) {
    display: block;
  }

  .navbar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .responsive-list {
    display: block !important;
    margin: 30px 0;

    .responsive-item {
      padding: 10px 0px !important;
      .navbar_list {
        display: flex;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;
        // gap: 30px;

        @media (max-width: 1023px) {
          display: none;
        }

        .navbar_item {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;

          a {
            color: #fff;
            font-family: "Inter";
            font-size: 18px;
            font-weight: 600;
            line-height: 26px;
            text-align: left;
          }

          @media (max-width: 1199px) {
            padding: 0px 15px;
          }

          .navbar_link {
            font-size: 18px;
            font-weight: 300;
            line-height: 22px;
            text-align: left;
            color: #fff;
            text-decoration: none;
            cursor: pointer;

            @media (max-width: 1045px) {
              font-size: 15px;
            }
            &:hover {
              color: #000;
            }
          }

          .active_link {
            color: #fff;
            font-weight: 500;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.active-navbar {
  @media (max-width: 1023px) {
    left: 0px;
    box-shadow: 0px 4px 63px 0px rgba(69, 162, 158, 0.1490196078);
  }

  @media (max-width: 481px) {
    width: 100%;
  }
}

.navbar_list {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 30px;
  @media (max-width: 1299px) {
    gap: 0;
  }
  @media (max-width: 1023px) {
    display: none;
  }

  .navbar_item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;

    a {
      color: #fff;
      font-family: "Inter";
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;
      text-align: left;
    }

    @media (max-width: 1199px) {
      padding: 0px 15px;
    }

    .navbar_link {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      text-align: left;
      color: #fff;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: #000;
      }

      @media (max-width: 1399px) {
        font-size: 12px;
      }
    }

    .active_link {
      color: #fff;
      font-weight: 500;
      text-decoration: none;
    }
  }
}
// }
.navbar_popup {
  color: #002522;
  position: sticky;
  top: 0;
  padding: 10px 60px;
  background-color: #42a5f5;
  display: none;
  @media (max-width: 576px) {
    padding: 10px;
  }
  @media (max-width: 1023px) {
    display: block;
  }
}
.navbar_popup_top {
  .buttom_nav {
    width: 100%;
    button {
      width: 100%;
      border: none;
      background-color: #3b94df;
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 6px;
      color: #fff;
    }
  }
}
.popup_wrapper {
  position: absolute;
  height: 100vh;
  top: 48px;
  left: 32%;
  transform: translateX(-50%);
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  border-radius: 8px;
  overflow: scroll;

  .popup_content {
    margin-top: 20px;
    .nav-item {
      .nav-link {
        font-family: "Inter";
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #676767;
        &:hover {
          color: #ffff;
          border-radius: 6px;
          border: 1px;
          background-color: #1a237e;
        }
      }
      .nav-link.special {
        font-family: "Inter";
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        text-align: left;
        color: #1a237e;
        &:hover {
          color: #ffff;
        }
      }
    }
  }
}
.search_wrapper {
  position: absolute;
  height: 70vh;
  width: 40%;
  top: 40px;
  left: 31%;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 8px;

  @media (max-width: 1423px) {
    width: 70%;
    left: 16%;
  }
  @media (max-width: 576px) {
    width: 92%;
    left: 4%;
  }

  .search_menu {
    position: relative;
    padding: 8px 20px;
    .search_img {
      position: absolute;
      left: 32px;
      top: 20px;
    }
    input {
      border: 2px solid #42a5f5;
      outline: none;
      width: 100%;
      padding: 6px 38px;
      border-radius: 5px;
      box-shadow: 0 0 0 4px rgba(66, 165, 245, 0.5019607843);
    }
  }
}
.border_bottom {
  position: absolute;
  top: 98px;
  border: 1px solid #00000024;
  width: 100%;
}
.popup_search_content {
  position: absolute;
  height: 75vh;
  width: 100%;
  top: 100px;
  left: 0%;
  /* transform: translateX(-8%); */
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 8px;
  overflow: scroll;

  .suggestions {
    padding: 0px 5px;
    li {
      line-height: 40px;
      font-size: 16px;
      font-weight: 500;
      padding: 4px 20px;
    }
    :hover {
      background-color: #f0f0f0;
      border-radius: 4px;
    }
    .highlight {
      text-decoration: underline;
      text-decoration-color: #42a5f5;
      text-decoration-thickness: 2px;
      font-weight: bold;
    }
  }
}
