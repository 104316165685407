.main-leftbar {
  background-color: $off-white-color;
  height: 100%;
  .screen-sec,
  .folder_sec {
    .inner {
      display: flex;
      justify-content: space-between;
      color: $white-color;
      align-items: center;
      background-color: $cloud-color;
      padding: 0px 5px;
      height: 30px;
      .title {
        p {
          margin-bottom: 0px;
          font-size: 14px;
        }
      }

      .icn {
        display: flex;
        align-items: center;
        position: relative;

        span {
          cursor: pointer;

          svg {
            font-size: 22px;
            padding: 3px;

            &:hover {
              background-color: $navy-blue-color;
              border-radius: 50px;
            }
          }
        }
        .popupDiv {
          position: absolute;
          left: 60px;
          top: 0px;
          z-index: 10;
        }
      }
    }

    .list-sec {
      color: #fff;
      width: 100%;
      height: 18rem;

      .inner_list {
        height: 18rem;
        overflow-y: scroll;
        position: absolute;
        width: 20%;

        @media (min-width: "5000px") {
          width: 8%;
        }

        @media (max-width: "1500px") {
          width: 29%;
        }

        .nav-list {
          color: #fff;
          list-style: none;
          margin-bottom: 0;
          padding: 10px 0;
          width: 100%;
          // height: 100%;
          // max-height: 18rem;
          // min-height: 12rem;

          div {
            text-decoration: none;
            color: #283249;
            font-size: 15px;
            font-weight: 500;
            line-height: 16px;
            text-align: left;
            display: flex;
            margin-bottom: 0px;
            padding-left: 15px;
            padding-right: 15px;
            height: 30px;
            align-items: center;
            justify-content: center;

            &:hover {
              &.no_active_link {
                background-color: #838ad330;
                svg {
                  path {
                    fill: #3c4c6e;
                    stroke: #3c4c6e;
                  }
                }
              }

              .options-icon {
                svg {
                  opacity: 1;
                  transition: 0.5s;
                }
              }
            }

            &.active_link {
              background-color: $navy-blue-color;
              font-weight: bold;
              svg {
                path {
                  fill: #fff;
                  stroke: #fff;
                }
              }
            }
            &.no_active_link {
              .screen_name {
                flex-basis: 90%;
                color: #3c4c6e;
              }
            }

            .screen_name {
              flex-basis: 90%;
              color: #fff;
            }

            .list-container {
              padding: 0;
              list-style: none;
            }

            .list-item {
              position: relative;
              padding: 10px;
              border: 1px solid #ccc;
              margin-bottom: 5px;
            }

            .options,
            .delete-confirmation {
              left: 271px !important;
              top: 22px !important;
              background-color: $white-color;
              border: 1px solid #1a237e54;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              padding: 8px;
              display: block;
              z-index: 1070; /* Ensure popup is above the list items */
              border-radius: 4px;
              height: 66px;

              &::before {
                content: "";
                display: none;
                background-color: $main-color;
                width: 10px;
                height: 10px;
                transform: rotate(45deg);
                position: relative;
                top: -9px;
                left: 4px;
                border: 1px solid $border-color-dash;
                mix-blend-mode: darken;
              }

              @media ($breakpoint_xxl) {
                left: 10rem !important;
              }
              @media (max-width: "1500px") {
                left: 258px !important;
              }
            }
            .options {
              // margin-top: -2rem !important;
              background-color: #1a237e;
              /* border-radius: 3px; */
              width: 112px;
              /* z-index: 100; */
              /* border-radius: 3px; */
              /* cursor: default; */
              top: 45px !important;
              //  height: 66px;
              @media (max-width: 1599px) {
                width: 112px;
              }
              .shareOptionv {
                background-color: $navy-blue-color;
                // padding: 2px 8px;
                border-radius: 6px;
                width: 100%;
                @media (max-width: 1599px) {
                  width: 100%;
                  position: absolute;
                  right: 0px;
                }

                @media (max-width: 1499px) {
                  position: absolute;
                  right: 0px;
                  width: 100%;
                }
                @media (max-width: 1399px) {
                  width: 100%;
                  position: absolute;
                  right: 0px;
                }
                .arrowsvgv {
                  position: absolute;
                  top: -14px;
                  right: 29px;

                  @media (max-width: 1599px) {
                    width: 34%;
                    right: 9px;
                    top: -22px;
                    position: absolute;
                  }
                  @media (max-width: 1499px) {
                    position: absolute;
                    width: 34%;
                    right: 24px;
                  }
                }
                svg path {
                  stroke: $navy-blue-color !important;
                  fill: $navy-blue-color !important;
                }

                .shareUlv {
                  margin-bottom: -40px;
                  @media (max-width: 1599px) {
                    margin-bottom: -43px;
                  }
                  .shareLiv {
                    color: #141a29;
                    margin-bottom: 0px;
                    padding: 8px 8px;
                    background-color: #fff;
                    margin-top: 10px;
                    font-family: "Inter";
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14px;
                    /* text-align: center; */
                    display: flex;
                    /* justify-content: flex-start; */
                    align-items: center;
                    gap: 9px;
                    //  text-align: left;
                    svg path {
                      stroke: #3c4c6e;
                      fill: #3c4c6e;
                      width: 30px;
                      height: 30px;
                    }
                  }
                }
              }
            }

            .delete-confirmation {
              position: absolute;
              left: 10rem !important;
              top: 18px !important;
              padding-left: 10px;
              width: 216px;
              height: 150px !important;
              background: #f8f8ff;
              // @media (max-width: "1500px") {
              //   position: absolute;
              //   left: 11rem !important;
              //   top: 18px !important;
              //   // padding-left: 10px;
              //   // width: 216px;
              //   height: 160px !important;
              // }
              .header_bars {
                padding-top: 0px;
                text-align: center;
                .svg_trashs {
                  padding: 19px 18px;
                  background-color: rgba(255, 111, 97, 0.1411764706);
                  display: flex;
                  border-radius: 6px;
                  // width: 10px;
                  // height: 20px;
                }
              }
              h5 {
                font-family: "Inter";
                font-size: 17px;
                font-weight: 600;
                line-height: 20.57px;
                text-align: center;
                margin-bottom: 4px;
                margin-top: 6px;
              }

              p {
                font-family: "Inter" !important;
                font-size: 13px !important;
                font-weight: 400 !important;
                text-align: center;
                margin-bottom: 10px;
              }
              @media ($breakpoint_xxl) {
                left: 5rem !important;
                top: 18px !important;
              }
              @media (max-width: 1500px) {
                position: absolute;
                left: 11rem !important;
                top: 18px !important;
                /* padding-left: 10px; */
                /* width: 216px; */
                height: 160px !important;
                width: 100%;
                max-width: 194px;
                padding: 15px 10px;
              }
              .btns_sec_delete {
                display: flex;
                gap: 6px;
                button {
                  border-radius: 3px;
                  border: none;

                  &:first-child {
                    width: 84px;
                    background-color: $red-bg;
                    color: $white;
                    padding: 5px;
                    font-family: "Inter";
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 15.73px;
                    text-align: center;
                  }

                  &:last-child {
                    width: 84px;
                    background: #f8f8ff;

                    color: #283249;
                    border: 1px solid #28324947;
                    padding: 5px;
                    font-family: "Inter";
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 15.73px;
                    text-align: center;
                  }
                }
              }

              // @media ($breakpoint_xxl) {
              //   left: 4rem !important;
              //   top: 24px;

              //   h5 {
              //     font-family: "Inter"!important;
              //     font-size: 17px!important;
              //     font-weight: 600!important;
              //     line-height: 20.57px!important;
              //     text-align: left;
              //                     }

              //   p {
              //     font-size: 12px;
              //     line-height: 20px !important;
              //     margin-bottom: 8px;
              //   }
              // }
              @media (max-width: "1500px") {
                h5 {
                  font-family: "Inter";
                  font-size: 17px;
                  font-weight: 600;
                  line-height: 20.57px;
                  text-align: center;
                  margin-bottom: 4px;
                  margin-top: 6px;
                }

                p {
                  font-family: "Inter" !important;
                  font-size: 11px !important;
                  font-weight: 400 !important;
                  text-align: center;
                  margin-bottom: 10px;
                }
              }
            }

            .options button {
              border: none;
              background: none;
              padding: 5px 10px;
              cursor: pointer;
              color: #283249;
              display: flex;
              font-size: 12px;
              align-items: center;
              gap: 8px;
              position: relative;
              top: -3px;
              width: 100%;

              svg {
                font-size: 14px;

                path {
                  stroke: #3c4c6e;
                  fill: #3c4c6e;
                }
              }
            }

            .options button:hover {
              background-color: $navy-blue-color;
              color: $white;

              svg {
                path {
                  stroke: #fff;
                  fill: #fff;
                }
              }
            }

            .options-icon {
              flex-basis: 10%;
              transform: translateY(-50%);
              cursor: pointer;

              svg {
                position: relative;
                top: 13px;
                opacity: 0;
              }
            }

            .options-icon span {
              font-size: 18px;
              color: white;
            }

            // @media ($breakpoint_xxl) {
            //   width: 414px;

            // }
          }
        }
      }
    }
    .orders-sec {
      height: calc(100vh - 420px);
      overflow-y: scroll;

      @media (min-width: 5000px) {
        height: 100%;
      }
      @media (max-width: 1399px) {
        height: calc(100vh - 520px);
      }
      // @media (max-width: "1500px") {
      //   height: 300px;
      // }
      @media ($breckpoint_XXL) {
        height: calc(100vh - 420px);
      }
      .orders-list {
        .MuiTree-label2 {
          background-color: #3366ff;
          position: relative;
        }
        .MuiBox-root.css-hytcpw {
          .hover_box {
            .mouseEvnt {
              text-decoration: none;
              color: $white-color;
              font-size: 14px;
              width: 100%;
              display: flex;
              margin-bottom: 0px;
              height: 36px;
              align-items: center;
              // position: absolute;

              .eye_option_flex {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                .icon-wrapper {
                  position: relative;
                }
              }
              &:hover {
                background-color: $active-color;
              }

              &.active-link {
                // Style for the active link
                background-color: $active-color;
                font-weight: bold;
              }

              img {
                padding: 10px;
              }
            }
          }
          color: white;
          max-width: 100%;
          .css-muqfoy-MuiTreeItem-root {
            .MuiTreeItem-group {
              margin-left: 15px;
              padding-left: 18px;
              border-left: 1px dashed #f8f9fa;
            }
            .Box {
              position: relative;
              .StyledTreeItem {
                position: absolute;
              }
            }
          }
        }
      }
    }
  }

  .folder_sec {
    width: 100%;
  }

  .bits_title {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 1px 0px;
    justify-content: space-between;
    position: relative;
    .Popup-wrap2 {
      position: absolute;
      top: 39px;
      left: 12px;
      z-index: -7;
      background: blue;
    }
    .bits_title2 {
      display: flex;
      align-items: center;
      gap: 5px;
    }
    // .hoverIcons {
    //   display: none;
    // }
  }
}

// .css-1bcfi89-MuiTreeItem-content.Mui-selected:hover {
//   // .hoverIcons {
//   //   display: flex;
//   //   gap: 2px;
//   // }
//   background: #36f !important;
// }
// .css-1bcfi89-MuiTreeItem-content:hover {
//   background: #36f !important;
//   .hoverIcons {
//     display: flex;
//     gap: 2px;
//   }
// }
