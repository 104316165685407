.dropdown-options {
  background: $white-color;
  border: 1px solid #efefef;
  padding: 5px;
  width: 100%;
  cursor: pointer;

  select {
    width: 80%;
    cursor: pointer;
    border: none;
    height: 45px;
    background: transparent;

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: none;
    }
  }
}
