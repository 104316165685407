.back-to-top {
    position: relative;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
    display: none;

    .back-to-top.visible {
        display: block;
      }
  }
  

  