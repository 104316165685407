.Button_Section {
  @include accordion-setting;
  margin-top: 5px;
  .inner_sec {
    .input-part {
      height: 135px;
      background-color: $off-white-color;
      width: 100%;
      color: $main-color !important;
      resize: none;
      padding: 5px;
    }
    p {
      color: $main-color;
      font-family: "Inter";
      text-align: left;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
    }
  }
  .main_icon-2 {
    .space-1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 9px;

      .text-small-slt {
        display: flex;
        align-items: center;
        gap: 5px;
        flex-basis: 40%;
        // justify-content: space-between;

        p {
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          color: $main-color;
        }
      }
      .input-leftname {
        flex-basis: 55%;
        input {
          width: 90%;
          background-color: $navy-blue-color;
          padding: 0px 10px;
          font-size: 14px;
          border-radius: 4px;
          color: $white !important;
          margin-bottom: 0px;
          height: 30px;
          // border: 1px solid $bg-color !important;

          &:focus-visible {
            outline: none;
            border: 1px solid $active-color;
            // border-color: $active-color;
          }
        }
      }
      .svg_space {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
      }
    }
  }
}
