.main-right {
  background-color: $off-white-color;
  .fourth-inner {
    height: 74vh;

    overflow-y: scroll;
    .scroll-fourth {
      @include accordion-setting;
      margin-top: 5px;
      .sec-1 {
        background-color: $navy-blue-color;
        padding: 12px 10px;
        margin-top: 12px;

        .flex-icon-btn-2 {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          gap: 98px;
          .icon-txt {
            display: flex;
            align-items: flex-start;
            gap: 12px;
            h5 {
              font-size: 16px;
              font-weight: 600;
              color: $white-color;
              text-align: left;
            }
          }
          .btn-2 {
            button {
              border-radius: 6px;
              background-color: $navy-blue-color;
              border: 1px solid $white-color;
              color: $white-color;
              padding: 2px 14px;
              font-size: 12px;
            }
          }
        }
        p {
          color: $white-color;
          font-size: 14px;
          font-weight: 400;
          text-align: left;
        }
      }
      .url-col {
        margin-top: 12px;
        h6 {
          color: #141a29;
          font-size: 16px;
          text-align: left;
        }
        textarea {
          outline: none;
          border: 2px solid #7dcff6;
          background-color: $secondary-color;
          border-radius: 6px;
          width: 100%;
          height: 100px;
          color: $white-color;
          padding: 2px 4px;
          resize: none;

          &::placeholder {
            color: #4d6598f7;
            text-align: left;
            font-size: 14px;
            font-weight: 500;
            padding: 2px 4px;
          }
        }
      }

      .tlg-btn-tab {
        margin-top: 24px;
        display: flex;
        justify-content: center;
        align-items: baseline;
        gap: 14px;
      }
      p {
        color: $navy-blue-color;

        text-align: left;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 400;
        line-height: 14px; /* 87.5% */
        margin-bottom: 20px;
      }
    }
  }
}
