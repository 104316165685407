* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* font-family: poppins; */
}
.css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background: aliceblue;
}

/* .cbQmzf {
  position: unset !important;
} */
