/* 

.input {
  width: 100%;
  border-radius: 8px;
  background: #f3f6ff;
  border: none;
  padding: 14px 18px;
  
  font-family: "Montserrat";
  outline: none;
  margin-top: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
} */

.lower_p {
  color: #283249;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600 !important;
  text-align: center;
  margin-top: 40px;
}
.lower {
  color: black;
  text-align: center;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 300;
  padding: 10px 60px;
  margin-bottom: 20px;
}

.google_link {
  text-decoration: none;
}
.gle_text {
  color: #36f;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  text-align: center;
}
.gle_img {
  align-items: center;
}

.btm-txt .lower_h1 {
  text-align: center;
  text-transform: capitalize;
}

/* responsive css style */

@media (max-width: 991px) {
  .Sign {
    width: 75%;
  }
}
@media (max-width: 767px) {
  .Sign {
    width: 100%;
    padding: 15px;
  }
  .submiit_btn button {
    border-radius: 10px;
    width: 100%;
    padding: 15px 18px;
    margin-top: 15px;
    font-weight: 500;
    font-family: "Montserrat";
    font-size: 18px;
    color: #f3f6ff;
  }

  .google_btn {
    border-radius: 8px;
    /* border: 1px solid #36f; */
    background: #fff;
    /* margin-top: 20px; */
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 10px;
    padding: 10px;
  }
}

@media (max-width: 567px) {
  .flex_input {
    flex-direction: column;
    gap: 0px;
  }
  .input_user {
    width: 100%;
  }
  .h2-box {
    font-size: 26px;
    margin-bottom: 25px;
  }
  .con_sign {
    padding: 30px 10px;
  }
  .lower {
    padding: 5px 10px;
    margin-bottom: 0px;
  }
  .submiit_btn button {
    border-radius: 10px;
    width: 100%;
    padding: 11px 14px;
    margin-top: 1px;
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 500;
    color: #f3f6ff;
  }
}
