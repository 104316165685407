.main-verify {
  background: #f3f6ff;
  padding: 30px 0;
  height: 100vh;
  display: flex;
  align-items: center;
}
.input_text {
  border-radius: 20px;
  background: #fff;
  width: 700px;
  padding: 25px 35px;
  margin: auto;
}

@media (max-width: 474px) {
  .input_text {
    padding: 25px 20px;
  }
}
.input_text {
  color: #000;
  font-size: 36px;
  text-align: center;
  font-weight: 600;
}
.h2-bold h2 {
  color: #000;
  font-family: "Montserrat";
  font-size: 36px;
  font-weight: 700;
  margin-top: 16px;
}
.opt-input {
  margin-top: 35px;
  padding: 0 2px;
}
.opt-input div {
  justify-content: space-between;
}

input {
  color: #000;
}

.p-timer {
  color: #000;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
}

.p-mas p {
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;

  line-height: 120%; /* 19.2px */

  margin: 15px;
}
.p-opt {
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 40px;
}

.p-opt p {
  font-weight: 700 !important;
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  /* font-weight: 700; */
  line-height: 120%;
  text-transform: capitalize;
}
.p-opt h6 {
  color: red;
}

.div-btn button {
  border-radius: 8px;
  background: #1a237e;
  border: none;
  width: 599px;
  height: 56px;
  margin: 0;
  font-size: 20px;
  font-family: "Montserrat";
  font-weight: 500;
  color: #f3f6ff;
}

.p-row p {
  text-align: center;
  font-size: 15px;
  padding: 5px 27px;
  margin-top: 12px;
}

.opt-input input {
  border-radius: 4px;
  border: 1.5px solid #c5c5ff;
  background: red;
  outline: none;
  width: 4.5rem !important;
  /* padding: 30px 55px; */
  font-size: 21px;
  border-radius: 5px;
  background: #f8f8ff;

  height: 3.5rem;
}

@media (max-width: 474px) {
  .opt-input input {
    width: 2rem !important;
    height: 2rem;
  }
}

/* Responsive css */

@media (max-width: 1199px) {
  .main {
    width: 100%;
  }
  .input_text {
    width: 75%;
  }
}
@media (max-width: 991px) {
  .main {
    width: 100%;
  }
  .input_text {
    width: 75%;
  }
}
@media (max-width: 767px) {
  .main {
    width: 100%;
  }
  .input_text {
    width: 75%;
  }
}

@media (max-width: 567px) {
  .main {
    width: 100%;
  }
  .input_text {
    width: 100%;
  }

  .div-btn {
    margin-top: 5px;
  }
  .p-row {
    padding: 5px 10px;
    margin-bottom: 0px;
  }
}
.Toastify__toast-container {
  text-align: center;
}
/* .Toastify__toast-container--top-right {
  top: 1em;
  right: 38em;
} */
.Toastify__toast {
  margin: 0 auto;
  text-align: center;
}
