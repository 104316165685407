.main-scroll {
  height: 72vh !important;
  overflow-y: scroll;
  position: relative;

  @media (max-width: 1399px) {
    height: 65vh !important;
  }
  .tooltip_top {
    background-color: #fff;
    color: #141a29;
  }
  .scrollable-comp {
    .style-bar {
      @include accordion-setting;
      margin-top: 5px;
      .main-select {
        display: flex;
        gap: 8px;
        align-items: center;
        .left-sec {
          flex-basis: 60%;

          select {
            width: 220px;
            height: 30px;
            background-color: $navy-blue-color;
            border: 1px solid $border-color-select;
            color: $white-color;
            border-radius: 3px;
            padding: 3px;
            font-size: 12px;

            &:focus-visible {
              outline: none;
            }

            &:hover {
              border-color: #ffffff29;
            }
          }

          .css-1xwtmcm-control,
          .css-ztyeyv-control {
            @media (max-width: "1500px") {
              width: 100%;

              .css-1jqq78o-placeholder {
                font-size: 10px;
              }
            }
          }
        }

        .right-secs {
          flex-basis: 38%;

          .icns {
            display: flex;
            justify-content: space-between;
            gap: 3px;

            span {
              cursor: pointer;

              svg {
                font-size: 22px;
                padding: 5px;
                background-color: $white-color;
                height: 28px;
                width: 26px;
                border-radius: 3px;
                color: $grey-dark-color;
                border: 1px solid $navy-blue-color;

                &:hover {
                  background-color: $navy-blue-color;
                  color: $white-color;
                }
                &.active {
                  background-color: $navy-blue-color;
                  path {
                    stroke: white;
                  }
                }
              }
            }
          }
        }
      }
    }
    .layout-bar {
      margin-top: 0px;

      .main-layout {
        color: $white-color;
        padding-bottom: 20px;
        .top-sec {
          display: flex;
          margin-bottom: 15px;
          .left-sec {
            display: flex;
            gap: 49px;
            align-items: center;
            @media (max-width: 1599px) {
              gap: 62px;
            }
            @media (max-width: 1499px) {
              gap: 100px;
            }
            @media (max-width: 1399px) {
              gap: 54px;
            }
            .dir {
              width: 80px;
              height: 28px;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              color: white;
              font-family: "Inter";
              font-size: 16px;
              font-weight: 500;
              line-height: 14px;
              text-align: left;
            }

            .img-main {
              display: flex;
              align-items: center;
              gap: 2px;
              border-radius: 6px 0 0 6px;
              span {
                background-color: $navy-blue-color;
                padding: 1px 5px;
                cursor: pointer;
                padding: 2px 12px;
                .btm {
                  transform: rotate(270deg);
                }

                .rgt {
                  transform: rotate(180deg);
                }

                &:first-child {
                  border-radius: 6px 0 0 6px;
                }

                &:last-child {
                  border-radius: 0 6px 6px 0;
                }
              }

              .btm_rt {
                transform: rotate(270deg);
              }

              .right_rt {
                transform: rotate(180deg);
              }
            }
          }

          .right-sec {
            @include switch-setting;
            display: flex;
            flex-basis: 45% !important;
            align-items: center;
            justify-content: flex-end;
            gap: 22px;
            // margin-right: 22px;
            @media (max-width: 1599px) {
              gap: 6px;
            }
            p {
              @media ($breakpoint_xxl) {
                font-size: 12px;
              }
            }
            .icns {
              svg {
                background-color: $off-white-color;
                &:hover {
                  color: white;
                }
              }
            }
          }
        }

        .align-sec {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .lt-sec {
            flex-basis: 25%;
            // margin-right: 24px;
          }

          .rt-sec {
            display: flex;
            gap: 0px;
            justify-content: flex-end;
            width: 100%;

            input {
              border-radius: 4px;
              background: $secondary-color;
              padding: 5px 36px;
              color: $white-color !important;
              height: 30px;
              max-width: 100%;
              border: none;
              margin-bottom: 0px;
              text-align: right;
              font-size: 14px;

              &::placeholder {
                color: $white-color;
              }
              p {
                text-align: end;
              }
            }
            .spans {
              border-right: none;
              svg {
                background-color: $white-color;
                padding: 4px;
                color: $navy-blue-color;
                font-size: 28px;
                width: 44px;
                cursor: pointer;
                // border-radius: 3px;
                height: 27px;
                outline: none;
                border: none;
                // &:hover {
                //   background-color: $navy-blue-color;
                //   color: $white-color;
                // }
                @media ($breakpoint_xxl) {
                  width: 38px;
                }

                @media (max-width: "1500px") {
                  width: 35px;
                }
              }

              .brd {
                background-color: $white-color;
                &:hover {
                  svg {
                    background-color: $navy-blue-color !important;
                    fill: $white-color;
                  }
                }
                .brd1 {
                  .btm {
                    transform: rotate(180deg);
                  }
                }
              }
              // &.active {
              //   background-color: yellow;
              // }
            }
            span {
              border-right: 0.15cap solid $navy-blue-color;
              svg {
                background-color: $white-color;
                padding: 4px;
                color: $navy-blue-color;
                font-size: 28px;
                width: 44px;
                cursor: pointer;
                border-radius: 0px !important ;
                height: 27px;
                outline: none;
                border: none;
                // &:hover {
                //   background-color: $navy-blue-color;
                //   color: $white-color;
                // }
                @media ($breakpoint_xxl) {
                  width: 38px;
                }

                @media (max-width: "1500px") {
                  width: 35px;
                }
              }

              .brd {
                background-color: $white-color;
                &:hover {
                  svg {
                    background-color: $navy-blue-color !important;
                    fill: $white-color;
                  }
                }
                .brd1 {
                  .btm {
                    transform: rotate(180deg);
                  }
                }
              }
              // &.active {
              //   background-color: yellow;
              // }
            }
          }
        }

        .justify-sec {
          // .brd2 {
          //   // background-color: $main-color !important;
          // }

          // .brd {
          //   background-color: $navy-blue-color !important;

          //   &:hover {
          //     background-color: $rgba(131, 138, 211, 0.1882352941) !important ;
          //   }
          // }
        }
        .basic-sec {
          display: flex;
          justify-content: center;
          align-items: center;
          .flex-sec {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-basis: 30px;
            button {
              border-radius: 8px;
              font-size: 14px;
              font-family: "inter";
              padding: 8px 18px;
              background-color: #3366ff;
              color: #fff;
              border: none;
            }
          }
          .flex-div {
            .text-field {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              margin-top: 12px;
              gap: 2px;
              margin-right: 9px;

              input {
                border-radius: 4px;
                background: #283249;
                color: #fff !important;
                height: 30px;
                border: none;
                margin-bottom: 0px;
                font-size: 12px;
                text-align: center;
                width: 28%;
                margin-left: -1px;
                cursor: pointer;
              }
            }
            .basic-flex {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 50px;
              margin-left: 42px;

              p {
                background: transparent;

                color: $white-color;
                font-size: 10px;
                border-radius: 4px;
                background: transparent;
                border: none;
                font-weight: 400;
              }
            }
          }
        }
        .align-flex {
          display: flex;
          gap: 23px;
          margin-top: 8px;
          margin-left: 4px;
        }

        .content-sec {
          color: $white-color;
          .wrap-sec {
            display: flex;
            align-items: center;
            margin-top: 10px;

            .lt-sec {
              flex-basis: 25%;
              p {
                margin-bottom: 0px;
                color: #141a29;
                font-size: 14px;
                font-weight: 400;
                line-height: 14px;
                text-align: left;
              }
            }
            .rt-sec {
              display: flex;
              gap: 2px;
              flex-basis: 75%;
              justify-content: space-between;
              button {
                border-radius: 4px;
                background: $secondary-color;
                padding: 6px 49px;
                color: $white-color;
                border: none;
                p {
                  text-align: end;
                  color: #141a29;
                }
              }
            }
          }

          .align-sec {
            display: flex;
            align-items: center;
            margin-top: 10px;
            .lt-sec {
              flex-basis: 25%;
              p {
                background-color: $lightblue-bg;

                font-size: 14px;
                font-weight: 400;
                line-height: 14px;
                text-align: left;

                margin-bottom: 0px;
              }
            }

            .rt-sec {
              display: flex;
              gap: 2px;
              flex-basis: 75%;
              justify-content: space-between;
              span {
                svg {
                  background-color: $secondary-color;
                  padding: 4px;
                  font-size: 28px;
                  width: 44px;
                  border: 1px solid $border-color-select;
                  cursor: pointer;

                  &:hover {
                    background-color: $main-color;
                  }
                }
                .brd {
                  border-radius: 3px 0 0 3px;
                  background-color: $main-color;
                }

                .brd1 {
                  border-radius: 0 3px 3px 0;
                }

                .btm {
                  transform: rotate(180deg);
                }
                .htlicon {
                  border-radius: 0 3px 3px 0;
                  background-color: $main-color;
                }
                .vtlicon {
                  border-radius: 0 3px 3px 0;
                  background-color: $main-color;
                }
              }
            }
          }
        }
      }
    }
    .background-bar {
      margin-top: 5px;

      .info_icon {
        position: relative;
        cursor: pointer;
      }
      .tooltip-container {
        top: 10px;
        left: 10px;
      }
      .main-background {
        color: $white-color;
        display: flex;
        gap: 10px;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
        justify-content: space-between;
        align-items: center;

        .flex-side {
          display: flex;
          justify-content: center;
          align-items: center;
          .color-picker {
            position: relative;
            border-radius: 6px;
            border: 2px solid $navy-blue-color;
          }
        }
        .slt-1 {
          display: flex;
          gap: 5px;
          .slt-left {
            flex-basis: 70%;

            select {
              width: 240px;
              height: 28px;
              background-color: $secondary-color;
              border: 1px solid $border-color-select;
              color: $white-color;
              border-radius: 3px;
              padding: 3px;
              font-size: 10px;

              &:focus-visible {
                outline: none;
              }

              &:hover {
                border-color: #ffffff29;
              }
            }
          }
        }

        p {
          width: 60px;
          color: #141a29;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 28px;
        }
      }

      .apply-btn {
        border-radius: 6px;
        font-size: 14px;

        padding: 2px 10px;
        background-color: $active-color;
        color: $white-color;
        border: none;
      }
    }
  }
  .size-bar {
    margin-top: 0px;

    .init_size {
      .main-size {
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;
        margin-right: 0px !important;

        .size-input-1 {
          display: flex;
          justify-content: center;
          gap: 16px;
          align-items: center;
          @media (max-width: 1500px) {
            gap: 5px;
          }
          p {
            font-family: "Inter";
            font-size: 14px !important;
            font-weight: 600 !important;
            line-height: 14px !important;
            text-align: left;
            border-radius: 4px;
            border: none;
            color: #141a29;
            width: 58px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 31px;
            @media (max-width: 1400px) {
              width: 50px;
            }
          }
          .flex-num-pt-1 {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            cursor: pointer;
            .slt-num-1 {
              background: $white-color;
              display: flex;
              align-items: center;
              height: 26px;
              border-radius: 4px;
              padding-right: 0px;
              width: 90px;

              @media (max-width: "1500px") {
                width: 80px;
              }
              input {
                width: 100%;
                height: 26px;
                border-radius: 4px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                color: #141a29 !important;
                background-color: transparent;
                padding: 7px 5px 5px 5px;
                margin-top: 18px;
                font-family: "Inter";
                font-size: 14px !important;
                font-weight: 400 !important;
                line-height: 14px !important;
                text-align: left;

                &::placeholder {
                  font-size: 12px;
                  text-align: center;
                  color: #141a29;
                }

                &:focus-visible {
                  outline: none;
                  border: 1px solid $active-color;
                }

                @media (max-width: 1400px) {
                  width: 2.5rem;
                }
              }

              select {
                background: transparent;
                border: none;
                color: #141a29;
                font-family: "Inter";
                font-size: 14px !important;
                font-weight: 400 !important;
                line-height: 14px !important;
                text-align: left;

                cursor: pointer;
                height: 24px;

                .no_brdr {
                  background: $main-color;
                  border: none;
                  outline: none;
                  cursor: pointer;
                  padding: 5px;

                  &:checked {
                    background-color: $main-color;
                    border: none;
                  }
                }

                &:focus-visible {
                  outline: none;
                }
              }

              span {
                color: $white;
                font-size: 12px;
              }
            }
            .slt-pt-1 {
              // border: 1px solid $secondary-color;
              padding: 5px;
              background: $secondary-color;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              color: $white-color;

              &:hover {
                background-color: rgb(88 103 138 / 56%);
              }
            }
            .select-list-item-1 {
              position: relative;
              right: 28px;
              top: 33px;

              background: $secondary-color;
              border-bottom-left-radius: 4px;
              border-bottom-right-radius: 4px;
              color: $white-color;
              .slt-1 {
                color: $white-color;
                padding: 2px 5px;

                &:hover {
                  background-color: rgb(88 103 138 / 56%);
                }
              }
              .slt-2 {
                color: $white-color;
                padding: 2px 5px;

                &:hover {
                  background-color: rgb(88 103 138 / 56%);
                }
              }
            }
          }
        }
      }
    }

    .auto-size {
      display: flex;
      gap: 24px;
      justify-content: flex-start;
      align-items: center;
      margin-left: 12px;
      .sz-1 {
        display: flex;
        justify-content: center;
        gap: 18px;
        justify-content: center;
        align-items: baseline;
        p {
          color: #fff;
          font-family: "Inter";
          font-size: 12px;
          font-weight: 500;
          line-height: 14px; /* 100% */
        }

        input {
          width: 83px;
          height: 33px;
          outline: none;
          border-radius: 4px;
          background: #283249;
          &::placeholder {
            font-size: 14px;
            text-align: center;
            font-weight: 400;
            line-height: 14px;
            color: $white-color;
          }
        }
        .flex-minimum {
          display: flex;
        }
      }
    }
    .auto-max {
      display: flex;
      gap: 23px;
      justify-content: flex-start;
      align-items: center;
      margin-left: 12px;
      .mx-1 {
        display: flex;
        justify-content: center;
        gap: 18px;
        justify-content: center;
        align-items: baseline;
        p {
          color: #fff;
          font-family: "Inter";
          font-size: 12px;
          font-weight: 500;
          line-height: 14px; /* 100% */
        }
        input {
          width: 83px;
          height: 33px;
          outline: none;
          border-radius: 4px;
          background: #283249;
          &::placeholder {
            font-size: 14px;
            text-align: center;
            font-weight: 400;
            line-height: 14px;
            color: $white-color;
          }
        }
      }
    }
  }
  .margin-bar {
    margin-top: 5px;
    .width_mrg {
      color: #141a29;
      font-family: "Inter";
      font-size: 14px !important;
      font-weight: 500 !important;
      line-height: 14px !important;
      text-align: left;
      margin-bottom: 10px;
      width: 60px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1399px) {
        width: 50px;
      }
    }
  }
  .typpography-bar {
    margin-top: 0px;
    p {
      font-family: "inter";
      font-size: 12px;
      font-weight: 400;
    }
    .main-sec {
      p {
        font-family: "inter";
        // font-size: 12px;
        // font-weight: 400;
        // color: #141a29;
        // padding: 1px 5px;
        // border-radius: 4px;

        // @media ($breakpoint_xxl) {
        //   font-size: 8px;
        //   // height: 20px;
        //   display: flex;
        //   align-items: center;
        // }
      }
      .main-flex {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        gap: 16px;
        .lt-slt {
          border-radius: 6px;
          flex-basis: 20%;
          row-gap: 17px;
          display: flex;
          flex-direction: column;
          button {
            border-radius: 4px;
            font-size: 14px;
            font-family: "inter";
            padding: 1px 10px;
            // background-color: #3366ff;
            color: #fff;
            border: none;
            width: 60px;
            height: 28px;
          }
        }
        .rt-slt {
          flex-basis: 80%;

          select {
            background: $navy-blue-color;
            padding: 8px 10px;
            width: 100%;
            border-radius: 5px;
            margin-bottom: 10px;
            color: $white-color;
            outline: none;
            border: none;
            @media ($breakpoint_xxl) {
              width: 100%;
            }
          }
        }
      }

      .contain-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .rt-size {
          display: flex;
          align-items: center;
          color: $white-color;
          flex-basis: 20%;
          h6 {
            font-family: "inter";
            font-size: 16px;
            font-weight: 400;
          }
        }
        .flex-num-size {
          display: flex;
          justify-content: space-evenly;
          align-items: flex-start;
          gap: 12px;
          cursor: pointer;
          flex-basis: 80%;
          margin-left: 20px;

          .slt-num-size {
            background: $navy-blue-color;
            display: flex;
            align-items: center;
            height: 30px;
            border-radius: 4px;
            width: 100%;
            input {
              width: 3.5rem;
              height: 30px;
              border-radius: 5px;
              border-top-right-radius: 0px;
              border-bottom-right-radius: 0px;
              color: $white-color !important;
              background-color: transparent;
              padding: 7px 5px 5px 5px;
              margin-top: 18px;
              font-family: "Inter";
              font-size: 13px;
              font-weight: 400;
              line-height: 14px;

              text-align: right;
              &::placeholder {
                font-family: "Inter";
                font-size: 13px;
                font-weight: 400;
                line-height: 14px;
                text-align: left;
                text-align: center;
                color: $white-color !important;
              }

              &:focus-visible {
                outline: none;
                border: 1px solid $active-color;
              }

              @media (max-width: "1500px") {
                width: 3rem;
              }
            }
            span {
              background: transparent;
              border: none;
              color: $white-color !important;
              font-size: 12px;
              cursor: pointer;
              .no_brdr {
                background: $main-color;
                border: none;
                outline: none;
                cursor: pointer;
                padding: 4px;

                &:checked {
                  background-color: $main-color;
                  border: none;
                }
              }

              &:focus-visible {
                outline: none;
              }
            }
          }
        }
      }
    }
  }

  .Decoration-bar {
    margin-top: 5px;

    .over-first-sec {
      // margin-top: 20px;

      h1 {
        font-size: 16px;
        font-weight: 500;
        line-height: 8px;
        color: $white-color;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 35px;
      }
      .two-flexItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;

        @media (max-width: 1399px) {
          gap: 0px;
        }
        .one-width,
        .one-radius {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;

          @media (max-width: 1399px) {
            gap: 0px;
          }

          h5 {
            font-family: "Inter";
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            text-align: left;
            color: #141a29;
            letter-spacing: 1px;
            text-transform: capitalize;
            width: 60px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 22px;
            padding: 0px 10px;
            margin-bottom: 0px;
          }

          .border_style {
            display: flex;
            background-color: $white-color;
            height: 26px;
            border-radius: 4px;
            color: #141a29;
            align-items: center;
            width: 90px;

            @media (max-width: 1399px) {
              width: 75px;
            }
            input {
              border-radius: 4px;
              background: $white-color;
              text-align: center;
              color: $navy-blue-color !important;
              font-family: "Inter";
              font-size: 14px;
              font-weight: 400;
              line-height: 14px;
              text-align: left;
              padding: 2px 4px;
              border: none;
              outline: none;
              width: 100%;
              height: 26px;
              margin-bottom: 0px;

              &::placeholder {
                font-size: 12px;
                text-align: center;
                color: $navy-blue-color !important;
              }

              &:focus-visible {
                outline: none;
                border: 1px solid $active-color;
              }
            }
            .unit {
              font-family: "Inter";
              font-size: 14px !important;
              font-weight: 400 !important;
              line-height: 14px !important;
              text-align: left;
              padding-left: 5px;
              padding-right: 10px;
              color: #141a29;
            }
          }
        }
      }
    }
    .middle-contain {
      border-top: 1px solid #c7c7c7 !important;
      margin-top: 10px;
      padding-top: 15px;
      h1 {
        font-size: 16px;
        font-weight: 500;
        line-height: 8px;
        color: #141a29;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      .para-wth {
        font-family: "Inter";
        font-size: 16px !important;
        font-weight: 600 !important;
        line-height: 14px !important;
        text-align: left;
        color: #141a29;
        position: relative;
        width: fit-content;
      }
      .auto-flx {
        display: flex;
        justify-content: center;
        align-items: baseline;
        gap: 0px;
        margin-bottom: 12px;
        margin-top: 12px;

        button {
          background-color: white;
          font-family: "Inter";
          font-size: 12px !important;
          font-weight: 500 !important;
          line-height: 14px !important;
          text-align: left;
          border-radius: 6px;
          border: 2px solid #acacac;
          color: #141a29;
          font-family: "inter";
          cursor: pointer;
          &:hover {
            background: $navy-blue-color;
            color: #ffff;
          }
        }
      }
      .rectangle-box {
        border: 2px solid #acacac;
        width: 200px;
        margin: 0 auto;
        height: 140px;
        @media (max-width: 1399px) {
          width: 160px;
        }

        .top-left-btn {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          /* gap: 4px; */
          margin-top: 16px;
          margin-left: 10px;
          button {
            font-size: 12px !important;
            font-weight: 500 !important;
            line-height: 14px !important;
            padding: 6px 18px;
            border-radius: 6px;
            border: 2px solid #acacac;
            background: #ffffff;
            color: #141a29;
            font-family: "inter";
            position: relative;
            left: -7px;
            top: -13px;
            &:hover {
              background: $navy-blue-color;
              color: #ffff;
            }
          }
        }
        .space-auto-btn {
          display: flex;
          gap: 14rem;
          justify-content: center;
          align-items: center;
          margin-top: 15px;
          margin-right: 8px;
          position: relative;
          margin-bottom: 15px;
          width: 100%;

          @media (max-width: 1399px) {
            gap: 170px;
          }

          button {
            font-size: 12px !important;
            font-weight: 500 !important;
            line-height: 14px !important;
            padding: 6px 18px;
            border-radius: 6px;
            border: 2px solid #acacac;
            background: #ffffff;
            color: #141a29;
            font-family: "inter";
            position: relative;
            &:hover {
              background: $navy-blue-color;
              color: #ffff;
            }
          }
        }
        .below_auto-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 15px;
          position: relative;
          gap: 130px;

          @media (max-width: 1399px) {
            gap: 90px;
            margin-top: 12px;
          }

          button {
            font-size: 12px !important;
            font-weight: 500 !important;
            line-height: 14px !important;
            padding: 6px 18px;
            border-radius: 6px;
            border: 2px solid #acacac;
            background: #ffffff;
            color: #141a29;
            font-family: "inter";
            position: relative;
            top: 10px;
            right: 0px;
            &:hover {
              background: $navy-blue-color;
              color: #ffff;
            }
          }
        }
        .center-auto-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 12px;
          margin-left: 8px;
          margin-bottom: 12px;

          button {
            font-size: 12px !important;
            font-weight: 500 !important;
            line-height: 14px !important;
            padding: 6px 18px;
            border-radius: 6px;
            border: 2px solid #acacac;
            background: #ffffff;
            color: #141a29;
            font-family: "inter";
            position: relative;
            top: -133px;
            right: -77px;
            &:hover {
              background: $navy-blue-color;
              color: #ffff;
            }

            @media (max-width: 1399px) {
              left: 54px;
              // top: -133px !important;
            }
          }
        }

        p {
          color: #141a29;
          font-size: 12px;
          margin-right: 10px;
        }
      }
      .auto_btn {
        font-size: 12px;
        padding: 4px 10px !important;
      }
      .left_auto,
      .right_auto {
        font-size: 12px;
        padding: 4px 10px !important;
      }
    }

    .top-para {
      border-top: 1px solid #c7c7c7 !important;

      padding-top: 15px;
      margin-top: 10px;
      p {
        margin-bottom: 12px;
        color: #141a29;
      }
    }
    .main-dec {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: "1500px") {
        gap: 15px;
      }
      button {
        border-radius: 6px;
        background-color: $navy-blue-color;
        margin-bottom: 0px;
        padding: 0px 20px;
        outline: none;
        height: 30px;
        padding-top: 2px;
        color: #fff;
        border: none;
      }
      .flex-side {
        display: flex;
        justify-content: center;
        align-items: center;
        .color-picker {
          position: relative;
          border-radius: 6px;
          border: 1px solid $navy-blue-color;

          @media ($breakpoint_xxl) {
            right: 5px;
          }
          @media (max-width: 1500px) {
            right: 0px;
          }
        }
      }
      .slt-1 {
        display: flex;
        gap: 8px;

        @media (max-width: "1500px") {
          width: 100%;
          justify-content: flex-end;
        }
        .slt-left {
          flex-basis: 70%;
          select {
            width: 40px;
            height: 36px;
            background-color: $navy-blue-color;
            border: 1px solid $navy-blue-color;
            color: $white-color;
            border-radius: 3px;
            padding: 3px;
            font-size: 10px;

            &:focus-visible {
              outline: none;
            }

            &:hover {
              border-color: #ffffff29;
            }
          }
        }
      }
    }
    .middle-part {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-top: 12px;
      .left_sec {
        button {
          font-size: 15px !important;
          font-weight: 500 !important;
          line-height: 14px !important;
          border-radius: 6px;
          border: none;
          margin-bottom: 0px;
          padding: 0px 24px;
          font-size: 14px;
          outline: none;
          height: 30px;
          padding-top: 2px;
          background: $navy-blue-color;
          color: #fff;
        }
      }
      .right_sec {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        div {
          svg {
            background: $navy-blue-color;
            padding: 4px;
            width: 39px;
            // border: 1px solid $navy-blue-color;
            color: #fff;
            cursor: pointer;
            @media (max-width: 1500px) {
              width: 35px;
            }
            // &:hover {
            //   background: #838ad330;
            // }

            @media ($breakpoint_xxl) {
              width: 35px;
            }
          }
          .brd {
            border-radius: 6px 0 0 6px;
            color: $white-color;
            padding: 2px;
            width: 61px;
          }
          .btmIcon {
            border-radius: 2px 0 0 2px;
            color: $white-color;
            padding: 2px;
          }
          .rticon {
            color: #fff;
            padding: 14px;
            border-radius: 0 6px 6px 0;
            background-color: $navy-blue-color;
            // border: 1px solid $navy-blue-color;
            padding: 10px 15px;
            @media (max-width: 1500px) {
              padding: 10px 8px;
              width: 38px;
            }
            // &:hover {
            //   background: #838ad330;
            // }
          }
        }
      }
    }
    .last-part {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-top: 12px;
      .left_line {
        margin-right: -27px;
        button {
          font-size: 16px !important;
          font-weight: 500 !important;
          line-height: 14px !important;
          border-radius: 6px;
          border: none;
          margin-bottom: 0px;
          padding: 0px 24px;

          outline: none;
          background: #141a29;
          height: 30px;
          padding-top: 2px;
          color: #fff;
        }
      }
      .right_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        span {
          .brd {
            border-radius: 6px 0 0 6px;
            color: $white-color;
            background: $navy-blue-color;
            padding: 5px 25px;

            // border: 1px solid #384053;
            // &:hover {
            //   background: #283249;
            // }

            @media (max-width: 1500px) {
              width: 35px;
              padding: 5px 12px;
            }

            @media ($breakpoint_xxl) {
              width: 35px;
              padding: 5px 12px;
            }
          }
          .btmIcon {
            border-radius: 2px 0 0 2px;
            padding: 5px 21px;
            color: $white-color;
            background: $navy-blue-color;
            // border: 1px solid #384053;
            // &:hover {
            //   background: #283249;
            // }
            @media (max-width: 1500px) {
              width: 35px;
              padding: 5px 12px;
            }
          }
          .rticon {
            color: $navy-blue-color;
            border-radius: 0 6px 6px 0;
            color: $white-color;
            background: $navy-blue-color;
            padding: 5px 25px;
            // &:hover {
            //   background: #283249;
            // }
            @media (max-width: 1500px) {
              width: 35px;
              padding: 5px 12px;
            }

            @media ($breakpoint_xxl) {
              width: 35px;
              padding: 5px 12px;
            }
          }
        }
      }
    }
  }
  .font-btm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #141a29;
    margin-left: 90px;
    margin-bottom: 12px;
    margin-top: 12px;
    font-family: "Inter";
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
    // &:hover {
    //   background-color: $sky-cloud-color;
    //   color: $white-color;
    //   border-radius: 4px;
    //   padding: 0px 4px;
    // }
    @media ($breakpoint_xxl) {
      margin-left: 100px;
    }

    p {
      font-size: 10px !important;
      @media (max-width: 1399px) {
        font-family: "Inter";

        font-weight: 500;
        line-height: 14px;
        text-align: left;
        // &:hover {
        //   background-color: $sky-cloud-color;
        //   color: $white-color;
        //   border-radius: 4px;
        //   padding: 0px 4px;
        // }
      }
    }
  }

  @media (min-width: 5000px) {
    height: 100%;
  }
  @media (min-width: 3000px) {
    height: 100%;
  }
}

.main-background {
  color: $white-color;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  .color-btn {
    border-radius: 4px;
    font-size: 14px;
    padding: 1px 10px;
    width: 60px;
    background-color: $sky-cloud-color;
    font-weight: 500;
    color: #fff;
    border: none;
  }

  .flex-side {
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 2px solid $navy-blue-color;

    .color-picker {
      position: relative;
      border-radius: 6px;
      border: 2px solid $navy-blue-color;
    }
  }
  .slt-1 {
    display: flex;
    gap: 8px;
    @media (max-width: "1500px") {
      width: 100%;
      justify-content: flex-end;
    }
    .slt-left {
      flex-basis: 70%;

      select {
        width: 40px;
        height: 36px;
        background-color: $secondary-color;
        border: 1px solid $border-color-select;
        color: $white-color;
        border-radius: 3px;
        padding: 3px;
        font-size: 10px;

        &:focus-visible {
          outline: none;
        }

        &:hover {
          border-color: #ffffff29;
        }
      }
    }
  }
}

.align-secs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-right: -10px;
  .lt-sec {
    flex-basis: 25%;
    color: $white-color;
    button {
      border-radius: 4px;
      margin-bottom: 0px;
      background: $active-color;
      padding: 6px 20px;
      border: none;
      color: $white-color;
    }
    p {
      color: $main-color;
    }
  }

  .rt-sec {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-right: 9px;
    gap: 6px;

    // .active {
    //   background: pink;
    // }

    // .no-active {
    //   background-color: $navy-blue-color;
    // }
    button {
      border-radius: 4px;
      background: $secondary-color;
      // padding: 6px 49px;
      color: $white-color;
      border: none;
      p {
        text-align: end;
        color: #141a29;
      }
    }
    .spans {
      border-right: none;
      svg {
        background-color: $navy-blue-color;
        padding: 4px;
        color: $white-color;
        font-size: 28px;
        width: 44px;
        cursor: pointer;
        border-radius: 0px;
        height: 27px;
        outline: none;
        border: none;
        // &:hover {
        //   background-color: $navy-blue-color;
        //   color: $white-color;
        // }
        @media ($breakpoint_xxl) {
          width: 38px;
        }

        @media (max-width: "1500px") {
          width: 35px;
        }
      }

      .brd {
        background-color: $navy-blue-color;
        &:hover {
          svg {
            background-color: $navy-blue-color !important;
            fill: $white-color;
          }
        }
        .brd1 {
          background-color: $navy-blue-color;
          .btm {
            transform: rotate(180deg);
          }
        }
      }
      // &.active {
      //   background-color: yellow;
      // }
    }
    span {
      svg {
        background-color: $navy-blue-color;
        padding: 4px;
        color: $white-color;
        font-size: 28px;
        width: 44px;
        cursor: pointer;
        // border-radius: 3px;
        height: 27px;
        outline: none;
        border: none;

        @media ($breakpoint_xxl) {
          width: 38px;
        }

        @media (max-width: "1500px") {
          width: 35px;
        }
      }

      .brd {
        background-color: $navy-blue-color;
        &:hover {
          svg {
            background-color: $navy-blue-color !important;
            fill: $white-color;
          }
        }
        .brd1 {
          background-color: $navy-blue-color;
          .btm {
            transform: rotate(180deg);
          }
        }
      }
    }
    svg {
      padding: 0px;
      width: 50px;
      height: 26px;
      margin-right: 0px;
      cursor: pointer;
      padding: 10px;

      @media (max-width: "1500px") {
        width: 50px;
      }
    }

    .brd {
      background-color: $navy-blue-color;
      color: $white-color;
      padding: 1px;
      border-radius: 6px 0px 0px 6px;
    }
    .btmIcon {
      background-color: $navy-blue-color;
      color: $white-color;
      padding: 1px;

      border-radius: 0px 6px 6px 0px;
    }

    .rticon {
      background-color: $navy-blue-color;
      color: $white-color;
      padding: 1px;
    }
  }
}

.css-1otpjjr-control {
  width: 180px !important;
  @media (max-width: "1500px") {
    width: 170px !important;
    min-height: 32px !important;
  }
  @media (max-width: "1500px") {
    width: 100% !important;
    min-height: 32px !important;

    .css-1jqq78o-placeholder {
      font-size: 9px;

      .css-qbdosj-input {
        font-size: 9px;
      }
    }
  }
}
.css-1rq358r-control {
  width: 180px !important;
  min-height: 30px !important;

  @media (max-width: "1500px") {
    width: 100% !important;
    min-height: 32px !important;

    .css-1jqq78o-placeholder {
      font-size: 9px;

      .css-qbdosj-input {
        font-size: 9px;
      }
    }
  }
}

.css-1jqq78o-placeholder {
  font-size: 12px;
}

.main-overall {
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: #141a29;
  }
  .auto-margin {
    .inner_margin {
      display: flex;
      gap: 12px;
      justify-content: space-between;

      .mr-1 {
        display: flex;
        justify-content: center;
        gap: 14px;

        @media (max-width: 1399px) {
          gap: 5px;
        }

        justify-content: center;
        align-items: baseline;

        .mr_sec {
          background: $white-color;
          border-radius: 3px;
          height: 26px;
          padding-right: 10px;
          display: flex;
          color: #141a29;
          width: 90px;
          @media (max-width: 1399px) {
            width: 75px;
          }
          input {
            width: 100%;
            height: 26px;
            padding: 2px 5px;
            border-radius: 4px;
            outline: none;
            background: $white-color;
            color: #141a29 !important;
            font-size: 12px;
            text-align: right;
            &::placeholder {
              font-size: 12px;
              text-align: center;
              font-weight: 400;
              line-height: 14px;
              color: #141a29;
            }

            &:focus-visible {
              outline: none;
              border: 1px solid $active-color;
            }

            @media (max-width: 1399px) {
              width: 3.2rem;
            }
          }

          select {
            background: transparent;
            border: none;
            color: $white-color;
            font-size: 12px;
            cursor: pointer;
            .no_brdr {
              background: $main-color;
              border: none;
              outline: none;
              cursor: pointer;
              padding: 5px;

              &:checked {
                background-color: $main-color;
                border: none;
              }
            }
            &:checked {
              background-color: $main-color;
              border: none;
            }
          }

          &:focus-visible {
            outline: none;
          }

          span {
            color: #141a29;
            font-size: 12px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .main-margin {
    .mg_para {
      position: relative;
      font-family: "Inter";
      font-size: 16px !important;
      font-weight: 600 !important;
      line-height: 14px !important;
      text-align: left;
    }
    .margin-flx {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      button {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 14px !important;
        padding: 6px 16px;
        border-radius: 6px;
        border: 2px solid #acacac;
        background: #ffffff;
        color: #141a29;
        font-family: "inter";
        position: relative;
        &:hover {
          background: $navy-blue-color;
          color: #ffff;
        }
      }
    }

    p {
      color: $white-color;
    }
  }

  .selected-item-sec {
    border-top: 1px solid #c7c7c7 !important;
    margin-top: 10px;
    padding-top: 15px;

    p {
      margin-bottom: 0px;
      color: #141a29;
    }
  }
  .selected-item-sec2 {
    // border-top: 1px solid $border-color-select !important;
    margin-top: 10px;
    padding-top: 15px;

    p {
      margin-bottom: 0px;
      color: #141a29;
    }
  }

  .rtangle-box {
    margin-top: 16px;
    border: 2px solid #acacac;
    width: 224px;
    margin: 0 auto;
    margin-top: 16px;

    @media (max-width: 1399px) {
      width: 160px;
    }

    .lt-btn {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      gap: 12px;
      margin-top: 15px;
      margin-left: 2px;
      font-size: 14px !important;
      font-weight: 500 !important;
      line-height: 14px !important;
      @media (max-width: 1399px) {
        gap: 0px;
      }
      p {
        text-align: left;
        color: #141a29;
        @media (max-width: 1399px) {
          font-size: 10px !important;
        }
      }
      button {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 14px !important;
        padding: 6px 16px;
        border-radius: 6px;
        border: 2px solid #acacac;
        background: #ffffff;
        color: #141a29;
        font-family: "inter";
        position: relative;
        &:hover {
          background: $navy-blue-color;
          color: #fff;
        }
      }
    }
    .space-btn {
      display: flex;
      gap: 40px;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
      position: relative;
      margin-bottom: 17px;
      margin-left: -1px;

      @media (max-width: 1399px) {
        gap: 19px;
      }
      button {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 14px !important;
        min-width: 62px;
        padding: 6px 16px;
        border-radius: 6px;
        border: 2px solid #acacac;
        background: #ffffff;
        color: #141a29;
        font-family: "inter";
        position: relative;
        &:hover {
          background: $navy-blue-color;
          color: #ffff;
        }
      }
    }
    .center-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 12px;
      margin-left: 8px;
      margin-bottom: 12px;

      button {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 14px !important;
        padding: 6px 16px;
        border-radius: 6px;
        border: 2px solid #acacac;
        background: #ffffff;
        color: #141a29;
        font-family: "inter";
        position: relative;
        &:hover {
          background: $navy-blue-color;
          color: #ffff;
        }
      }
    }

    p {
      color: $white-color;
      font-size: 12px;
      margin-right: 4px;

      @media (max-width: "1500px") {
        font-size: 9px;
      }
    }
  }

  .auto_btn {
    font-size: 12px;
    padding: 6px 16px;
  }
}

.tlg-btn {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 14px;

  p {
    color: $main-color;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 14px; /* 87.5% */
    margin-bottom: 20px;
  }
}

.margin-flx {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 15px;
  margin-bottom: 10px;

  button {
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 14px !important;
    padding: 6px 16px;
    border-radius: 6px;
    border: 2px solid #acacac;
    background: #ffffff;
    color: #141a29;
    font-family: "inter";
    position: relative;
    &:hover {
      background: $navy-blue-color;
      color: #ffff;
    }
  }

  .btm_btn {
    margin-left: 15px !important;
  }
}

.main-scroll::-webkit-scrollbar {
  display: none;
}

/* Define CSS classes for different width and height values */
// .selected-folder-preview {
//   /* Default styles */
// }

.width-100 {
  width: 100px; /* You can change the width value as needed */
}

.height-100 {
  height: 100px; /* You can change the height value as needed */
}

.flex-num-pt-1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  cursor: pointer;

  .inner_num {
    display: flex;
    gap: 14px;
    color: $white-color;
    align-items: center;

    @media (max-width: "1500px") {
      gap: 5px;
    }

    p {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      text-align: left;
      margin-bottom: 0px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 31px;
      border-radius: 4px;
      @media (max-width: 1400px) {
        width: 50px;
      }
    }
    .slt-num-1 {
      text-align: left;
      background: $white-color;
      display: flex;
      align-items: center;
      height: 26px;
      border-radius: 4px;
      width: 90px;

      @media (max-width: 1500px) {
        width: 80px;
      }

      input {
        width: 100%;
        height: 26px;
        border-radius: 4px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        color: #141a29 !important;
        background-color: transparent;
        padding: 7px 5px 5px 5px;
        margin-top: 18px;
        font-family: "Inter";
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 14px !important;
        // &::placeholder {
        //   text-align: center;
        //   color: #141a29;
        // }

        &:focus-visible {
          outline: none;
          border: 1px solid $active-color;
        }
        @media (max-width: 1400px) {
          width: 2.5rem;
        }
      }

      select {
        background: transparent;
        border: none;
        color: #141a29;
        font-family: "Inter";
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 14px !important;
        text-align: left;
        cursor: pointer;
        height: 24px;
        .no_brdrs {
          background: #fff;
          border: none;
          outline: none;
          cursor: pointer;
          padding: 5px;

          &:checked {
            border: none;
          }
        }
        .no_brdr {
          background: #fff;
          border: none;
          outline: none;
          cursor: pointer;
          padding: 5px;

          &:checked {
            border: none;
          }
        }

        &:focus-visible {
          outline: none;
        }
      }

      span {
        font-size: 12px;
      }
    }
  }

  .slt-pt-1 {
    // border: 1px solid $secondary-color;
    padding: 5px;
    background: $secondary-color;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: $white-color;

    &:hover {
      background-color: rgb(88 103 138 / 56%);
    }
  }
  .select-list-item-1 {
    position: relative;
    right: 28px;
    top: 33px;

    background: $secondary-color;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: $white-color;
    .slt-1 {
      color: $white-color;
      padding: 2px 5px;

      &:hover {
        background-color: rgb(88 103 138 / 56%);
      }
    }
    .slt-2 {
      color: $white-color;
      padding: 2px 5px;

      &:hover {
        background-color: rgb(88 103 138 / 56%);
      }
    }
  }
}

.mg_tb {
  margin: 5px 0;
}

.size_title {
  width: 46px;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 14px !important;
  text-align: left;

  color: #141a29;
}
.justify-sec {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .lt-sec {
    flex-basis: 25%;
    color: $white-color;
    p {
      color: #141a29;
    }
  }
  .rt-sec {
    display: flex;
    justify-content: flex-end;
    color: $white-color;
    width: 100%;
    gap: 4px;
    // border-right: 2px solid $navy-blue-color;
    // margin-left: 24px;
    @media (max-width: 1399px) {
      margin-left: 0px;
    }
    span {
      // width: 100%;

      svg {
        background-color: $navy-blue-color;
        font-size: 28px;
        width: 58px;
        height: 26px;

        // border: 1px solid $border-color-select;
        cursor: pointer;

        @media (max-width: 1500px) {
          width: 50px;
        }
      }
      .brd {
        background-color: $navy-blue-color;
        color: $white-color;
        padding: 1px;
        border-radius: 0px !important;

        // @media (max-width: 1500px) {
        //   padding: 4px 10px;
        // }
      }
    }
    p {
      background-color: white;
      width: 67px;
      height: 26px;
      color: #1a237e;
      text-align: center;

      @media (max-width: "1500px") {
        padding: 2px 20px;
      }
    }

    .brd1 {
      font-size: 12px;
      background-color: $navy-blue-color;
      color: $white-color;

      @media (max-width: "1500px") {
        padding: 2px 18px;
      }
    }
    .brd2 {
      font-size: 12px;
      background-color: $navy-blue-color;
      color: $white-color;

      @media (max-width: "1500px") {
        padding: 2px 18px;
      }
    }
    .brd3 {
      font-size: 12px;
      border-radius: 0 6px 6px 0;
      background-color: $navy-blue-color;
      color: $white-color;

      @media (max-width: "1500px") {
        padding: 2px 18px;
      }
    }

    .brd3 {
      border-radius: 0 6px 6px 0 !important;
    }
  }
}
.css-b62m3t-container {
  min-height: 31px !important;
}

.ltr-space {
  color: #141a29;
  font-family: "Inter";
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 14px !important;
  text-align: left;
  text-transform: uppercase;
  padding-bottom: 15px;
}
