.main_con {
  padding: 80px 0;
  background-color: #f8f8ff;
  min-height: 100vh;
  display: flex;
  align-items: center;

  .form_container {
    border-radius: 20px;
    background: #fff;
    width: 699px;
    padding: 20px 40px;
    margin: auto;

    .top_h1 {
      h2 {
        color: #000;
        font-size: 36px;
        font-family: "Montserrat";
        text-align: center;
        font-weight: 700;
        margin-top: 25px;
        margin-bottom: 30px;
      }
    }
    .input_field {
      margin-top: 12px;
      margin-bottom: 25px;

      label {
        color: #000;
        font-family: "Montserrat";
        font-size: 16px;
        font-weight: 600;
        text-align: center;
      }

      input {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
        border-radius: 6px;
        background: #f8f8ff !important;
        margin-top: 4px;
        padding: 14px 18px;
        width: 100%;
        font-family: "Montserrat";
        font-size: 15px;
        border: 1.5px solid #c5c5ff;
        outline: none;
        font-weight: 500;
      }
      .erros_msg {
        color: red;
        margin-top: 8px;
      }
    }
    .formBtn {
      margin-top: 5px;
      button {
        border-radius: 6px;
        background: $navy-blue-color;
        border: none;
        width: 100%;
        padding: 14px 18px;
        font-family: "Montserrat";
        font-weight: 700;
        font-size: 16px;
        color: #f3f6ff;
        margin-top: 0px;
      }
    }
    .input_check {
      margin-top: 15px;
      display: flex;
      justify-content: left;
      align-items: center;
      flex-direction: row-reverse;
      gap: 8px;
      margin-bottom: 10px;

      label {
        font-weight: 600;
        color: #283249;
        font-size: 16px;
      }
      input {
        width: 15px;
        height: 15px;
        position: relative;
        top: -2px;
      }
    }
    .div_gle {
      font-weight: 700;
    }
    .get {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
      margin: 20px 0;
      .get-link {
        text-decoration: none;
        h3 {
          color: black;
          font-weight: 600;
          font-family: "Montserrat";
          font-size: 16px;
          line-height: 22px; /* 137.5% */
          text-transform: capitalize;
          margin-bottom: 0px;
        }
      }
    }
  }
}
.Toastify__toast-container {
  text-align: center;
}

.Toastify__toast {
  margin: 0 auto;
  text-align: center;
}
