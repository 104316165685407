.delete_confirm_popup {
  width: 570px;
  height: 400;
  border-radius: 10px;
  opacity: 0px;
  color: $white;
  overflow-y: hidden;
  padding: 48px 0px;
  border-radius: 10px;

  .header_bar {
    padding-top: 0px;
    text-align: center;
    .svg_trash {
      padding: 19px 20px;
      background-color: rgba(255, 111, 97, 0.1411764706);
      display: inline-block;
      border-radius: 12px;
    }
  }

  .body_sec {
    padding: 15px 0;

    h4 {
      font-family: "Inter";
      font-size: 25px;
      font-weight: 600;
      line-height: 48.41px;
      text-align: center;
      color: #283249;
    }
    p {
      font-size: 20px !important;
      font-weight: 400 !important;
      line-height: 15.73px !important;
      text-align: center;
      color: #283249;
    }
  }

  .btn_sec {
    display: flex;
    justify-content: center;
    gap: 10px;

    .del_btn {
      button {
        background-color: #ff6f61;
        width: 108px;
        height: 36px;
        border: none;
        color: $white;
        font-weight: 600;
      }
    }

    .cancel_btn {
      background-color: $white-color;
      button {
        width: 108px;
        height: 36px;
        border: 1px solid $navy-blue-color;
        color: $navy-blue-color;
        font-weight: 600;
        background-color: $white-color;
      }
    }
  }

  .react-responsive-modal-closeButton {
    display: none;
  }
}
.react-responsive-modal-modal.delete_confirm_popup {
  background-color: $off-white-color;
}
