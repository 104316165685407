.main_service {
  .inner_service {
    padding: 50px 0;

    .top_head {
      text-align: center;
      padding-bottom: 50px;

      p{

        color: $active-color;
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 5px;
      }
    }

    .body_sec {
      display: flex;
      justify-content: center;
      gap: 15px;

      .img_sec {
        text-align: center;
        height: 472px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px;

        img{

            padding-bottom: 30px;
        }

        &:hover {
          background: rgba(38, 86, 231, 0.06);
          transition: 0.2s;
          border-radius: 17px;
        }

        h6 {
          color: $black;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
          padding-bottom: 10px;
        }

        p {
          color: $skyblue-color;
          font-size: 14px;
          font-weight: 400;
          line-height: 28px; /* 200% */
          letter-spacing: 0.28px;
          text-transform: capitalize;
        }
      }

      @media($breckpoint_MD){
        flex-direction: column;
      }
    }
  }
}
