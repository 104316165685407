.main-bar {
  .sec-one {
    overflow-y: scroll;
    height: 74vh;
    .scroll-main {
      @include accordion-setting;
      margin-top: 5px;
      .space-1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-bottom: 18px;

        .text-upper {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          p {
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
            color: #141a29;
          }

          svg {
            color: $white;
            font-size: 15px;
          }
        }
      }
      .bottom_line {
        margin-bottom: 16px;
        border-bottom-color: #c7c7c7 !important;
        border: 1px solid;
        margin-top: 20px;
      }
      .space-1,
      .option_space,
      .Error-piker,
      .Custom-1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 9px;
        padding-bottom: 10px;
        .text-small-slt,
        .snap_img {
          display: flex;
          /* justify-content: center; */
          align-items: center;
          gap: 5px;
          flex-basis: 65%;

          p {
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
            color: #141a29;
          }
        }
        .input-leftname {
          flex-basis: 77%;
          input {
            width: 100%;
            background-color: $navy-blue-color;
            padding: 0px 10px;
            font-size: 14px;
            border-radius: 4px;
            color: $white !important;
            margin-bottom: 0px;
            height: 30px;
            // border: 1px solid $bg-color !important;

            &:focus-visible {
              outline: none;
              border: 1px solid $active-color;
              // border-color: $active-color;
            }
          }
        }
        .svg_space {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }
}
