.tip_wrapper {
  width: 260px;
  position: absolute;
  z-index: 1000;
  background: $white-color;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 14px;
  margin-bottom: 0px;
  border: 1px solid #1a237e54;
  border-radius: 6px;
  right: 96px;
  // top: 45px;
  overflow: scroll;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: #283249;
    }
    p {
      margin: 5px 0;
      color: #283249;
    }

    .doc_link a {
      color: #0aabe6;
      text-decoration: underline;
      opacity: 1;
    }
  }
}

.info_icon_div {
  position: relative;
  .white_arrow_img {
    position: absolute;
    top: 20px;
    left: -3px;
  }
}
