.main-testi{
    // background: linear-gradient(180deg, #F2F6FF 0%, #F3F7FF 100%);
    background-image: url("../Image/test2_bg.svg");
    padding: 50px 0px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1920px auto;

    .inner{

        display: flex;
        align-items: center;
        @media($breckpoint_LG){
            flex-direction: column;
        }

        .left-testi{
            flex-basis: 50%;
            

            .clr{
                color:#223A83;
                font-weight: 600 !important;
            }

            h2{

                font-size: 40px;
                font-weight: 700;
            }
            p{
                color: #2E484B;
font-family: "Montserrat";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 31px; /* 221.429% */
letter-spacing: 0.28px;
text-transform: capitalize;
            }
        }
     

        .right-testi{
            flex-basis: 50%;

        
       
        }
    }
}