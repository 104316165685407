.main_wrapper {
  z-index: 200px;
  position: sticky;
  // padding-top: 10px;
  .sidebar {
    height: 100vh;
    max-width: 22% !important;
    top: 68px;
    bottom: 0;
    // left: 316px;
    z-index: 100;
    overflow: scroll;
    background: #f8f8ff;
    @media (max-width: 576px) {
      // padding: 40px 0;
    }
    @media (max-width: 1023px) {
      display: none;
    }

    .nav {
      list-style-type: none;
      // padding-left: 22px;
      // padding-right: 22px;
      flex-direction: column;
      display: flex;
      gap: 10px;
      padding-top: 10px;

      .nav-item {
        .nav-children {
          padding-left: 20px;
        }
        .nav-children3 {
          padding-left: 40px;
        }
        .nav-link {
          font-family: "Inter";
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: #676767;
          &:hover {
            color: #ffff;
            border-radius: 6px;
            border: 1px;
            background-color: #1a237e;
            transition: none !important;
          }
          &:active {
            color: #ffff;
            border-radius: 6px;
            border: 1px;
            background-color: #1a237e;
            // padding: 10px 10px;
          }
        }
        .false {
          border-radius: 30px;
          padding: 5px;
          transition: none !important;
        }
        .special {
          font-family: "Inter";
          font-size: 17px;
          font-weight: 600;
          line-height: 26px;
          text-align: left;
          color: #1a237e;
          padding-left: 5px !important;
          &:hover {
            color: #ffff;
          }
        }
        svg {
          width: 10px;
          height: 10px;
        }
        .icon {
          width: 10px;
          height: 10px;
          transition: transform 0.3s ease;
          transform: rotate(0deg);
        }
        .rotated {
          margin-right: 5px;
          width: 10px;
          height: 10px;
          transition: transform 0.3s ease;
          transform: rotate(90deg);
        }
      }
    }
  }
  .midsection {
    max-width: 58% !important;
    @media ($breakpoint_xxm) {
      max-width: 78% !important;
    }
    @media (max-width: 1023px) {
      max-width: 100% !important;
    }
  }

  .content {
    height: 100vh;
    z-index: 100;
    // margin-left: 345px;
    padding: 20px;
    overflow: scroll;
  }
  .lastsection {
    max-width: 20% !important;
    /* padding-left: 40px; */
    margin-top: 100px;
    @media ($breakpoint_xxm) {
      display: none;
    }

    ul {
      list-style: none;
      .flex-table-icon {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        //  margin-left: -56px;
        li {
          font-family: "Inter";
          font-size: 18px;
          font-weight: 500;
          line-height: 30px;
          text-align: left;
          color: #283249;
          @media ($breckpoint_XXL) {
            font-size: 16px;
          }
        }
        svg {
          color: #637288;
          font-size: 18px;
        }
      }
    }

    .table-children {
      list-style: none;
      ul {
        list-style: none;
        margin-left: 18px;
        li {
          a {
            font-family: "Inter";
            font-size: 16px;
            font-weight: 300;
            line-height: 34px !important;
            text-align: left;
            color: #283249 !important;

            @media ($breckpoint_XXL) {
              font-size: 14px;
            }
            &:hover {
              color: #2832497c !important;
            }
          }
        }
      }
    }
  }
  .button_group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    .button_forward,
    .button_backward {
      margin-top: 15px;
      font-family: "Inter";
      font-size: 18px;
      font-weight: 400;
      line-height: 34px !important;
      color: #4f5a66;
      margin-bottom: 25px;

      svg {
        color: #99a4b0e1;
      }
    }
  }
}
