.canvas {
  color: $white;
  // overflow: hidden;
  width: 35%;
  height: 800px;
  border: 6px;
  box-shadow: 0px 4px 64px 0px #000d332b;

  background: $white;
  transform-origin: center top;
  // overflow-y: scroll;
  // transition: 0.3s;

  // .canvas-container {
  //   width: 100%;
  // }

  @media (max-width: "1500px") {
    height: 650px;
  }

  .inner_canvas {
    // display: flex;
    // flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0px;
  }
  .inner {
    .img_block {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: 9999;
      border: 2px solid transparent;
      cursor: pointer;
    }
    .selectBits_img {
      background-color: #0099eb11;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: 9999;
      cursor: pointer;
      outline: 3px dotted #009aeb;

      &::after {
        bottom: 0;
        box-shadow: inset 0 0 0 1px #38bdf8,
          inset 0 0 0 3px rgba(56, 189, 248, 0.3),
          inset 0 0 0 999999px rgba(56, 189, 248, 0.1);
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 100000;
      }
    }
    .input-box {
      // color: $black-color !important;
      background-color: transparent !important;
      width: 100%;
      font-size: 12px;
      // padding: 10px 10px;
      margin-bottom: 0px;
      border: 2px dotted black;
      position: relative;
      z-index: 1;
      font-size: 14px;
      // height: auto;
      cursor: pointer !important;
      &:focus {
        border: 2px dotted #009aeb;
        outline: none;

        &::before {
          align-items: center;
          background-color: #38bdf8;
          color: #fff;
          content: attr(data-content) "​";
          display: flex;
          font-family: Inter var;
          font-size: 0.625rem;
          font-weight: 500;
          // height: 1rem;
          justify-content: center;
          left: 0;
          padding: 0 8px;
          position: absolute;
          top: -1rem;
          z-index: 100000;
        }
      }
    }
    .selectInput-box {
      background-color: transparent !important;
      width: 100%;
      // padding: 10px 10px;
      margin-bottom: 0px;
      border: 2px solid #000 !important;
      position: relative;
      z-index: 1;
      // border: 1px solid #b7b7b7;
      // height: auto;
      cursor: pointer;
      &:focus {
        border: 2px dotted #009aeb !important;
        outline: none;
        &::before {
          align-items: center;
          width: 100px !important;
          height: 100px !important;
          background: #38bdf8 !important;
          color: #fff;
          content: "Text" !important;
          display: flex;
          font-size: 0.625rem;
          font-weight: 500;
          height: 1rem;
          justify-content: center;
          left: 0;
          padding: 0 8px;
          position: absolute;
          top: -1rem;
          z-index: 100000;
        }
      }
    }

    .view_comp {
      background-color: transparent;
      width: auto;
      height: auto;
      // border: 1px dotted #009aeb;
      // padding: 10px;
      position: relative;
      z-index: -1;
      cursor: pointer;
      box-sizing: border-box;
      width: 100%;
      position: relative;
      overflow-x: scroll;
    }
    .view_comp_tabItem {
      background-color: transparent;
      width: auto;
      height: auto;
      // border: 1px dotted #009aeb;
      // padding: 10px;
      position: relative;
      z-index: -1;
      cursor: pointer;
      box-sizing: border-box;
      box-shadow: inset 0 0 0 1px #362525, inset 0 0 0 3px rgb(56 189 248 / 35%),
        inset 0 0 0 999999px rgb(56 189 248 / 41%);
      text-align: center;
      color: #fff;
    }
    .selectView_comp {
      background-color: #0099ebec;
      width: 100%;
      height: auto;
      border: 2px dotted #f00b0b;
      // padding: 10px;
      position: relative;
      z-index: -1;
      cursor: pointer;
      box-sizing: border-box;
      box-shadow: inset 0 0 0 1px #362525, inset 0 0 0 3px rgb(56 189 248 / 35%),
        inset 0 0 0 999999px rgb(56 189 248 / 41%);
      text-align: center;
      color: #fff;
      overflow-x: scroll;
    }
    .selectView_comps {
      background-color: #0099ebfd;
      width: auto;
      height: auto;
      border: 2px dotted #63f00b;
      // padding: 10px;
      position: relative;
      z-index: -1;
      cursor: pointer;
      box-sizing: border-box;
      box-shadow: inset 0 0 0 1px #362525, inset 0 0 0 3px rgb(56 189 248 / 35%),
        inset 0 0 0 999999px rgb(56 189 248 / 41%);
      width: 100%;
    }

    .canvas-container {
      // overflow: hidden;
      // width: 100%;
      // height: 800px;
      // border: 6px solid #404b69;
      // background: $white;
      // transform-origin: center top;
      // transition: 0.3s;
      @media (max-width: "1500px") {
        width: 350px;
      }

      .inner_canvas {
      }
      .react-draggable {
        cursor: move;
      }
      .bits_img {
        width: 100px;
        height: 100px;
        object-fit: contain;
      }

      .bits_div {
        height: 100px;
        border: 1px solid black;
      }
    }
  }
}

.canvas.landscape {
  height: 360px;
  width: 600px;
  transition: 0.3s;

  @media (max-width: "1500px") {
    width: 530px;
  }
}

/* For draft preview mode */
.img_block.draft-preview {
  cursor: pointer;
}

.selectBits_img.draft-preview {
  cursor: pointer;
}

.input-box.draft-preview {
  cursor: pointer;
}

.selectInput-box.draft-preview {
  cursor: pointer;
}

.view_comp.draft-preview {
  cursor: pointer;
}

.selectView_comp.draft-preview {
  cursor: pointer;
}

/* For web preview mode */
.img_block.web-preview {
  cursor: default;
  pointer-events: none;
}

.selectBits_img.web-preview {
  cursor: default;
  pointer-events: none;
}

.input-box.web-preview {
  cursor: default;
  pointer-events: none;
}

.selectInput-box.web-preview {
  cursor: default;
  pointer-events: none;
}

.view_comp.web-preview {
  cursor: default;
  pointer-events: none;
}

.selectView_comp.web-preview {
  cursor: default;
  pointer-events: none;
}

.false {
  border-radius: 30px;
  padding: 5px;
  transition: 0.5s;
}

.true {
  transition: 0.5s;
}
