.scroll-sec {
  .web-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    .web_img {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;
      p {
        color: #141a29;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;

        @media ($breakpoint_xxl) {
          font-size: 12px;
        }
      }

      img {
        @media ($breakpoint_xxl) {
          width: 16px;
        }

        width: 14px;
      }
    }

    input {
      width: 150px;
      background-color: $bg-color;
      padding: 0px 10px;
      font-size: 14px;
      border-radius: 4px;
      color: $white !important;
      margin-bottom: 0px;
      height: 30px;
      &::placeholder {
        color: white;
        font-size: 12px;
        text-align: end;
      }
      &:focus-visible {
        outline: none;
        border: 1px solid $active-color;
      }
    }
  }
}
