.main_nav {
  background-color: transparent;

  .navbar-main {
    .navbar_list {
      display: flex;
      justify-content: space-between;
      padding-top: 30px;
    }
  }
}

.topList {
  display: flex;
  align-items: center;
  gap: 20px;

  @media ($breckpoint_XXL) {
    gap: 14px;
  }

  a {
    color: $black;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    align-items: center;

    @media ($breakpoint_XXN) {
      font-size: 12px !important;
    }
  }

  @media ($breckpoint_XL) {
    font-size: 14px;
  }

  button {
    @media ($breckpoint_LG) {
      font-size: 12px !important;
    }
  }
}

@media (max-width: 1200px) {
  .nav_bar {
    display: none;
  }
}

.mob-resp {
  display: none;
}

@media (max-width: 1200px) {
  /* .bg-body-tertiary{
      display: none;
    } */

  .mob-resp {
    display: block;
  }
}

.button_Sign {
  border-radius: 30px;
  border: 2px solid #0b35b5;
}

.button_Sign {
  color: #2859ec;
  font-size: 15px;
  font-weight: 600;
  height: 40px;
  width: 90px;
  background: #fff;
}
.button_start {
  border-radius: 30px;
  border: none;
  background: linear-gradient(89deg, #0e8ffb -3.08%, #0f39b9 95.74%);
  height: 40px;
  width: 119px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}

.div_gap {
  display: flex;
  align-items: center;
  gap: 10px;
}
