.PVTHeader__sidebar__right,
.Header__sidebar__right {
  background-color: #fff !important;
  width: 270px !important;
  height: 100vh !important;
  position: absolute;
  right: 0;

  .nav-list {
    .inner {
      margin-top: 20px;

      ul {
        line-height: 3.2rem;
        padding-left: 0px;
        li {
          a {
            text-decoration: none;
            outline: none;
          }
        }
      }
    }

    .btn-sec {
      .inner {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        .sign-btn {
          a {
            text-decoration: none;
            border: 1px solid #2859ec;
            color: #2859ec !important;
            padding: 10px 12px;
            border-radius: 50px;
            font-size: 14px;
          }
        }

        .get-btn {
          a {
            background: linear-gradient(89deg, #0e8ffb -3.08%, #0f39b9 95.74%);
            padding: 10px 12px;
            color: #fff !important;
            border-radius: 50px;
            text-decoration: none;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@keyframes customEnterModalAnimation {
  0% {
    transform: translateX(300px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes customLeaveModalAnimation {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(300px);
  }
}

// startup popup

.new-start-popup {
  border-radius: 10px;
  background: $white-color;
  padding: 42px 35px;
  margin-bottom: 0px;
  width: 650px;
  height: 550.17px;
  margin-top: 3px;

  .form-control:focus {
    border: none !important;
  }
  h6 {
    color: #141a29;
    font-family: "Montserrat";
    font-size: 28px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;

    margin-bottom: 20px;
  }
  .input-box {
    margin-top: 6px;
    label {
      color: #141a29;
      font-family: "Montserrat";
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      text-align: left;
    }
    .name_box {
      input {
        border-radius: 3px;
        background: $off-white-color;
        border: 1.5px solid #c5c5ff;
        height: 40px;
        //palceholder
        color: #141a29;
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: 600;
        line-height: 22px;
        text-transform: capitalize;
        padding: 2px 10px;
        height: 40px;
        margin-top: 6px;
        margin-bottom: 10px;
      }
    }

    .des_box {
      textarea {
        border-radius: 4px;
        padding: 2px 10px;
        background: $off-white-color;
        border: 1.5px solid #c5c5ff;
        width: 100%;
        height: 100px;
        // Description
        color: #141a29;
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: 600;
        line-height: 22px; /* 157.143% */
        text-transform: capitalize;
        outline: none;
        width: 100%;
        height: 100px;
        margin-top: 6px;
      }
    }
    .file_div_sec {
      margin-bottom: 25px;
      margin-top: 25px;
      .Upload_logo {
        cursor: pointer;
        border-radius: 4px;
        border: 2px dotted #c5c5ff;
        background: $off-white-color;
        text-align: center;
        height: 100px;
        padding-top: 18px;
        h5 {
          font-family: "Montserrat";
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
          text-align: center;
          color: #3c4c6e;
        }
        input {
          display: none;
          color: #141a29;
          font-family: "Montserrat";
          font-size: 14px;
          font-weight: 500;
          line-height: 22px; /* 122.222% */
          text-transform: capitalize;
        }
      }
    }

    button {
      border-radius: 4px;
      background: $navy-blue-color;
      //button create new project
      color: $white-color;
      font-family: "Montserrat";
      font-size: 12px;
      font-weight: 600;
      line-height: 22px; /* 122.222% */
      text-transform: capitalize;
      height: 40px;
      border: none;
      height: 46px;
    }
  }

  svg {
    fill: #151313;
    width: 20px;
  }
  .top_head {
    text-align: center;
    background-color: #fff;
    color: #fff;
    .body_sec {
      color: $white;
      .left_bar {
        background-color: $white-color;
        margin-top: -41px;
        @media (max-width: "1500px") {
          padding: 5px 15px 30px 15px;
        }
        p {
          font-family: "Inter";

          text-align: center;
          color: #141a29;
          font-family: "Inter";
          font-size: 20px !important;
          font-weight: 400 !important;
          line-height: 36px !important;
        }
        h5 {
          line-height: 48.41px;
          font-family: "Inter";
          line-height: "48.41px";
          font-size: 40px !important;
          font-weight: 600 !important;
          line-height: 24px !important;
          text-align: center;
          color: #141a29;
        }
        .btn_close {
          background-color: $navy-blue-color;
          color: $white;
          border: 1px solid #bbb;
          border-radius: 4px;
          font-weight: 600;
          text-align: center;
          width: 100%;
          padding: 10px;

          &:hover {
            border-color: 1px solid $navy-blue-color;
            background-color: $white-color;
            color: $navy-blue-color;
          }

          svg {
            font-size: 20px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.terms_and_conditions_modal {
  width: 700px !important;
  background: $white !important;
  color: $black !important;
  border-radius: 20px;
  text-align: center;
  padding: 40px 30px;

  p {
    line-height: 36px !important;
  }

  .accept-btn {
    background-color: $active-color;
    border-radius: 6px;
    color: $white;
    width: 216px;
    height: 56px;
    font-size: 22px;
    font-weight: 700;
    border: none;
  }
}

.react-responsive-modal-overlay {
  background: rgb(0 0 0 / 65%) !important;
}

.react-responsive-modal-container {
  overflow-y: hidden;
}
