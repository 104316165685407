.main-popup {
  border-radius: 10px;
  background: #141a29;
  width: 100%;
  padding: 0px;
  background-color: #141a29;
  @media (max-width: "1500px") {
    max-width: 1200px;
  }
}

.invalid-feedback2 {
  position: absolute;
  bottom: 99px;
  left: 33px;
  font-size: 0.995em;
  color: #e1061b;
}
