.all-products {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;

  .main-product {
    background-color: #eef7ff;
    padding: 15px 25px;
    border-radius: 10px;
    width: 49%;
    min-height: 160px;
    cursor: pointer;

    position: relative;

    @media (max-width: 1199px) {
      padding: 15px 15px;
    }
    // @media (max-width: "1350px") {
    //   width: 276.77px;
    //   max-width: 100%;
    // }
    .delete-sec {
      height: fit-content;
      position: relative;
      z-index: 99;
      display: flex;
      justify-content: flex-end;
    }

    .shareOption {
      position: absolute;
      right: -132px;
      top: 46px;
      z-index: 100;
      background-color: $navy-blue-color;
      padding: 8px;
      border-radius: 6px;
      width: 28%;
      @media (max-width: 1599px) {
        width: 30%;
      }

      @media (max-width: 1499px) {
        width: 34%;
        position: absolute;
        right: -43px;
      }
      @media (max-width: 1399px) {
        width: 36%;
      }

      @media (max-width: 1199px) {
        width: 40%;
      }

      @media (max-width: 999px) {
        width: 44%;
      }

      .arrowsvg2 {
        position: absolute;
        top: -15px;
        right: 132px;
        @media (max-width: 1499px) {
          width: 34%;
          right: 24px;
        }
      }
      .shareUl {
        margin-bottom: 10px;
        .shareLi {
          color: #141a29;
          margin-bottom: 0px;
          padding: 4px 9px;
          background-color: #fff;
          margin-top: 10px;
          font-family: Inter;
          font-size: 16px;
          font-weight: 400;
          line-height: 14px;
          text-align: left;
        }
      }
    }

    .inner_project {
      position: relative;
      top: -1rem;
      // overflow: hidden;
      .head-sec {
        display: flex;
        align-items: center;
        gap: 20px;

        .left-sec {
          background-color: #eef7ff;
          border-radius: 10px;

          display: flex;
          justify-content: center;
          align-items: center;
          span {
            color: #141a29;
          }
          // img {
          //   width: 100px;
          //   height: 100px;
          //   padding: 10px;
          // }
          .cloud_logo {
            padding: 14px 15px;
            background-color: white;
            border-radius: 10px;
            img {
              width: 50px;
              height: 50px;
            }
          }
        }
        .body-sec {
          padding: 24px 0;
          color: #141a29;
          min-height: 106px;
          // @media (max-width: 1199px) {
          width: 200px;
          // }
          @media (max-width: 1030px) {
            width: 185px;
          }
          h4 {
            color: #141a29;
            font-family: "Inter";
            font-size: 24px;
            font-weight: 600;
            line-height: 26px;
            text-align: left;
          }

          p {
            margin-bottom: 0px;
            text-align: left;
          }
          .right-sec {
            span {
              color: #141a29;
              font-family: "Inter";
              font-size: 16px;
              font-weight: 500;
              line-height: 21px;
              text-align: left;
            }
          }
        }
        .bottom-sec {
          color: $white-color;
          font-size: 14px;
          display: flex;
          align-items: center;
          gap: 16px;
          margin-bottom: 28px;
          @media (max-width: 1299px) {
            flex-direction: column;
          }

          svg {
            font-size: 18px;
          }
          .left-sec {
            background: #eef7ff;
            border: 1px solid #141a29;
            border-radius: 3px;
            padding: 0px 10px;
            @media (max-width: 1199px) {
              padding: 0px 5px;
            }
            span {
              color: #141a29;
              padding: 4px 6px;
              svg {
                width: 18px;
                height: 15px;
              }
            }
          }

          .mid-sec {
            background: #eef7ff;
            border-radius: 3px;
            border: 1px solid black;
            padding: 4px 2px;
            @media (max-width: 1199px) {
              padding: 4px 0px;
            }
            span {
              color: #141a29;
              padding: 3px 8px;
              svg {
                width: 18px;
                height: 15px;
              }
            }
          }
        }
      }
    }
  }
  .imgofempty {
    @media (max-width: 1399px) {
      width: 400px;
      height: auto;
    }
  }
  .no-data-available {
    color: $main-color;
    text-align: center;
    width: 100%;
    font-family: Inter;
    font-size: 42px !important;
    font-weight: 600;
    @media (max-width: 1399px) {
      font-size: 36px !important;
    }
  }

  .Add_conatiner {
    background-color: #141a29;
    padding: 15px;
    border-radius: 10px;
    width: 328.77px;
    min-height: 280px;
    cursor: pointer;
    text-align: center;
    color: $white-color;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
      font-size: 26px;
      line-height: 1.2em;
      font-weight: 600;
    }
  }
}
