.main-header {
  background-color: $off-white-color;
  padding: 5px 10px;
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-profile {
      display: flex;
      align-items: center;
      gap: 30px;

      .tools-sec {
        display: flex;
        align-items: center;
        gap: 5px;

        span {
          cursor: pointer;

          svg {
            font-size: 26px;
            padding: 0px 5px;
            color: black;
            color: $black;
            &:hover {
              background-color: $navy-blue-color;
              color: $white-color;
              color: $white-color;
              border-radius: 50px;
            }
          }
        }

        .versionsSelectBox {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 12px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
          border-radius: 0.375rem;
          padding: 6px 20px;
          outline: none;
          background-color: #f8f8ff;
          color: #3c4c6e;
          border: 1px solid $light-purple-color;
          option {
            text-transform: capitalize;
          }
        }
      }
    }

    .right-weather {
      display: flex;
      gap: 15px;
      flex-direction: row-reverse;
      align-items: center;

      img {
        width: 40px;
        border-radius: 30px;
      }
      .btn_screen_list {
        display: flex;
        gap: 10px;

        .pubBtn {
          text-decoration: none;
          background-color: #ca33ff;
          color: $white-color;
          padding: 8px 14px;
          border-radius: 5px;
          font-size: 14px;
          border: none;

          &:hover {
            background-color: #ca33ff;
            transition: 0.2s;
          }
        }
        button {
          text-decoration: none;
          background-color: $active-color;
          color: $white-color;
          padding: 8px 14px;
          border-radius: 5px;
          font-size: 14px;
          border: none;

          &:hover {
            background-color: #204ed7;
            transition: 0.2s;
          }
        }
      }
      .profile-main {
        display: flex;
        align-items: center;
        color: $white-color;
        gap: 5px;
        .img-sec {
          img {
            cursor: pointer;
            width: 40px;
            border-radius: 50%;
          }

          .user_first_letter {
            color: #141a29;
            width: 45px;
            border-radius: 30px;
            border: 2px solid #1a237e;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 16px;
            text-transform: uppercase;
            padding-top: 2px;
          }
        }

        .name-sec {
          p {
            margin-bottom: 0;
            cursor: pointer;
            margin-top: 2px;
            color: #141a29;

            font-size: 18px !important;
            font-weight: 500 !important;
            line-height: 21.78px !important;
            text-align: left;
          }
        }
      }
    }
  }
}
