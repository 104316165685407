.Popup-wrap {
  position: absolute;
  background: #141a29;
  padding: 12px;
  top: 17rem;
  left: 20.4%;
  z-index: 1;
  border-radius: 5px;
  border: 1px solid #efefef5e;

  .wrapperpopup {
    width: 345px;
    border-radius: 5px;
    .arrow_sec {
      position: absolute;
      left: 0px;
      top: -20px;
      transform: rotate(90deg);
    }

    .head_section {
      position: relative;

      h6 {
        font-size: 16px;
        font-family: "inter";
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        font-family: "inter";
        margin-bottom: 10px;
        font-weight: 400;
      }
      .input_text_sec {
        input {
          outline: none;
          border-radius: 4px;
          width: 100%;
          background-color: #a7acb9;
          padding: 4px 12px;

          &::placeholder {
            font-size: 14px;
            line-height: 14px;
            color: $white-color;
          }
        }
      }
    }

    .save_cancal_flex {
      display: flex;
      justify-content: flex-start;
      justify-items: center;
      gap: 8px;

      .cancal-btn {
        background-color: #8d9097;
        padding: 6px 16px;
        border: none;
        border-radius: 4px;
        font-size: 14px;
        color: white;
      }
      .save-btn {
        padding: 6px 114px;
        border: none;
        background-color: $active-color;
        border-radius: 4px;
        font-size: 14px;
        color: white;
      }
    }
  }
}
