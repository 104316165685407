.main_Container {
  background-color: #f3f6ff;
  padding: 80px 0;
  height: 100vh;
  display: flex;
  align-items: center;
}
.sub_con {
  border-radius: 20px;
  background: #fff;
  width: 700px;
  padding: 25px;
  margin: auto;
}
.h1_pass h2 {
  color: #000;
  font-size: 36px;
  text-align: center;
  font-weight: 700;
  /* margin-top: 50px; */
}

.p_massage {
  color: #000;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  margin-top: 15px;
  margin-bottom: 30px;
}
.input_email label {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
.input_email input {
  border-radius: 6px;
  background: #f8f8ff;
  margin-top: 12px;
  padding: 14px 18px;
  width: 100%;
  font-size: 15px;
  font-weight: 500;

  margin-bottom: 0px;

  border: 1.5px solid #c5c5ff;
}
/* .input_email {
  margin-top: 12px;
} */
input {
  border: none;
  color: #000;
  font-size: 18px;
  font-weight: 300;
  font-weight: 500;
}

/* .send_btn {
  margin-top: 2px;
} */

.p-max {
  color: #000;
  text-align: center;
  font-size: 15px;
  padding: 10px 43px;
  margin-top: 4px;
  font-weight: 300;
}
.send_btn button {
  border-radius: 8px;
  background: #1a237e;
  color: #fff;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 84.615% */
  text-transform: capitalize;
  width: 100%;
  height: 52px;
  border-radius: 8px;
  border: none;
  margin-top: 30px;
}

.p-max p {
  margin-bottom: 0px;
}

/* Responsive css */

@media (max-width: 991px) {
  .form_container {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .sub_con {
    width: 100%;
  }
}

@media (max-width: 567px) {
  .input_email {
    font-size: 20px;
  }
  .main_Container {
    padding: 30px 0;
  }
  .send_btn {
    margin-top: 5px;
  }
  .p-max {
    padding: 5px 10px;
    margin-bottom: 0px;
  }
}
