.main-scroll {
  height: 74vh;
  overflow-y: scroll;
  position: relative;
  .scrollable-comp {
    .main_bar {
      .specific_bar,
      .specific_color_bar,
      .selected_bar,
      .bg_dropDwon,
      .br_color_dropDown,
      .ph_color,
      .slt_color,
      .text-sltcolor,
      .handle-color-1,
      .handle-2,
      .handle-3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .leftside_text {
          flex-basis: 51%;
          span {
            font-family: "Inter";
            font-size: 15px;
            font-weight: 400;
            line-height: 14px;
            text-align: left;
            color: #141a29;
          }
        }
      }
      .toggle_bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .tgl_text {
          span {
            font-family: "Inter";
            font-size: 15px;
            font-weight: 500;
            line-height: 14px;
            text-align: left;
            color: #141a29;
          }
        }
      }
      .size_bar,
      .size_icon,
      .act-op,
      .dis-op,
      .icon_size,
      .size-input,
      .br_radius {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .size-text {
          flex-basis: 51%;
          span {
            font-family: "Inter";
            font-size: 16px;
            font-weight: 400;
            line-height: 14px;
            color: #141a29;
          }
        }
        .input_fill {
          flex-basis: 49%;
          input {
            width: 100%;
            background-color: $navy-blue-color;
            padding: 0px 10px;
            font-size: 0.75rem;
            border-radius: 4px;
            color: #fff !important;
            margin-bottom: 0px;
            height: 30px;
            text-align: end;
            &::placeholder {
              color: #fff;
              text-align: end;
            }
          }
        }
      }
      .icon_bar,
      .icon_position,
      .row_direact,
      .position-1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .icon-text {
          span {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            text-align: left;
            color: #141a29;
          }
        }
        .drop-menu {
          select {
            background-color: $navy-blue-color;
            padding: 0px;
            font-size: 14px;
            border-radius: 4px;
            color: #fff !important;
            margin-bottom: 0px;
            text-align: end;
            border: none;
          }
        }
      }
    }
  }

  .bottom-sheets {
    .inner {
      .border-color {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #141a29;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 400;
        line-height: 14px;
        color: #141a29;
        .left_sec {
          flex-basis: 51%;

          .first_txt::after {
            content: "Show Vertical Scroll...";
          }
          .first_txt:hover::after {
            content: "Show Vertical Scroll Indicator";
          }

          p {
            font-size: 12px;
          }
        }

        .right_sec {
          flex-basis: 49%;
          input {
            border-radius: 4px;
            background: $navy-blue-color;
            padding: 5px 10px;
            color: $white-color !important;
            height: 30px;
            width: 180px;
            border: none;
            margin-bottom: 0px;
            text-align: right;
            font-size: 14px;

            &::placeholder {
              color: $white-color;
            }
            p {
              text-align: end;
            }
          }

          .css-104gcub-control {
            width: 180px;
          }

          .css-1ocvzpi-control {
            width: 180px;
          }

          select {
            background: transparent;
            border: none;
            color: $white-color;
            font-size: 12px;
            cursor: pointer;
            height: 24px;
            .no_brdr {
              background: $main-color;
              border: none;
              outline: none;
              cursor: pointer;
              padding: 5px;

              &:checked {
                background-color: $main-color;
                border: none;
              }
            }

            &:focus-visible {
              outline: none;
            }
          }

          span {
            font-size: 12px;
          }

          .unit_sec {
            display: flex;
            align-items: center;
            background: $navy-blue-color;
            border-radius: 4px;

            input {
              width: 142px;
            }
          }

          .unit_sec2 {
            text-align: right;
          }
        }

        .snap_pos {
          text-align: right;
          background-color: $navy-blue-color;
          max-width: max-content;
          border-radius: 4px;
        }
      }
    }
  }
}
