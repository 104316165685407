.main-footer {
  // position: absolute;
  bottom: 0;
  left: 0;
  background-color: $white-color;
  width: 100%;
  padding-top: 50px;
  z-index: 99;

  @media ($breckpoint_ESM) {
    padding-top: 10px;
  }

  .inner {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;

    @media ($breckpoint_MD) {
      flex-direction: column;
    }

    @media ($breckpoint_ESM) {
      padding-bottom: 0px;
    }

    .left-footer {
      flex-basis: 30%;

      .logo-sec {
        padding-right: 3rem;

        h3 {
          color: #283249;
          font-family: "Montserrat";
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 40px;
          /* 142.857% */
        }

        p {
          color: #000;
          font-family: "Montserrat";
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          /* 220% */
        }
      }

      .social-sec {
        .social-list {
          list-style: none;
          padding-left: 0px;
          display: flex;
          gap: 10px;
          li {
            background-color: #223A83;
            padding: 3px;
            border-radius: 50px;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            a {
              text-decoration: none;
              color: $white-color;

              svg {
                font-size: 18px;
              }
            }

            &:hover {
              svg {
                transform: scale(0.7);
                transition: 0.3s;
              }
            }
          }
        }
      }
    }

    .right-footer {
      flex-basis: 70%;
      display: flex;
      justify-content: space-between;

      @media ($breckpoint_SM) {
        flex-direction: column;
      }

      .comp-sec {
        h4 {
          color: #000;
          font-family: "Montserrat";
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
        }
      }

      .supp-sec {
        h4 {
          color: #000;
          font-family: "Montserrat";
          font-size: 22px;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
        }
      }

      .start-sec {
        h4 {
          color: #000;
          font-family: "Montserrat";
          font-size: 22px;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
        }
      }

      .prod-sec {
        h4 {
          color: #000;
          font-family: "Montserrat";
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
        }
      }

      .comp-sec,
      .supp-sec,
      .start-sec,
      .prod-sec {
        .list {
          ul {
            line-height: 2.2rem;
            padding-left: 0px;

            @media ($breckpoint_SM) {
              line-height: 1.2rem;
            }

            li {

              a {
                text-decoration: none;
                color: #3C4C6E;
                font-family: "Montserrat";
                font-size: 18px;
                font-weight: 500;
                line-height: normal;
                // color: $footer-text-color;

                @media ($breckpoint_SM) {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }

  .rights-sec {
    background: linear-gradient(#4773f1, #0e38b7);
    padding: 10px 0;
    text-align: center;
    color: $white-color;

    p {
      margin-bottom: 0px;
    }

    svg {
      font-size: 16px;
      margin-top: -3px;
    }
  }
}