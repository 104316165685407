.background-img {
  /* position: cover; */

  background-image: url("../../assets/Image/Homepageimg.svg");
  background-repeat: no-repeat;
  background-size: 1920px auto;
  background-position: center bottom;
  padding-bottom: 200px;
  padding-top: 150px;
  margin-top: -5rem;

  @media ($breckpoint_SM) {
    padding-top: 116px;
  }

  .home_sub {
    .flex_con {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;

      @media (max-width: "784px") {
        flex-direction: column;
      }

      .left_text {
        .h1_text {
          color: $web-main-color;
          font-size: 60px;
          font-weight: 700;

          @media (max-width: "784px") {
            font-size: 30px;
          }
        }

        .para_text {
          color: $lightblue-color;
          font-size: 14px;
          font-weight: 400;
        }

        .banner_btn_sec {
          .lower_start {
            display: flex;
            align-items: center;
            gap: 12px;
            .btn-sec {
              display: flex;
              justify-content: space-between;
              gap: 12px;
              margin-top: 30px;
              .btn-left,
              .btn-right {
                a {
                  font-size: 14px;
                  background-color: #2a5bef;
                  color: $white-color;
                  padding: 10px 12px;
                  border-radius: 4px;
                  text-decoration: none;
                  border: 2px solid #2a5bef;
                  font-weight: 600;

                  svg {
                    margin-top: -1px;
                  }
                  @media ($breckpoint_SM) {
                    font-size: 10px;
                    svg {
                      display: none;
                    }
                  }

                  &:hover {
                    background-color: transparent;
                    color: #2a5bef;
                    transition: 0.3s;
                  }
                }
              }

              .btn-right {
                a {
                  background-color: transparent !important;
                  color: #2a5bef;

                  &:hover {
                    background-color: #2a5bef !important;
                    color: $white-color;
                  }
                }
              }
            }
          }
        }
      }

      .dummy_img {
        width: 100%;
        text-align: right;

        @media ($breckpoint_MD) {
          text-align: center;
        }

        img {
          max-width: 100%;
          position: relative;
          top: 50px;

          @media ($breckpoint_MD) {
            max-width: 50%;
          }

          @media ($breckpoint_SM) {
            max-width: 80%;
          }
        }
      }
    }
  }
}
