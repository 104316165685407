.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100vh;

  .loader {
    border: 4px solid #014e70;
    border-top: 4px solid #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 28rem;
    // background-color: black;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
