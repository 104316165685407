$primary-color: #007bff;

// .App {
//   text-align: center;
//   padding: 50px;

//   .saved-content {
//     margin-top: 20px;
//   }
// }

.EditorModal {
  background-color: #1a237e;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  height: 610px;
  color: $white-color;

  .modal-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 6rem;
      width: 100%;
      .left_sec {
        flex-basis: 50%;
      }

      .right_sec {
        flex-basis: 50%;
        text-align: right;
        display: flex;
        gap: 1px;
        justify-content: end;

        button {
          display: flex;
          align-items: center;
          gap: 6px;
          svg {
            font-size: 17px;
          }
        }
      }
    }
  }

  .tab-button {
    padding: 3px 10px;
    background: none;
    cursor: pointer;
    font-size: 14px;
    background-color: #1a237e;
    margin: 0;
    color: $white;
    border: 2px solid #fff;
    border-radius: 6px;

    &:first-child {
      border-radius: 6px 0 0 6px;
    }
    &:last-child {
      border-radius: 0px 6px 6px 0px;
    }
  }

  .bg_text {
    // background-color: #000;

    button {
      position: absolute;
      font-size: 12px;
      background-color: $bg-editor-color;
      color: $white-color;
      border-radius: 6px;
      border: 1px solid $bg-editor-border-color;
      padding: 1px 5px;
      right: 25px;
      margin-top: 20px;
      display: flex;
      align-items: center;

      svg {
        font-size: 14px;
      }
    }

    .modal-textarea {
      width: 100%;
      margin-top: 10px;
      padding: 10px;
      font-family: monospace;
      background-color: #fff;
      color: #000;
      border: none;
      font-size: 14px;

      &:focus-visible {
        outline: none;
      }
    }
  }

  .error-message {
    color: #f9c266;
    font-size: 12px;
    height: 30px;
  }

  .empty {
    height: 30px;
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    button {
      &:first-child {
        background-color: transparent;
        border: 1px solid gray;
        padding: 2px 10px;
        border-radius: 4px;
        color: $white;
        font-size: 14px;
        font-weight: 600;
      }
      &:last-child {
        background-color: $active-color;
        color: $white;
        font-weight: 600;
        padding: 2px 10px;
        border: none;
        border-radius: 4px;
        font-size: 14px;
      }
    }
  }

  .react-responsive-modal-closeButton {
    display: none;
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

// button {
//   margin: 5px;
//   padding: 10px 20px;
//   cursor: pointer;

//   &:focus {
//     outline: none;
//   }
// }

.EditorModal {
}
