.box_container {
  background: #e3edf2;
  padding: 20px 25px;

  border-left: 3px solid rgb(91.25, 192.1554054054, 222.25);
  .box-comp {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    align-items: center;

    h4 {
      font-size: 16px;
      font-weight: 500;
      color: #46b8da;
    }
  }
  .list-para {
    padding-left: 24px;

    .clone_paras {
      font-family: "Inter";
      font-size: 16px !important;
      font-weight: 400 !important;
      text-align: left;
      color: #283249;
    }
    li {
      font-size: 15px;
      font-weight: 400;
      color: #000;
    }
  }
}

.box_container_alert {
  background: #fdf7f7;
  padding: 20px 25px;
  border-left: 3px solid rgb(216.56875, 83.4, 78.93125);
  .box-comp {
    display: flex;
    justify-content: flex-start;
    gap: 5px;

    h4 {
      font-size: 16px;
      font-weight: 500;
      color: #000;
    }
  }
}
.box_container_update {
  background: #fcf8f2;
  padding: 20px 25px;
  border-left: 3px solid rgb(239.9885321101, 172.878440367, 77.5114678899);
  .box-comp {
    display: flex;
    justify-content: flex-start;
    gap: 5px;

    h4 {
      font-size: 16px;
      font-weight: 500;
      color: #000;
    }
    .clone_paras {
      font-family: "Inter";
      font-size: 16px !important;
      font-weight: 400 !important;
      text-align: left;
      color: #283249;
    }
  }
}
