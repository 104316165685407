.main-set-password {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #f3f6ff;
  // padding: 30px 0;
  // height:100vh;

  .inner {
    display: flex;
    justify-content: center;

    form {
      width: 699px;
      background-color: white !important;
      padding: 40px;
      border-radius: 20px;
      // box-shadow: 1px 1px 40px #0003;
      .main-form {
        h3 {
          text-align: center;
          margin-bottom: 40px;
          font-size: 36px !important;
          font-weight: 700;
        }
        .input-form {
          margin-top: 10px;

          label {
            display: grid;
            margin-bottom: 15px;
            font-weight: 500;
            gap: 5px;

            input {
              // height: 58px;
              // border: 1px solid #0000001f;
              border-radius: 8px;
              padding: 14px 18px;
              font-size: 14px;
              background-color: #f8f8ff;
              margin-bottom: 0px;
              border: 1.5px solid #c5c5ff;
              &:focus-visible {
                outline: none;
              }
            }
          }
        }
      }
    }

    .submit-btn {
      button {
        background: #1a237e;
        width: 100%;
        border-radius: 8px;
        border: none;
        // height: 56px;
        color: $white-color;
        margin-top: 10px;
        padding: 8px 14px;
        font-weight: 600;
        font-size: 26px;
      }
    }
  }
}
