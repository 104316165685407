.scroll-sec {
  .box-1,
  .box-2,
  .box-3,
  .box-4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    .adjuct_img {
      display: flex;
      align-items: center;
      gap: 2px;
      flex-basis: 40%;

      p {
        color: #141a29 !important;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        display: -webkit-box;
        overflow: hidden;
        -webkit-word-clamp: 1;
        -webkit-box-orient: vertical;
        cursor: pointer;
        text-align: left;

        &:hover {
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        @media ($breakpoint_xxl) {
          font-size: 12px;
        }
      }

      img {
        @media ($breakpoint_xxl) {
          width: 16px;
        }

        width: 14px;
      }
    }
  }
  .box-5 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    .max_info {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;
      p {
        color: #141a29 !important;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;

        @media ($breakpoint_xxl) {
          font-size: 12px;
        }
      }

      img {
        @media ($breakpoint_xxl) {
          width: 16px;
        }

        width: 14px;
      }
    }

    .max_input {
      flex-basis: 60%;
      input {
        width: 100%;
        background-color: $navy-blue-color;
        padding: 0px 10px;
        font-size: 14px;
        border-radius: 4px;
        color: $white !important;
        margin-bottom: 0px;
        height: 30px;
        &::placeholder {
          color: #fff;
          font-size: 12px;
          text-align: end;
        }

        &:focus-visible {
          outline: none;
          border: 1px solid $navy-blue-color;
          // border-color: $active-color;
        }
      }
    }
  }
  //   .select-field-1,
  //   .select-field-2 {
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //     margin-bottom: 12px;
  //     .data_img {
  //       display: flex;
  //       justify-content: center;
  //       align-items: flex-end;
  //       gap: 2px;
  //       p {
  //         color: #fff;
  //         font-family: "Inter";
  //         font-size: 12px;
  //         font-weight: 400;

  //         @media ($breakpoint_xxl) {
  //           font-size: 12px;
  //         }
  //       }

  //       img {
  //         @media ($breakpoint_xxl) {
  //           width: 16px;
  //         }

  //         width: 14px;
  //       }
  //       .slt_value {
  //         select {
  //           background-color: #283249;
  //           padding: 2px;
  //           font-size: 14px;
  //           border-radius: 4px;
  //           color: #fff !important;
  //           margin-bottom: 0px;
  //           text-align: end;
  //           border: none;
  //           outline: none;
  //         }
  //       }
  //     }
  //   }
  .access_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    .acess-label {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-basis: 20%;
      p {
        color: #141a29;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;

        @media ($breakpoint_xxl) {
          font-size: 12px;
        }
      }
      img {
        @media ($breakpoint_xxl) {
          width: 16px;
        }
      }
    }
    .option-slt {
      select {
        background-color: $navy-blue-color;
        padding: 8px;
        font-size: 14px;
        border-radius: 4px;
        color: #fff !important;
        margin-bottom: 0px;
        text-align: left;
        border: none;
        outline: none;
        width: 100%;
      }
    }
  }
}
