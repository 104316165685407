.image_assets_modal {
  max-width: 1438px;
  width: 100%;
  padding: 0px;
  @media ($breakpoint_xxl) {
    max-width: 1270px;
    height: 600px;
  }

  .top_head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 12px;
    padding-left: 12px;
    background-color: $navy-blue-color;
    color: $white;
  }

  .body_sec {
    display: flex;

    .left_bar {
      flex-basis: 15%;
      background-color: $navy-blue-color;
      display: flex;
      align-items: baseline;

      span {
        background-color: $cloud-color;
        padding: 5px;
        width: 100%;
        color: $white;
        padding-left: 12px;
        font-weight: 600;
        height: 40px;
        display: flex;
        align-items: center;
      }
    }

    .main_bar {
      flex-basis: 85%;
      padding: 10px;
      background-color: #fff;
      color: $white;
      height: 600px;
      overflow-y: scroll;

      @media (max-width: "1500px") {
        height: 476px;
      }

      .image-uploader {
        display: flex;
        gap: 10px;
        margin-top: 10px;
        .upload-container {
          min-width: 225px;
          height: 210px;
          display: flex;
          align-items: center;
          flex-direction: column;
          background-color: $cloud-color;
          justify-content: center;
          text-align: center;
          cursor: pointer;
          gap: 12px;

          svg {
            transform: rotate(180deg);
            font-size: 40px;
            color: #fff;
          }

          .clr {
            color: $white;
            font-weight: 600;
          }

          //   &:hover {
          //     background-color: $main-color;
          //   }
        }

        .image-list {
          .all_images {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            margin-bottom: 0px;
            li {
              background-color: #42a5f554;
              // background-position: 0 0, 0 5px, 5px -5px, -5px 0;
              background-size: 10px 10px;
              margin-bottom: 5px;
            }

            .image-box {
              cursor: pointer;
              width: 234px;
              height: 211px;
              img {
                padding: 20px;
                object-fit: contain;
              }

              .image_name {
                // background-color: #42a5f554;
                padding: 8px;
                width: 100%;
                position: relative;
                bottom: -26px;
                color: $main-color;
              }

              .delete-icon {
                display: flex;
                justify-content: flex-end;
                position: relative;
                top: 1rem;
                width: fit-content;
                left: 11.8rem;
                background: white;
                border-radius: 50px;
                color: $bg-color;
                cursor: pointer;

                &:hover {
                  // background: rgb(184, 167, 167);
                }
              }

              &:hover {
                .image_name {
                  // background-color: #717e9f;
                }
              }
            }
          }
        }
      }
    }
  }

  .react-responsive-modal-closeButton {
    fill: $white;
  }

  .done_btn {
    background-color: $navy-blue-color;
    display: flex;
    justify-content: center;
    align-items: center;
    .main_btn {
      padding: 15px 0;
      .btn_close {
        background-color: $cloud-color;
        color: $white;
        padding: 8px 16px;
        border: none;
        border-radius: 4px;
        border: none;
      }
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.image_assets_Delete_Modal {
  width: 320px;
  height: 220px;
  text-align: center;
  background-color: $main-color;
  color: $white;
  overflow-y: hidden;
  padding: 15px 0px;
  border-radius: 10px;
  .bld {
    font-weight: 600;
    margin: 16px 0 10px 0;
    font-size: 16px;
  }

  .btns2 {
    padding: 5px 12px;
    border-radius: 4px;
    margin-right: 5px;
    margin-top: 8px;
    border: none;
  }

  .delete {
    background-color: #fb434a;
    color: $white;
  }

  .react-responsive-modal-closeButton {
    fill: $white;
  }
}
