.main-right {
  background-color: $off-white-color;
  // height: 100vh;

  .inner {
    .preview-bar {
      background-color: $navy-blue-color;
      color: $white-color;
      padding: 10px;
      .top {
        display: flex;
        align-items: center;
        gap: 10px;

        span {
          font-size: 12px;

          svg {
            font-size: 16px;
            margin-top: -2px;
          }

          &:first-child {
            padding-right: 0px;
          }
        }
      }

      .bottom {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-top: 7px;
        .enviorment {
          background-color: $white-color;
          // padding: 4px 10px 3px 10px;
          height: 30px;
          display: flex;
          font-size: 14px;
          cursor: pointer;
          align-items: center;
          padding: 5px 5px 2px 5px;
          color: $navy-blue-color;
          &.active {
            background-color: $cloud-color;
            path {
              stroke: white;
            }
          }
          &:hover {
            path {
              stroke: white;
            }
          }
          svg {
            font-size: 14px;
            margin-top: -2px;
          }

          &:first-child {
            border-radius: 0;
          }

          &:last-child {
            border-radius: 0;
          }

          &:hover {
            background-color: $active-color;
            color: $white-color;
          }

          &.active {
            background-color: $cloud-color;
            path {
              stroke: white;
            }
          }
        }
        .enviorment2 {
          background-color: $white-color;
          height: 30px;
          display: flex;
          font-size: 14px;
          cursor: pointer;
          align-items: center;
          padding: 6px;
          border-radius: 0px;
          &.active {
            background-color: $cloud-color;
            path {
              stroke: white;
            }
          }
          &:hover {
            background-color: $active-color;
            path {
              stroke: white;
            }
          }
          &.active {
            background-color: $cloud-color;
            path {
              stroke: white;
            }
            svg {
              font-size: 14px;
              margin-top: -2px;
            }
          }
        }
        .enviormentActive {
          background-color: #3366ff;
          // padding: 4px 10px 3px 10px;
          height: 30px;
          display: flex;
          font-size: 14px;
          cursor: pointer;
          align-items: center;
          padding: 6px;
          svg {
            font-size: 14px;
            margin-top: -2px;
          }

          &:first-child {
            border-radius: 0px;
          }

          &:last-child {
            border-radius: 0px;
          }

          &:hover {
            background-color: $active-color;
          }

          &.active {
            background-color: $cloud-color;
            path {
              stroke: white;
            }
          }
        }

        .export_button {
          background-color: $active-color;
          color: $white;
          border: none;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 600;
          height: 30px;
          padding: 0px 15px;

          &:hover {
            background-color: #204ed7;
            transition: 0.2s;
          }

          @media (max-width: "1390px") {
            font-size: 11px;
          }
        }
      }
    }

    .order-bar {
      background-color: $navy-blue-color;
      padding: 10px;
      margin-top: 5px;
      height: 44px;
      display: flex;
      align-items: center;
      .inner {
        display: flex;
        align-items: center;
        color: $white-color;
        gap: 10px;
        span {
          font-size: 14px;

          svg {
            font-size: 16px;
            margin-top: -2px;
          }
        }
      }
    }

    .setting-bar {
      display: flex;
      justify-content: space-between;
      text-align: center;
      margin-top: 5px;
      color: $white-color;
      width: 100%;
      padding: 8px;

      .bg-hvr {
        width: 100%;
        padding: 8px;
        cursor: pointer;

        border: 1px solid $white-color;
        background-color: $white-color;

        .firstTabText1 {
          display: none;
        }
        .firstTabText {
          display: none;
        }
        svg {
          color: $white-color;
        }
        &:hover {
          background-color: $blue-d-color;
        }
      }
      .bg-hvrActive {
        width: 100%;
        padding: 8px;
        cursor: pointer;
        background-color: $navy-blue-color;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .firstTabText1 {
          color: #fff;
          font-family: Inter;
          font-size: 10px;
          font-weight: 500;
          line-height: 14px;
          text-align: left;
          margin-left: -10px;
        }
        .firstTabText {
          color: #fff;
          font-family: Inter;
          font-size: 10px;
          font-weight: 500;
          line-height: 14px;
          text-align: left;
        }

        .icon_all {
          filter: brightness(20);
        }

        &:hover {
          background-color: $blue-d-color;
          .firstTabText {
            color: $navy-blue-color;
          }
          .firstTabText1 {
            color: $navy-blue-color;
          }
          .icon_all {
            filter: none;
          }
        }
      }
    }
  }

  @include switch-setting();
}
