.popupDashboard-Wrapper {
  background: $navy-blue-color;
  width: 230px;

  .head_main {
    position: relative;
    .arrowSvg {
      position: absolute;
      left: -19px;
      top: 2px;
    }
    .headSection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      background: $white-color;
      color: $dark-grey-color;
      padding: 5px;
      border: 2px solid $navy-blue-color;
      svg {
        cursor: pointer;
      }
    }
  }
  .initialSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 8px;
    position: relative;
    .arrowSvg {
      position: absolute;
      left: -19px;
      top: 2px;
    }
    .blankScreen {
      background: $white-color;
      padding: 5px;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      width: 100%;
      margin-bottom: 10px;
      cursor: pointer;
      color: $dark-grey-color;
    }
    .exampleScreen {
      background: $white-color;
      padding: 5px;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      width: 100%;
      cursor: pointer;
      color: $dark-grey-color;
    }
  }
  .add_section {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .add_input {
      width: 90%;
      outline: none;
      border-radius: 5px;
      padding: 2px 5px;
      color: $dark-grey-color;
    }
    .add_button {
      color: #fff;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px; /* 100% */
      background: #36f;
      border: none;
      padding: 6px 15px;
    }
  }

  .componentWrapper {
    .comp_btns {
      padding: 2px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .bitBlock_btn_active {
        background: #36f;
        color: #fff;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        padding: 10px;
        border: none;
        width: 50%;
      }
      .bitBlock_btn {
        border-bottom: 3px solid #404b69 !important;
        opacity: 0.7;
        color: #fff;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        padding: 10px;
        border: none;
        width: 50%;
      }
    }
    .all_components {
      display: flex;
      flex-wrap: wrap;
      height: 330px;
      overflow: scroll;
      .comp_box {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 25px 0px;
        row-gap: 10px;
        border-bottom: 1px $navy-blue-color solid;

        cursor: pointer;
        .comp_name {
          color: #fff;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 14px;
        }

        &:first-child {
          border-right: 1px $navy-blue-color solid;
        }
        &:nth-child(odd) {
          border-right: 1px $navy-blue-color solid;
        }

        &:hover {
          background-color: $active-color;
        }
      }

      @media (max-width: "1390px") {
        height: 190px;
        overflow-y: scroll;
      }
    }
    .YourBlock_main {
      .blocks {
        display: flex;
        align-items: center;
        gap: 5px;
        background: $navy-blue-color;
        padding: 8px;
        margin-bottom: 4px;
        .textHead {
          color: #fff;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
      }
      .blockList {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        background-color: $navy-blue-color;
        margin-bottom: 2px;
        cursor: pointer;
        .listLeft {
          display: flex;
          align-items: center;
          width: 85%;
          gap: 5px;
          .white_box {
            border-radius: 2px;
            background: #fff;
            width: 25px;
            height: 25px;
            padding: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg path {
              font-size: 12px;
            }
          }
          .listName {
            color: #fff;
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px; /* 160% */
            margin-bottom: 0;
          }
        }
        .deleteIconContainer {
          width: 25px;
        }

        &:hover {
          background-color: $active-color;
        }
      }
    }
  }
}

.popupDashboard-Wrappers {
  background: $navy-blue-color;
  width: 230px;

  .head_main {
    position: relative;
    .arrowSvg {
      position: absolute;
      left: -19px;
      top: 2px;
    }
    .headSection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $navy-blue-color;
      padding: 5px;
      svg {
        cursor: pointer;
      }
    }
  }
  .initialSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 8px;
    position: relative;
    .arrowSvg {
      position: absolute;
      left: -19px;
      top: 2px;
    }
    .blankScreen {
      background: $navy-blue-color;
      padding: 5px;
      text-align: center;
      width: 100%;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .exampleScreen {
      background: $navy-blue-color;
      padding: 5px;
      text-align: center;
      width: 100%;
      cursor: pointer;
    }
  }
  .add_section {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .add_input {
      width: 90%;
      outline: none;
      border-radius: 5px;
      padding: 2px 5px;
    }
    .add_button {
      color: #fff;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px; /* 100% */
      background: #36f;
      border: none;
      padding: 6px 15px;
    }
  }

  .componentWrapper {
    .comp_btns {
      padding: 2px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $off-white-color;
      color: $navy-blue-color;
      .bitBlock_btn_active {
        border-bottom: 3px solid #3366ff !important;

        color: $navy-blue-color;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        padding: 10px;
        border: none;
        width: 50%;
      }
      .bitBlock_btn {
        border-bottom: 3px solid #404b694f !important;

        opacity: 0.7;
        color: $navy-blue-color;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        padding: 10px;
        border: none;
        width: 50%;
      }
    }

    .all_components {
      display: flex;
      flex-wrap: wrap;
      height: 330px;
      overflow: scroll;
      background-color: $off-white-color;
      .comp_box {
        display: flex;
        align-items: center;
        width: 100%;
        background: #1a237e;
        padding: 12px 26px;
        margin-bottom: 8px;
        margin-top: 8px;
        cursor: pointer;
        gap: 42px;
        svg {
          path {
            fill: white;
            stroke: white;
          }
        }

        &:hover {
          svg {
            path {
              fill: #3c4c6e;
              stroke: #3c4c6e;
            }
          }
          .comp_name {
            color: #3c4c6e;
            font-weight: 500;
          }
        }
        .comp_name {
          color: #fff;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin-bottom: 0px;
        }

        // &:first-child {
        //   border-right: 1px $navy-blue-color solid;
        // }
        // &:nth-child(odd) {
        //   border-right: 1px $navy-blue-color solid;
        // }

        &:hover {
          background-color: #838ad330;
          color: $navy-blue-color;
        }
      }
      .no_comp_box {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 12px 26px;
        margin-bottom: 8px;
        margin-top: 8px;
        cursor: pointer;
        gap: 42px;
        background-color: #838ad330;
        color: $navy-blue-color;
        svg {
          path {
            fill: #3c4c6e;
            stroke: #fff;
          }
        }

        .comp_name {
          color: #3c4c6e;
          font-weight: 500;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          line-height: 16px;
          margin-bottom: 0px;
        }

        // &:first-child {
        //   border-right: 1px $navy-blue-color solid;
        // }
        // &:nth-child(odd) {
        //   border-right: 1px $navy-blue-color solid;
        // }
      }
      .no_comp_box2 {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 12px 26px;
        margin-bottom: 8px;
        margin-top: 8px;
        cursor: pointer;
        gap: 42px;
        background-color: #838ad330;
        color: $navy-blue-color;
        svg {
          path {
            fill: #3c4c6e;
            stroke: #3c4c6e;
          }
        }

        .comp_name {
          color: #3c4c6e;
          font-weight: 500;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          line-height: 16px;
          margin-bottom: 0px;
        }

        // &:first-child {
        //   border-right: 1px $navy-blue-color solid;
        // }
        // &:nth-child(odd) {
        //   border-right: 1px $navy-blue-color solid;
        // }
      }

      @media (max-width: "1390px") {
        height: 190px;
        overflow-y: scroll;
      }
    }

    .YourBlock_main {
      background-color: $off-white-color;
      .blocks {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $navy-blue-color;
        padding: 8px;
        margin-bottom: 10px;
        margin-top: 10px;
        .textHead {
          display: flex;
          align-items: center;
          color: $white-color;
          font-size: 14px;
          font-style: normal;
          font-weight: 600 !important;
          line-height: 16px;
        }
      }
      .blockList {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        background-color: $navy-blue-color;
        &:hover {
          .listLeft {
            .listName {
              color: #3366ff;
            }
          }
        }

        cursor: pointer;
        margin-bottom: 10px;
        .listLeft {
          display: flex;
          align-items: center;
          width: 85%;
          gap: 5px;

          .listName {
            color: #fff;
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px; /* 160% */
            margin-bottom: 0;
          }
        }
        .flex_svg {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          .white_box {
            border-radius: 2px;
            background: #fff;
            padding: 2px 4px;
          }
          .deleteIconContainer {
            padding: 2px 4px;
            background-color: #ffc5c7;
            border-radius: 2px;
          }
        }
        &:hover {
          background-color: rgba(131, 138, 211, 0.1882352941);
        }
      }
    }
  }
}
