.con_sign {
  padding: 80px 0;
  background: hwb(225 86% 1%);
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  align-items: center;

  .Sign {
    border-radius: 20px;
    background: #ffff;
    width: 699px;
    padding: 30px;
    margin: auto;

    h2 {
      color: #000;
      text-align: center;

      font-size: 36px;
      font-weight: 700;
      margin-bottom: 45px;
      margin-top: 25px;
    }
    .flex_input {
      display: flex;
      align-items: center;
      gap: 20px;
      outline: none;

      @media (max-width: "568px") {
        gap: 0px;
      }

      .input_user {
        flex-basis: 50%;
        color: black;
        font-weight: 400;
        font-family: "Montserrat";
        font-size: 16px;
        outline: none;
        padding-top: 4px;
        label {
          color: #000;
          font-family: "Montserrat";
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          margin-bottom: 5px;
        }

        input {
          width: 100%;
          border-radius: 6px;
          background: #f8f8ff;
          border: 1.5px solid #c5c5ff;
          outline: none;
          padding: 14px 18px;
          font-family: "Montserrat";
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 5px;
        }
      }
      .err_msg_resp {
        @media (min-width: "568px") {
          display: none;
        }
        width: 100%;
      }
    }

    .input_width {
      padding-top: 12px;

      label {
        color: #000;
        font-family: "Montserrat";
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 5px;
      }

      .input {
        width: 100%;
        border-radius: 6px;
        background: #f8f8ff;
        border: 1.5px solid #c5c5ff;
        outline: none;
        padding: 14px 18px;
        margin-bottom: 5px;
        font-family: "Montserrat";
        outline: none;
        margin-top: 5px;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
      }
    }
    .submiit_btn button {
      border-radius: 8px;
      background-color: $navy-blue-color;
      border: none;
      width: 100%;
      padding: 11px 14px;
      margin-top: 15px;
      font-family: "Montserrat";
      font-size: 20px;
      font-weight: 700;
      color: #f3f6ff;
      &:hover {
        background: #0aabe6;
        transition: 0.3s;
      }
    }
    .terms_conditions {
      padding: 14px 12px;
      .check_term_box {
        width: 15px;
        height: 23px;
        margin-bottom: 0px;

        input {
          display: flex;
          justify-content: left;
          align-items: center;
          flex-direction: row-reverse;
          gap: 12px;
        }
      }
    }
  }
}
