// all colors variable
$main-color: #141a29;
$secondary-color: #283249;
$active-color: #3366ff;
$white-color: #fff;
$black-color: #000;
$bg-color: #404b69;
$border-color-select: #384053;
$blue-color: #00c;
$skyblue-color: #2e484b;
$lightblue-color: #283249;
$web-main-color: #2c5df2;
$gradient-color: linear-gradient(90deg, #4a75f3 0%, #123cbc 99.94%);
$gradient-bg-color: linear-gradient(180deg, #ecf5fe 0%, #ecf5fe 100%);
$lightblue-bg: #141a29;
$red-bg: #fb434a;
$grey-color: #aaaaaa;
$border-color-dash: #313849;
$dash-bg-color: #232a3c;
$modal-bg-color: #25324b;
$light-blue-color: #3d4b6c;
$dark-blue-color: #26324b;
$bg-editor-color: #334155;
$bg-editor-border-color: #475569;
$bg-color-editor-header: #1e293b;
$modal-bg-color2: #0f172a;
$cloud-color: #42a5f5;
$navy-blue-color: #1a237e;
$dark-grey-color: #283249;
$light-purple-color: #c1cade;
$off-white-color: #f8f8ff;
$grey-dark-color: #3c4c6e;
$green-color: #4caf50;
$blue-d-color: #838ad330;
$sky-cloud-color: #0aabe6;

//breakpoints
$breakpoint_xxxl: "max-width:1899px";
$breakpoint_xxl: "max-width:1700px";
$breakpoint_xxm: "max-width:1199px";
$breckpoint_XXL: "max-width: 1400px";
$breckpoint_XL: "max-width: 1200px";
$breakpoint_XXN: "max-width:1099px";
$breckpoint_LG: "max-width: 992px";
$breckpoint_MD: "max-width: 768px";
$breckpoint_SM: "max-width: 576px";
$breakpoint_MS: "max-width: 420px";
$breckpoint_ESM: "max-width: 385px";
$breckpoint_340: "max-width: 355px";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.coustom_container {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 0 100px;

  @media ($breckpoint_XXL) {
    padding: 0 70px;
  }

  @media ($breckpoint_LG) {
    padding: 0 30px;
  }

  @media ($breckpoint_MD) {
    padding: 0 18px;
  }

  @media ($breckpoint_SM) {
    padding: 0 15px;
  }
}
.coustom_container2 {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 0 100px;

  @media ($breckpoint_XXL) {
    padding: 0 70px;
  }

  @media ($breckpoint_LG) {
    padding: 0 30px;
  }

  @media ($breckpoint_MD) {
    padding: 0 18px;
  }

  @media ($breckpoint_SM) {
    padding: 0 15px;
  }
}

body {
  overflow-x: hidden;
  font-family: "Montserrat" !important;
}

h1 {
  font-size: 60px;
  font-weight: 700;
  line-height: 1.2em;

  @media ($breckpoint_MD) {
    font-size: 32px !important;
    letter-spacing: -1.5px;
  }

  @media ($breckpoint_SM) {
    font-size: 24px !important;
    letter-spacing: -1.5px;
  }
}

h2 {
  font-size: 36px;
  line-height: 1.2em;
  font-weight: 700;

  @media ($breckpoint_SM) {
    font-size: 35px !important;
  }
}

h3 {
  font-size: 28px;
  line-height: 1.2em;
  font-weight: 700;
}

h4 {
  font-size: 20px;
  line-height: 1.2em;
  font-weight: 600;

  @media ($breckpoint_MD) {
    font-size: 16px !important;
  }
}

p {
  font-size: 14px !important;
  font-weight: 500 !important;
  // line-height: 28px !important;

  margin-bottom: 0px;
  @media (max-width: 1399px) {
    font-size: 13px;
  }
}

// common css

.main-dash {
  display: flex;
  justify-content: space-between;
  padding-top: 1px;
  background-color: #fff;
  height: 100vh !important;
  // overflow-y: hidden;
  .left-sec {
    flex-basis: 20%;

    @media (max-width: "1500px") {
      flex-basis: 33%;
    }

    @media (min-width: "5000px") {
      flex-basis: 8%;
    }
  }
  .main-midSec {
    flex-basis: 55%;
    height: 850px;
    overflow: auto;

    @media (min-width: "5000px") {
      height: 100vh;
    }
    @media (max-width: "1700px") {
      flex-basis: 50%;
      height: 650px;
      overflow: auto;
    }
    .mid-sec {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      // height: 100%;
      // overflow-y: scroll;
      @media (max-width: "1700px") {
        height: 800px;
      }
      @media (max-width: "1550px") {
        height: 740px;
      }
      @media (max-width: "1530px") {
        height: 580px;
      }

      // min-height: 900px;
      // overflow-y: scroll;
      align-items: center;
      // padding: 1.5rem 0;
      // padding-bottom: 9.6rem;
      padding-top: 20px;

      .top_screen_sec {
        margin-bottom: 20px;
        .inner {
          display: flex;
          align-items: center;
          gap: 15px;

          @media (max-width: "1500px") {
            gap: 6px;
          }

          .main_screen {
            background-color: $navy-blue-color;
            color: $white-color;
            display: flex;
            align-items: center;
            gap: 15px;
            padding: 8px 16px;
            border-radius: 5px;

            @media (max-width: "1500px") {
              gap: 6px;
            }

            p {
              font-weight: 600;
            }
          }

          .view_sec {
            display: flex;
            align-items: center;
            gap: 2px;
            .portrait_view {
              background-color: $red-bg;
              padding: 6px 12px;
              border-radius: 6px 0 0 6px;
              cursor: pointer;

              @media (max-width: "1500px") {
                padding: 6px 8px;
              }
            }

            .landscape_view {
              background-color: $green-color;
              padding: 6px 12px;
              border-radius: 0 6px 6px 0;
              cursor: pointer;
              @media (max-width: "1500px") {
                padding: 6px 8px;
              }
            }

            // .portrait_view.active,
            // .landscape_view.active {
            //   background-color: $light-blue-color !important;
            // }
          }

          .screen_percentage_sec {
            display: flex;
            background-color: $navy-blue-color;
            padding: 8px 12px;
            border-radius: 6px;

            @media (max-width: "1500px") {
              padding: 8px 6px;
            }

            .percentage {
              display: flex;
              gap: 6px;
              span {
                color: $white-color;
                font-weight: 600;
              }

              select {
                background: transparent;
                border: none;
                color: $white;
                width: 75px;
                cursor: pointer;

                option {
                  background: $main-color;
                  border-radius: 4px;
                  font-size: 14px;
                  padding: 3px;
                  cursor: pointer !important;
                }

                &:focus {
                  border: none;
                  outline: none;
                }
              }
            }
          }
        }
      }
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .right-sec {
    flex-basis: 20%;
    overflow-y: hidden;

    @media (min-width: "5000px") {
      flex-basis: 10%;
    }

    @media (max-width: "1500px") {
      flex-basis: 30% !important;
    }

    @media (max-width: "1800px") {
      flex-basis: 24%;
    }
  }
}

@mixin accordion-setting {
  .accordion {
    .accordion-item {
      background-color: $main-color;
      border: none;
      .accordion-header {
        color: #fff;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 87.5% */
        img {
          padding: 8px;
        }
        button {
          background-color: $navy-blue-color;
          color: $white-color;
          display: flex;
          padding-left: 40px;
          box-shadow: none;
          height: 44px;
          font-size: 14px;
          border-radius: 0;
          margin-top: 5px;
          &:after {
            margin-right: auto;
            left: 12px;
            filter: brightness(60.5);
            position: absolute;
          }

          &:focus {
            box-shadow: none;
          }

          // &:first-child {
          //   margin-top: 0px;
          // }
        }
      }

      .accordion-collapse,
      .collapse,
      .show {
        .accordion-body {
          background-color: $off-white-color !important;
          padding: 10px 15px 5px 15px;

          // @media ($breakpoint_xxl) {
          //   padding: 15px 15px 10px 8px;
          // }
          @media (max-width: 1399px) {
            padding: 10px 10px 5px 8px;
          }
        }
      }
    }

    .accordion-button {
      &::after {
        width: 1rem;
        height: 0.9rem;
        background-size: 1rem;
      }
    }
  }
}

@mixin switch-setting {
  .react-switch-bg {
    background: $navy-blue-color !important;
    height: 22px !important;
    width: 50px !important;
    top: 4px;
    box-shadow: "0px 1px 5px rgba(0, 0, 0, 0.6)" !important;
    // background-color: #3366ff !important;

    svg {
      display: none;
    }
  }

  .react-switch-handle {
    margin-right: -2px !important;
    height: 25px !important;
    width: 24px !important;
    top: 3px !important;
    // background: #3366ff !important;
    background: rgb(255, 255, 255) !important;
    &:focus {
      outline: none;
      box-shadow: none;
      // background: rgba(250, 242, 242, 0.95);
    }
  }
}

ul {
  list-style: none;
  padding-left: 0px;
}

a {
  text-decoration: none;
}

.login-google {
  display: flex;
  justify-content: center;
  width: 100%;
  border: 1px solid $active-color !important;
  margin-top: 10px;
  border-radius: 10px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #3366ff !important;
  word-spacing: 2px;
  box-shadow: none !important;
}

.logout-google {
  border-radius: 50px !important;
  box-shadow: none !important;
}

.google_btn {
  @media ($breckpoint_MD) {
    padding: 0px !important;
  }
}

.send_btn {
  button {
    &:hover {
      background: #2d59db;
      transition: 0.3s;
    }
  }
}

.div-btn {
  button {
    font-weight: 700 !important;

    &:hover {
      background: #0aabe6;
      transition: 0.3s;
    }
  }
}

.submit-btn {
  button {
    &:hover {
      background: #2d59db !important;
      transition: 0.3s;
    }
  }
}

// div{

//   border: 1px solid red;
// }

.h3_Sign,
.h3_pass {
  &:hover {
    color: #918d8d;
  }
}

// .toast.succes{
//   background-color: $web-main-color;
// }

.Toastify__progress-bar {
  background: $web-main-color !important;
}

.Toastify__toast {
  @media ($breckpoint_MD) {
    transform: translateY(60px);
    padding: 0px 15px !important;
    margin: 0px 15px;
  }
}

.terms_conditions {
  label {
    display: flex;
    gap: 5px;
  }

  @media ($breckpoint_SM) {
    font-size: 14px;
  }
}

.Sign,
.form_container {
  @media (max-width: "1500px") {
    width: 50% !important;
  }

  @media ($breckpoint_LG) {
    width: 75% !important;
  }

  @media ($breckpoint_SM) {
    width: 100% !important;
  }
}

.get {
  @media ($breckpoint_ESM) {
    flex-direction: column;
    gap: 10px;
  }
}

.error-msg,
.erros_msg {
  font-size: 15px;
  position: relative;
  left: 8px;
  color: red;
}

.error_outside {
  @media (max-width: "568px") {
    display: none;
  }
  .err_msg {
    color: red;
    width: 50%;
    display: inline-flex;
    justify-content: space-between;
    .error-msg {
      color: red;
    }
  }
}
.submiit_btn {
  button {
    &:hover {
      background: #0aabe6;
      transition: 0.3s;
    }
  }
}

.mid_h1 {
  @media ($breckpoint_SM) {
    font-size: 28px;
  }
}

.f_max_withd {
  h2 {
    color: #000;
    font-family: "Montserrat";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.44px;
    text-transform: capitalize;
  }
}

.section_fourth {
  .left {
    .box-2 {
      h2 {
        font-size: 48px;
        font-weight: 700;
      }
    }
  }
}

.logout-btn {
  text-decoration: none;
  background-color: $white-color;
  color: $cloud-color;
  padding: 8px 14px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 700;
}

.css-1bcfi89-MuiTreeItem-content.Mui-selected {
  background-color: rgb(235 235 235 / 0%) !important;
  padding: 0px 8px !important;
}
.eye_option_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.chrome-picker {
  position: absolute;
  z-index: 99;
  height: 250px;
  // bottom: 5rem;
  // right: 4rem;
}
.tool_tranform {
  font-family: "Inter";
  font-weight: 300;
  font-size: 15px;
  text-transform: capitalize;
  letter-spacing: normal;
  background: red !important;
}
.font-picker {
  font-size: 16px;
  width: 200px;
}

.font-picker select {
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.font-picker h1 {
  font-size: 1.5rem;
  margin-top: 8px;
}

.bg_fetch_api {
  background: black;
  height: 200px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.variable_bar {
  .main_variables {
    .inner_var {
      display: flex;
      color: $white;
      align-items: center;
      justify-content: space-between;
      .var_title {
        flex-basis: 20%;
      }

      .var_selector {
        flex-basis: 80%;
        .css-b62m3t-container {
          .css-1dldku-control {
            width: 100% !important;
          }
          .css-1vbdzxn-control {
            width: 100% !important;
          }
        }
      }
    }
  }
}

.custom-table {
  color: #ffffff !important;
}

.custom-table th,
.custom-table td {
  border-color: rgba(255, 255, 255, 0.2) !important;
  background-color: transparent !important;
  color: #ffffff !important;
}

.custom-table tbody tr:nth-child(even) {
  background-color: rgba(15, 23, 42, 0.4);
}

.custom-table tbody tr:nth-child(odd) {
  background-color: transparent;
}

.select__control.css-1pjcsdu-control {
  @media (max-width: "1500px") {
    width: 100%;
  }
}
.select__control.css-1aciz1m-control {
  @media (max-width: "1500px") {
    width: 100%;
  }
}

.css-1aciz1m-control {
  width: 100% !important;
}
.css-1pjcsdu-control {
  width: 100% !important;
}

// .cbQmzf {
//   bottom: 0 !important;
// }
// .css-lo1h8q-control {
//   width: 190px;
// }
