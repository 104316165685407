.spinner-border2 {
  width: 1rem;
  height: 1rem;
  border: 0.15em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spin 0.75s linear infinite;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}
