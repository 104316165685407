.versionUpdation {
  width: 530px;
  height: 440px;
  border-radius: 10px;
  .versionWrapper {
    padding: 20px;
    section {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      img {
        width: 130px;
        height: auto;
      }
    }
    .textUpdate {
      h4 {
        font-family: Inter;
        font-size: 34px;
        font-weight: 600;
        line-height: 48.41px;
        text-align: center;
        color: #141a29;
      }
      p {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
      }
    }
    .btnSection {
      padding: 20px 0px 0px 0px;
      .btnUpdate {
        background-color: #1a237e;
        padding: 5px;
        text-align: center;
        border-radius: 4px;
        color: #fff;
        width: 100%;
        border: none;
      }
      p {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 0px;
        cursor: pointer;
      }
    }
  }
}
.versionDeletion {
  width: 530px;
  height: 380px;
  border-radius: 10px;
  .versionDiscardWrapper {
    padding: 20px;
    section {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .textUpdate {
      margin-top: 50px;
      h4 {
        font-family: Inter;
        font-size: 34px;
        font-weight: 600;
        line-height: 48.41px;
        text-align: center;
        color: #141a29;
      }
      p {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
      }
    }
    .btnSection {
      padding: 20px 0px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btnUpdate {
        background-color: #1a237e;
        padding: 5px;
        text-align: center;
        border-radius: 4px;
        color: #fff;
        width: 40%;
        border: none;
        outline: none;
      }
      .btnDelete {
        background-color: #1a237e;
        outline: none;
        padding: 5px;
        text-align: center;
        border-radius: 4px;
        color: #fff;
        width: 40%;
        border: none;
      }
    }
  }
}
