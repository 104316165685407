.scroll-sec {
  .access_box,
  .access_navtive {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    .acess-label {
      display: flex;
      align-items: center;
      gap: 2px;
      flex-basis: 40%;
      p {
        color: #141a29 !important;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;

        @media ($breakpoint_xxl) {
          font-size: 12px;
        }
      }
      img {
        @media ($breakpoint_xxl) {
          width: 16px;
        }

        width: 14px;
      }
    }
    .type-sec {
      // flex-basis: 60%;   
      input {
        width: 100%;
        background-color: $navy-blue-color;
        padding: 0px 10px;
        font-size: 14px;
        border-radius: 4px;
        color: $white !important;
        margin-bottom: 0px;
        height: 30px;
        max-width: 190px;
        &::placeholder {
          color: #fff;
          font-size: 12px;
          text-align: end;
        }

        &:focus-visible {
          outline: none;
          border: 1px solid $active-color;
          // border-color: $active-color;
        }
      }
    }
    .option-slt {
      select {
        background-color: $navy-blue-color;
        padding: 8px;
        font-size: 14px;
        border-radius: 4px;
        color: #fff !important;
        margin-bottom: 0px;
        text-align: end;
        border: none;
        outline: none;
        width: 100%;
      }
    }
  }
}
