.main-right {
  background-color: $off-white-color;
  height: 100%;
  .sec-inner {
    // height: 300px;
    overflow-y: scroll;
    height: 72vh;
    @media (max-width: 1399px) {
      height: 64vh !important;
    }
    .scroll-sec {
      @include accordion-setting;
      margin-top: 5px;

      .comp-1,
      .Bounce-1,
      .handle-color-1,
      .Bounce-2,
      .Bounce-3,
      .top-1,
      .top-inset {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .flex-p-img {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 2px;
          p {
            color: #141a29;
            font-family: "Inter";
            font-size: 12px;
            font-weight: 400;

            @media ($breakpoint_xxl) {
              font-size: 12px;
            }
          }

          img {
            @media ($breakpoint_xxl) {
              width: 16px;
            }

            width: 14px;
          }
        }

        input {
          width: 150px;
          background-color: $navy-blue-color;
          padding: 0px 10px;
          font-size: 14px;
          border-radius: 4px;
          color: $white !important;
          margin-bottom: 0px;
          height: 30px;

          &:focus-visible {
            outline: none;
            border: 1px solid $navy-blue-color;
          }
        }
      }
      .Basic-1,
      .position-1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .p-info-flex {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 2px;
          p {
            color: #141a29;
            font-family: "Inter";
            font-size: 12px;
            font-weight: 400;

            @media ($breakpoint_xxl) {
              font-size: 12px;
            }
          }
          img {
            @media ($breakpoint_xxl) {
              width: 16px;
            }

            width: 14px;
          }
        }
        .drop-time {
          select {
            background-color: $navy-blue-color;
            padding: 0px;
            font-size: 14px;
            border-radius: 4px;
            color: #fff !important;
            margin-bottom: 0px;
            text-align: center;
            border: none;
            padding: 4px;
            outline: none;
          }
        }
      }
      .Basic-2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .p-icon {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 2px;
          flex-basis: 65%;
          p {
            color: #141a29;
            font-family: "Inter";
            font-size: 12px;
            font-weight: 400;

            @media ($breakpoint_xxl) {
              font-size: 12px;
            }
          }
          img {
            @media ($breakpoint_xxl) {
              width: 16px;
            }

            width: 14px;
          }
        }
        .input-basic {
          flex-basis: 70%;
          input {
            width: 100%;
            background-color: $navy-blue-color;
            padding: 0px 10px;
            font-size: 14px;
            border-radius: 4px;
            color: $white !important;
            margin-bottom: 0px;
            height: 30px;
            // border: 1px solid $bg-color !important;

            &:focus-visible {
              outline: none;
              border: 1px solid $active-color;
              // border-color: $active-color;
            }
          }
        }
      }
      .slt-para-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        .paraslt {
          color: $main-color !important;
          font-family: "Inter";
          font-size: 14px !important;
          font-weight: 400;
        }
        // .icon_config {
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        // }
      }
      .basic_placeholder,
      .br-width {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .ph-icon {
          display: flex;
          align-items: center;

          flex-basis: 40%;
          p {
            color: #141a29;
            font-family: "Inter";
            font-size: 12px;
            font-weight: 400;

            @media ($breakpoint_xxl) {
              font-size: 12px;
            }
          }
          img {
            @media ($breakpoint_xxl) {
              width: 16px;
            }

            width: 14px;
          }
        }
        .filed-option,
        .basic_placeholder {
          flex-basis: 60%;
          input {
            width: 100%;
            background-color: $navy-blue-color;
            padding: 0px 10px;
            font-size: 14px;
            border-radius: 4px;
            color: $white !important;
            margin-bottom: 0px;
            height: 30px;
            &::placeholder {
              color: #ffff;
              text-align: end;
            }
            &:focus-visible {
              outline: none;
              border: 1px solid $navy-blue-color;
              // border-color: $active-color;
            }
          }
        }
      }
      .buttons-1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .long-press-img {
          display: flex;
          align-items: center;
          gap: 2px;
          flex-basis: 33%;
          p {
            color: #141a29;
            font-family: "Inter";
            font-size: 12px;
            font-weight: 400;

            @media ($breakpoint_xxl) {
              font-size: 12px;
            }
          }
          img {
            @media ($breakpoint_xxl) {
              width: 16px;
            }

            width: 14px;
          }
        }
        .input-button {
          flex-basis: 60%;
          input {
            width: 100%;
            background-color: $navy-blue-color;
            padding: 0px 10px;
            font-size: 14px;
            border-radius: 4px;
            color: $white !important;
            margin-bottom: 0px;
            height: 30px;
            &::placeholder {
              color: #141a29;

              text-align: end;
            }

            &:focus-visible {
              outline: none;
              border: 1px solid $active-color;
              // border-color: $active-color;
            }
          }
        }
      }
      .Basic-3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .p-auto {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 2px;
          p {
            color: #141a29;
            font-family: "Inter";
            font-size: 12px;
            font-weight: 400;

            @media ($breakpoint_xxl) {
              font-size: 12px;
            }
          }

          img {
            @media ($breakpoint_xxl) {
              width: 16px;
            }

            width: 14px;
          }
        }
      }
      .comp-2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .flex-p-img {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          gap: 2px;
          p {
            color: #141a29;
            font-family: "Inter";
            font-size: 16px;
            font-weight: 400;
          }
        }
      }

      .comp-3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .flex-p-img {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          gap: 2px;
          p {
            color: #141a29;
            font-family: "Inter";
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
      .comp-4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .flex-p-img {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          gap: 2px;
          p {
            color: #141a29;
            font-family: "Inter";
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
      .comp-5 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .flex-p-img {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          gap: 2px;
          p {
            color: #141a29;
            font-family: "Inter";
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
      .comp-6 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .flex-p-img {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          gap: 2px;
          p {
            color: #141a29;
            font-family: "Inter";
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
      .comp-7 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .flex-p-img {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          gap: 2px;
          p {
            color: #141a29;
            font-family: "Inter";
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
      .comp-8 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .flex-p-img {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          gap: 2px;
          p {
            color: #141a29;
            font-family: "Inter";
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
      .comp-9 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .flex-p-img {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          gap: 2px;
          p {
            color: #141a29;
            font-family: "Inter";
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
      .comp-10 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .flex-p-img {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          gap: 2px;
          p {
            color: #141a29;
            font-family: "Inter";
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
    .tlg-btn-tab-2 {
      margin-top: 24px;
      display: flex;
      justify-content: center;
      align-items: baseline;
      gap: 14px;

      p {
        color: $navy-blue-color;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 400;
        line-height: 14px; /* 87.5% */
        margin-bottom: 20px;
      }
    }
  }

  .css-1dldku-control {
    @media ($breakpoint_xxl) {
      min-height: 30px;
      width: 130px;
      height: 34px;
      font-size: 13px;
    }
    @media ($breckpoint_XXL) {
      min-height: 30px;
      width: 100px;
      height: 34px;
      font-size: 13px;
    }
  }
}
