.main-dash-header {
  background-color: $cloud-color;
  padding: 10px 0;
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-sec {
      img {
        width: 70%;
      }
    }

    .right-sec {
      display: flex;
      align-items: center;
      gap: 15px;
      .logout-btn {
        text-decoration: none;
        background-color: $white-color;
        color: $cloud-color;
        padding: 8px 14px;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 700;
        border: 1px solid $white-color;

        &:hover {
          background-color: $cloud-color;
          border: 1px solid $white-color;
          color: $white-color;
          transition: 0.2s;
        }
      }

      .account_setting {
        img {
          width: 45px;
          height: 45px;
          border-radius: 30px;
        }
      }
    }
  }
}
