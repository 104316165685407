.main-right {
  background-color: $off-white-color;
  // height: 100%;
  .third-scroll {
    // height: 300px;
    // overflow-y: scroll;
    height: 72vh;
    overflow-y: scroll;

    @media (max-width: 1399px) {
      height: 64vh !important;
    }

    .scrollable-inner {
      @include accordion-setting;
      margin-top: 5px;

      .input-bar {
        .input-area {
          height: 135px;
          background-color: $off-white-color;
          width: 100%;
          color: $main-color !important;
          resize: none;
          padding: 5px;
          border: 2px $sky-cloud-color solid;
          border-radius: 4px;
          outline: none;
          font-weight: 500;
        }
        p {
          color: $main-color;
          font-family: "Inter";
          font-size: 12px;
          font-weight: 400;
          line-height: 20px; /* 166.667% */
        }
        .paaofadat {
          color: $main-color !important;
          font-family: "Inter";
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
        }
      }
      .data-bar {
        .main-content {
          .flex-select {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 12px;
            margin-bottom: 10px;
            h6 {
              color: $main-color;
              font-family: "Inter";
              font-size: 16px;
              font-weight: 400;
              margin-bottom: 0;
            }
            .select_con {
              select {
                border-radius: 5px;
                background: #1a237e !important;
                padding: 8px 10px;
                border: none;
                color: white;
              }
            }
          }
          p {
            color: $main-color;
            font-family: "Inter";
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
          }
        }
      }
      .setup-bar {
        .main-flex {
          .first-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
            .type-flex {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;
              p {
                color: #141a29 !important;
                font-family: "Inter";
                font-size: 16px;
                font-weight: 400;
              }
            }
            .slt-1 {
              select {
                border-radius: 5px;
                background: #1a237e !important;
                padding: 8px 10px;
                border: none;
                color: white;
              }
            }
          }
          .sec-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 13px;
            p {
              color: $main-color;
              font-family: "Inter";
              font-size: 16px;
              font-weight: 400;
            }
            .slt-2 {
              select {
                border-radius: 5px;
                background: #1a237e !important;
                padding: 8px 10px;
                border: none;
                color: white;
              }
            }
            .img_source {
              border-radius: 5px;
              background: #1a237e !important;
              padding: 3px 10px;
              border: none;
              color: white;
              width: 150px;
              height: 30px;
              cursor: pointer;
            }
          }
          .top-data {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
            .img-h5-flex {
              display: flex;
              justify-content: center;
              align-items: baseline;
              gap: 8px;

              p {
                color: #fff;
                font-family: "Inter";
                font-size: 16px;
                font-weight: 400;
              }
            }
          }
          .mid-data {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;

            p {
              color: $main-color;
              font-family: "Inter";
              font-size: 16px;
              font-weight: 400;
              margin-bottom: 0;
            }
          }
          .lower-data {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
            .info-h5-flex {
              display: flex;
              justify-content: center;
              align-items: flex-end;
              gap: 2px;
              p {
                color: $main-color;
                font-family: "Inter";
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 0;
              }
            }
          }
          .Service-bar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
            .icon-h5-flex {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;
              p {
                color: #fff;
                font-family: "Inter";
                font-size: 16px;
                font-weight: 400;
              }
            }
          }
          .endpoint-bar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
            .icon-h5-flex {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;
              p {
                color: #fff;
                font-family: "Inter";
                font-size: 16px;
                font-weight: 400;
              }
            }
          }
        }
      }
      .display-bar {
        .slt1 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          gap: 12px;
          p {
            color: $main-color;
            font-family: "Inter";
            font-size: 16px;
            font-weight: 400;
          }
        }
        .slt2 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 12px;
          p {
            color: $main-color;
            font-family: "Inter";
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
      .Configuration-bar {
        h6 {
          color: $main-color !important;

          font-family: "Inter";
          font-size: 16px;
          font-weight: 400;
          line-height: 14px; /* 87.5% */
          margin-bottom: 22px;
        }
        .slt-para-flex {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;
          .paraslt {
            color: $main-color !important;
            font-family: "Inter";
            font-size: 19px !important;
            font-weight: 400;
          }
          // .icon_config {
          //   display: flex;
          //   justify-content: center;
          //   align-items: center;
          // }
        }
      }
      .variable_bar {
        .main_variables {
          .inner_var {
            display: flex !important;
            color: $white;
            gap: 5px;
            .var_title {
              color: $main-color;
            }

            .var_selector {
            }
          }
        }
      }
      .preview-bar {
        h6 {
          color: #fff;
          font-family: "Inter";
          font-size: 16px;
          font-weight: 400;
          line-height: 14px; /* 87.5% */
          margin-bottom: 22px;

          img.reload-mrg {
            margin-left: 60%;
          }
        }
      }
      .tlg-btn-tab-3 {
        margin-top: 24px;
        display: flex;
        justify-content: center;
        align-items: baseline;
        gap: 14px;

        p {
          color: $navy-blue-color;

          font-family: "Inter";
          font-size: 16px;
          font-weight: 400;
          line-height: 14px; /* 87.5% */
          margin-bottom: 20px;
        }
      }

      // .space-10 {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   gap: 4px;
      //   .text-small-slt,
      //   .snap_img {
      //     display: flex;
      //     /* justify-content: center; */
      //     align-items: center;
      //     gap: 5px;
      //     flex-basis: 65%;

      //     p {
      //       font-size: 14px;
      //       line-height: 22px;
      //       font-weight: 400;
      //       color: #141a29;
      //     }
      //   }
      //   .input-leftname {
      //     flex-basis: 55%;
      //     input {
      //       width: 100%;
      //       background-color: $navy-blue-color;
      //       padding: 0px 10px;
      //       font-size: 14px;
      //       border-radius: 4px;
      //       color: $white !important;
      //       margin-bottom: 0px;
      //       height: 30px;
      //       // border: 1px solid $bg-color !important;

      //       &:focus-visible {
      //         outline: none;
      //         border: 1px solid $active-color;
      //         // border-color: $active-color;
      //       }
      //     }
      //   }
      //   .svg_space {
      //     display: flex;
      //     justify-content: flex-end;
      //     align-items: center;
      //     gap: 10px;
      //   }
      // }
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
