.main_button {
  width: 100%;
  height: 30px;
  button {
    position: relative;
    z-index: 9999;

    &:hover {
      background: rgba(0, 0, 0, 0.507) !important;
    }
  }
}
