.datepicker_wrapper {
  border-width: 1px;
  padding-top: 24px;
  padding-bottom: 8px;
  opacity: 1;
  background-color: rgb(251, 252, 253);
  border: black solid 1px;
  cursor: pointer;
  border-radius: 12px;
  // width: 100%;
  .datepicker_label {
    color: black;
  }
  .datepicker_datetime {
    color: black;
  }
}
