.f_section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 80px;
    padding-top: 30px;

    @media ($breckpoint_MD)
    {
        flex-wrap: wrap;
    }
    @media  ($breakpoint_MS) 
      {
          gap: 40px;
    
      }
    .icon_box{
        width: 100%;
        text-align: center;
        img{
            max-width: 100%;
        }
    }

    .f_box{
        width: 100%;
        flex-basis: 98%;
        
        span{
            color: #223a83;
  font-family: "Montserrat";
  font-weight: 400;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
        }
        h2 {
            color: #000;
            font-family: "Montserrat";
            font-size: 48px; 
            text-align: left;
            font-weight: 700 !important;
            // line-height: 60px;
            letter-spacing: -1.44px;
            text-transform: capitalize;
          
          }
          p {
            color: #2e484b;
            font-family: "Montserrat";
            font-size: 14px;
            font-weight: 400;
          
            text-transform: capitalize;
            width: 100%;
          }
    }
}