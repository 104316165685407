.folder {
  margin: 6px 0;
  padding-left: 0.5rem;
  position: relative;

  &:first-child {
    padding-left: 0;
  }
}

.folder-name {
  cursor: move;
  display: flex;
  align-items: center;
  height: 24px;
  padding-left: 3px;
  svg {
    stroke: #3c4c6e;
    fill: #3c4c6e;
  }
  &:hover {
    background-color: rgba(131, 138, 211, 0.1882352941);
    color: $navy-blue-color !important;
    svg {
      stroke: #3c4c6e;
      fill: #3c4c6e;
    }
  }
  .eye_viewdots {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-right: 10px;
    width: 50px;
    .view_dots {
      position: relative;
    }
  }
}

.icon {
  margin-right: 5px;
  display: flex;
  align-items: center;
  color: $grey-dark-color;
  svg {
    width: 14px;
    height: 14px;
  }
  .common_icon {
    color: $grey-dark-color;
  }
}

.active_links {
  background-color: $navy-blue-color;
  color: #fff !important;

  svg {
    path {
      stroke: #fff;
      fill: #fff;
    }
  }

  &:hover {
    svg {
      path {
        stroke: #3c4c6e;
        fill: #3c4c6e;
      }
    }
    .icon::before {
      color: #3c4c6e !important;
    }
  }
  .icon::before {
    color: #fff !important;
  }
}

.folder_container {
  display: flex;
  align-items: center;
  .hover-icon {
    position: relative;
    left: 226px;
  }
}

.dotted-border {
  border-left: 1px dotted $grey-dark-color;
  padding-left: 10px;
  transition: 0.3s;
  .flex_folder {
    display: flex;
    width: 100%;
    .static_dots {
    }
  }
}

.expanded {
  transition: 0.3s;
}

.collapsed {
  transition: 0.3s;
}

.dotted-border {
  // overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  border-left: 1px dotted $grey-dark-color;
  padding-left: 10px;
}

.expanded .dotted-border {
  margin-left: 4px;
}

.folder {
  // overflow: hidden;
  transition: max-height 1.9s ease-in-out;
  width: 100%;
}

.folder.expanded {
  // max-height: 1000px;
  transition: 0.3s;
  width: 100%;
  position: relative;
}

.folder.folder-type-view.no-children .icon::before {
  content: "";
  display: none;
}
.folder.folder-type-tabviewitem.no-children .icon::before {
  content: "";
  display: none;
}

.folder.folder-type-view.has-children.expanded .icon::before {
  content: "▼";
  font-size: 10px;
  position: relative;
  top: -1px;
  margin-right: 5px;
}

.folder.folder-type-view.has-children.collapsed .icon::before {
  content: "▶";
  font-size: 10px;
  position: relative;
  margin-right: 5px;
  top: -1px;
}

.folder.folder-type-bottomSheets.has-children.expanded .icon::before {
  content: "▼";
  font-size: 10px;
  position: relative;
  top: -1px;
  margin-right: 5px;
}

.folder.folder-type-bottomSheets.has-children.collapsed .icon::before {
  content: "▶";
  font-size: 10px;
  position: relative;
  margin-right: 5px;
  top: -1px;
}

.folder.folder-type-tabview.has-children.expanded .icon::before {
  content: "▼";
  font-size: 10px;
  position: relative;
  top: -1px;
  margin-right: 5px;
}

.folder.folder-type-tabview.has-children.collapsed .icon::before {
  content: "▶";
  font-size: 10px;
  position: relative;
  margin-right: 5px;
  top: -1px;
}
.folder.folder-type-tabviewitem.has-children.expanded .icon::before {
  content: "▼";
  font-size: 10px;
  position: relative;
  top: -1px;
  margin-right: 5px;
}

.folder.folder-type-tabviewitem.has-children.collapsed .icon::before {
  content: "▶";
  font-size: 10px;
  position: relative;
  margin-right: 5px;
  top: -1px;
}

.folder.folder-type-pressable.no-children .icon::before {
  content: "";
  display: none;
}

.folder.folder-type-pressable.has-children.expanded .icon::before {
  content: "▼";
  font-size: 10px;
  position: relative;
  top: -1px;
  margin-right: 5px;
}

.folder.folder-type-pressable.has-children.collapsed .icon::before {
  content: "▶";
  font-size: 10px;
  position: relative;
  margin-right: 5px;
  top: -1px;
}
.folder.folder-type-fetch.no-children .icon::before {
  content: "";
  display: none;
}

.folder.folder-type-fetch.has-children.expanded .icon::before {
  content: "▼";
  font-size: 10px;
  position: relative;
  top: -1px;
  margin-right: 5px;
}

.folder.folder-type-fetch.has-children.collapsed .icon::before {
  content: "▶";
  font-size: 10px;
  position: relative;
  margin-right: 5px;
  top: -1px;
}
.folder.folder-type-flashlist.no-children .icon::before {
  content: "";
  display: none;
}

.folder.folder-type-flashlist.has-children.expanded .icon::before {
  content: "▼";
  font-size: 10px;
  position: relative;
  top: -1px;
  margin-right: 5px;
}

.folder.folder-type-flashlist.has-children.collapsed .icon::before {
  content: "▶";
  font-size: 10px;
  position: relative;
  margin-right: 5px;
  top: -1px;
}

// /* Hide icon when folder has no children */
// .folder.folder-type-view.has-children .icon::before {
//   display: none;
// }

/* Show expanded icon when folder has children and is expanded */
.folder.folder-type-fetch.has-children.expanded,
.folder.folder-type-flashlist.has-children.expanded
  .folder.folder-type-bottomSheets.has-children.expanded
  .folder.folder-type-view.has-children.expanded
  .icon::before {
  content: "▼"; /* Unicode for down arrow */
  font-size: 10px;
  position: relative;
  top: -1px;
  margin-right: 5px;
}

/* Show collapsed icon when folder has children and is collapsed */
.folder.folder-type-fetch.has-children.expanded,
.folder.folder-type-flashlist.has-children.collapsed,
.folder.folder-type-view.has-children.collapsed .icon::before,
.folder.folder-type-bottomSheets.has-children.collapsed .icon::before {
  content: "▶"; /* Unicode for right arrow */
  font-size: 10px;
  position: relative;
  margin-right: 5px;
  top: -1px;
}

.folder.folder-type-image .icon::before,
.folder.folder-type-text .icon::before,
.folder.folder-type-datepicker .icon::before,
.folder.folder-type-button .icon::before,
.folder.folder-type-textField .icon::before,
.folder.folder-type-dropdown .icon::before {
  content: "";
  display: none;
}

.clip_svg {
  background-color: $white-color;
  padding: 4px;
}

// ======================================
