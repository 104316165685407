// TestimonialSlider.scss

$slider-background: #f4f4f4;
$testimonial-background: #fff;
$testimonial-border-radius: 10px;
$testimonial-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
$testimonial-margin-top: 20px;

.slider-container {
  overflow: hidden;
  width: 80%;
  max-width: 600px;
  margin: 0 auto; // Center the slider
  position: relative;
  z-index: 1;

  @media ($breckpoint_MD) {
    width: 100%;
  }

  @media ($breckpoint_SM) {
    max-width: 350px;
  }
  @media ($breckpoint_SM) {
    max-width: 330px;
  }
}

.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.testimonial {
  flex: 0 0 100%;
  text-align: center;
  padding: 20px;
  background-color: $testimonial-background;
  border-radius: $testimonial-border-radius;
  box-shadow: $testimonial-box-shadow;
  margin-top: $testimonial-margin-top;

  p {
    margin-bottom: 20px;
    text-align: left;
    font-size: 12px !important;
    line-height: 1.6em !important; // Remove default margin for paragraphs
  }

  .testi-author {
    .inner {
      display: flex;
      justify-content: space-between;
      .left-t {
        display: flex;
      }

      .right-t {
      }
    }
  }

  .author {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
  }

  .prof {
    font-size: 12px;
    position: relative;
    top: -4px;
  }

  .testimonial-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  .rating {
    margin-top: 10px;

    .star {
      color: #f6b900; // Star color (yellow)
      font-size: 20px;
      margin-right: 5px;
    }
  }
}

.btns {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  padding-bottom: 1px;

  .prev-button,
  .next-button {
    background-color: #2f5ad8;
    color: #fff;
    border: 1px solid #2f5ad8;
    padding: 5px 5px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 50px;
    margin-top: 10px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }
  }

  .prev-button {
    transform: rotate(180deg);
    background-color: transparent !important;
    color: #2f5ad8;
  }
}
