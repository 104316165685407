.main_con {
  padding: 80px 0;
  background-color: #f3f6ff;
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.form_container {
  border-radius: 20px;
  background: #fff;
  width: 699px;
  padding: 40px;
  margin: auto;
}
.top_h1 h2 {
  color: #000;
  font-size: 36px;
  font-family: "Montserrat";
  text-align: center;
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 20px;
}
.label_em {
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.label_pass {
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

/* .formBtn {
  margin-top: 5px;
} */

/* .input_field input {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px; 
  border-radius: 8px;
  background: #f8f8ff;
  margin-top: 4px;
  padding: 14px 18px;
  width: 100%;
  font-family: "Montserrat";
  font-size: 15px;
  border: 1.5px solid #c5c5ff;
  outline: none;
  font-weight: 500;
} */
.input_field {
  margin-top: 12px;
  margin-bottom: 25px;
}
input {
  border: none;
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}
.input_check {
  margin-top: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row-reverse;
  gap: 8px;
  margin-bottom: 10px;
}
label.label_remember {
  font-weight: 600;
  color: #283249;
  font-size: 16px;
}
.check_box {
  width: 15px;
  height: 15px;
  position: relative;
  top: -2px;
  margin-bottom: 0;
  margin-top: 2px;
}

/* .formBtn button {
  color: #fff;
  font-family: "Montserrat";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  text-transform: capitalize;
} */

h3.h3_pass {
  color: black;
  font-weight: 600;
  font-family: "Montserrat";
  font-size: 16px;
  line-height: 22px; /* 137.5% */
  text-transform: capitalize;
  margin-bottom: 0px;
}
