.api_cloud_assets_modal {
  max-width: 1400px;
  width: 100%;
  padding: 0px;
  max-height: 90vh;

  @media (max-width: 1500px) {
    max-width: 1200px;
    height: 85vh;
  }

  @media (max-width: 1350px) {
    max-width: 975px;
    height: 85vh;
  }

  .top_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    padding-left: 12px;
    background-color: $navy-blue-color;
    color: $white;
    border-bottom: 1px solid #ffff;
    h3 {
      h3 {
        font-family: "Inter";
        font-size: 20px !important;
        font-weight: 600 !important;
        line-height: 22px !important;

        text-align: left !important;
      }
    }

    span {
      background-color: $white-color;
      color: #1a237e;

      padding: 10px 24px;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      /* text-align: center; */
      /* padding-left: 10px; */
      margin-right: 54px;
      margin-bottom: 10px;
      border-radius: 4px;
      gap: 10px;
      display: flex;
    }
    .question_icon {
      margin-right: 55px;
      margin-bottom: 12px;
    }
  }

  .body_sec {
    display: flex;
    color: $white;
    .left_bar {
      flex-basis: 26%;
      background-color: $navy-blue-color;
      align-items: baseline;
      padding: 15px;

      @media (max-width: "1500px") {
        padding: 5px 15px 30px 15px;
      }
      p {
        font-family: "Inter";
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left;

        text-align: left;
      }
      h5 {
        font-family: "Inter";
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left;

        position: relative;
        top: 1rem;
      }

      h6 {
        font-family: "Inter";
        font-size: 16px !important;
        font-weight: 600 !important;
        line-height: 24px !important;
        text-align: left;

        margin-top: 8px;
      }

      .space_btm {
        padding-bottom: 2rem;
      }
      .add_custom_api {
        padding-top: 20px;
        width: 100%;
        height: 42px;
        cursor: pointer;

        .api_btn {
          display: flex;
          width: 100%;
          background-color: $white-color;
          color: #141a29;
          height: 42px;
          align-items: center;
          border-radius: 6px;
          padding-left: 5px;

          font-family: "Inter";
          font-size: 16px;
          font-weight: 600;
          line-height: 14px;
          text-align: left;

          .title {
            p {
              font-size: 14px;
              padding-left: 10px;
              font-weight: 600;
            }
          }

          &:hover {
            background-color: #ffffff45;
          }
        }

        .hvr_bg {
          width: fit-content;
          display: flex;
          justify-content: center;
          position: relative;
          top: -32px;
          display: none;
          margin: 0 auto;
          right: -0.5rem;

          .circle {
            background-color: #fff;
            border-radius: 30px;
            width: 24px;
            display: flex;
            justify-content: center;
            height: 24px;
            align-items: center;

            svg {
              color: #0ac9c9;
              font-size: 18px;
            }
          }
        }

        &:hover {
          .hvr_bg {
            display: block;
          }
        }
      }

      .connect_service {
        margin-top: 30px;

        @media (max-width: "1500px") {
          margin-top: 12px;
        }
        h5 {
          font-size: 14px;

          position: relative;
          top: 1rem;
        }

        .inner {
          display: flex;
          gap: 10px;
          margin-bottom: 6px;

          .add_custom_api {
            padding-top: 20px;
            width: 100%;
            height: 42px;
            cursor: pointer;

            .api_btn {
              display: flex;
              width: 100%;
              background-color: $white-color;
              height: 42px;
              align-items: center;
              border-radius: 6px;
              padding-left: 5px;
              color: #141a29;

              .title {
                p {
                  font-size: 14px;
                  padding-left: 10px;
                  font-weight: 600;
                }
              }

              &:hover {
                background-color: #ffffff45;
              }
            }

            .hvr_bg {
              width: fit-content;
              display: flex;
              justify-content: center;
              position: relative;
              top: -32px;
              display: none;
              margin: 0 auto;
              right: -0.5rem;

              .circle {
                background-color: #fff;
                border-radius: 30px;
                width: 24px;
                display: flex;
                justify-content: center;
                height: 24px;
                align-items: center;

                svg {
                  color: #0ac9c9;
                  font-size: 18px;
                }
              }
            }

            &:hover {
              .hvr_bg {
                display: block;
              }
            }
          }
        }
      }

      .import_sec {
        margin-top: 15px;
        .add_custom_api_2 {
          padding-top: 20px;
          width: 100%;
          height: 42px;
          cursor: pointer;

          .api_btn {
            display: flex;
            width: 100%;
            background-color: $white-color;
            color: #141a29;
            height: 42px;
            align-items: center;
            border-radius: 6px;
            padding-left: 10px;

            .title {
              p {
                font-size: 14px;
                padding-left: 10px;
                font-weight: 600;
              }
            }

            &:hover {
              background-color: #ffffff45;
            }
          }

          .hvr_bg {
            width: fit-content;
            display: flex;
            justify-content: center;
            position: relative;
            top: -32px;
            display: none;
            margin: 0 auto;
            right: -0.5rem;

            .circle {
              background-color: #fff;
              border-radius: 30px;
              width: 24px;
              display: flex;
              justify-content: center;
              height: 24px;
              align-items: center;

              svg {
                color: #0ac9c9;
                font-size: 18px;
              }
            }
          }

          &:hover {
            .hvr_bg {
              display: block;
            }
          }
        }
      }

      .connect_api {
        .guide_btn {
          background-color: $green-color;
          color: $white-color;
          padding: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          gap: 6px;
          font-size: 12px;
          font-weight: 600;
          position: relative;
          top: 15px;
          transition: 0.3s;

          svg {
            font-size: 14px;
          }

          &:hover {
            background-color: #ffffff36;
          }
        }
      }

      .resources {
        margin-top: 40px;
        .guide_btn {
          background-color: $white-color;
          color: #141a29;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          gap: 6px;
          font-size: 12px;
          font-weight: 700;
          position: relative;
          top: 15px;
          transition: 0.3s;
          margin-bottom: 8px;

          svg {
            font-size: 14px;
          }

          &:hover {
            background-color: #ffffff36;
          }
        }
      }

      .guide_btn {
        background-color: $white-color;
        color: #141a29;
        padding: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        gap: 6px;
        font-size: 12px;
        font-weight: 600;
        position: relative;
        top: 15px;
        transition: 0.3s;

        svg {
          font-size: 14px;
        }

        &:hover {
          background-color: #ffffff36;
        }
      }
    }

    .main_bar {
      flex-basis: 85%;
      padding: 15px;
      background-color: $off-white-color;
      color: #141a29;
      height: 738px;
      overflow-y: scroll;

      @media (max-width: "1500px") {
        height: 680px;
      }

      .api_services {
        h5 {
          font-family: "Inter";
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          position: relative;
          margin-bottom: 8px;
          margin-top: 8px;
        }

        .inner_blocks {
          display: flex !important;
          flex-wrap: wrap !important;
          gap: 7px;

          .api_blocks {
            display: flex;
            align-items: center;
            background-color: $white-color;
            width: 465px;
            color: #141a29;
            height: 58px;
            border-radius: 6px;
            padding-left: 15px;
            cursor: pointer;
            justify-content: space-between;

            @media ($breakpoint_xxxl) {
              width: 474px;
            }
            @media ($breakpoint_xxl) {
              width: 460px;
            }

            @media (max-width: "1500px") {
              width: 445px;
            }

            .left_data {
              display: flex;
              flex-basis: 90%;
              align-items: center;
              gap: 15px;
              .logo_Sec {
                flex-basis: 8%;
              }

              .txt_sec {
                flex-basis: 82%;
                .top_sec {
                  display: flex;
                  gap: 15px;
                  align-items: center;
                  span {
                    font-size: 14px;
                    font-weight: 600;
                    color: #141a29;
                  }
                  .bg_change {
                    background-color: $secondary-color;
                    padding: 1px 10px;
                    border-radius: 4px;
                    font-size: 12px !important;
                    font-weight: 400 !important;
                  }
                }
                .btm_sec {
                  p {
                    word-break: break-all;
                  }
                }
              }
            }

            .right_data {
              flex-basis: 10%;
              .popup_sec {
                flex-basis: 10%;
                text-align: center;

                .options {
                  position: absolute;
                  padding: 6px 0px;
                  background: #fff;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  text-align: left;
                  border: 1px solid rgba(26, 35, 126, 0.2588235294);
                  border-radius: 6px;
                  width: 165px;
                  button {
                    border: none;
                    background-color: $off-white-color;
                    color: #141a29;
                    font-size: 14px;
                    text-align: left;
                    display: flex;
                    gap: 9px;
                    align-items: center;
                    height: 4px;
                    padding: 14px 9px;
                    border-radius: 2px;
                    margin: 6px;
                    svg {
                      font-size: 14px;
                    }
                    &:hover {
                      background-color: #ff6f61;
                      color: $white-color;
                    }
                  }

                  &::before {
                    content: "";
                    background-color: $white-color;
                    border: 1px solid #1a237e42;
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    transform: rotate(45deg);
                    top: -8px;
                    left: 15px;
                    border-right: 0;
                    border-bottom: 0;
                    @media (max-width: 1500px) {
                      top: -9px;
                      left: 16px;
                    }
                  }
                }

                .delete-confirmation {
                  position: absolute;
                  padding: 14px 15px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  width: 288px;
                  margin-left: -14rem;
                  background: $off-white-color;
                  border-radius: 6px;
                  border: 1px solid rgba(26, 35, 126, 0.2588235294);
                  padding-top: 16px;
                  .svg_trashg {
                    padding: 14px 14px;
                    background-color: rgba(255, 111, 97, 0.1411764706);
                    display: flex;
                    border-radius: 6px;
                  }
                  .btns_sec_delete {
                    display: flex;
                    gap: 12px;
                    margin-top: 7px;
                    margin-left: 12px;
                    button {
                      outline: none;
                      background-color: $off-white-color;
                      color: #283249;
                      font-size: 14px;
                      text-align: left;
                      display: flex;
                      gap: 5px;
                      align-items: center;
                      height: 25px;
                      padding: 4px 12px;
                      border: 1px solid #28324947;
                      padding: 15px 18px;
                      svg {
                        font-size: 14px;
                      }

                      &:first-child {
                        background-color: #ff6f61;
                        border: none;
                        outline: none;
                        color: $white-color;
                        padding: 16px 18px;
                      }
                    }
                  }
                  h5 {
                    font-family: "Inter";
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    text-align: center;
                  }
                  p {
                    font-family: "Inter";
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: center;
                  }
                  &::before {
                    content: "";
                    background-color: $off-white-color;
                    border: 1px solid #1a237e42;
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    transform: rotate(45deg);
                    top: -8px;
                    left: 241px;
                    border-right: 0;
                    border-bottom: 0;
                  }
                }
              }
            }

            // &:hover {
            //   background-color: #ffffff45;
            // }
          }
        }
      }

      .image-uploader {
        display: flex;
        gap: 10px;
        margin-top: 10px;
        .upload-container {
          min-width: 225px;
          height: 210px;
          display: flex;
          align-items: center;
          flex-direction: column;
          background-color: $bg-color;
          justify-content: center;
          text-align: center;
          cursor: pointer;
          gap: 12px;

          svg {
            transform: rotate(180deg);
            font-size: 40px;
            color: $active-color;
          }

          .clr {
            color: $white;
            font-weight: 600;
          }

          //   &:hover {
          //     background-color: $main-color;
          //   }
        }

        .image-list {
          .all_images {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            margin-bottom: 0px;
            li {
              background-image: linear-gradient(
                  45deg,
                  #3d4b6c 25%,
                  transparent 0
                ),
                linear-gradient(-45deg, #3d4b6c 25%, transparent 0),
                linear-gradient(45deg, transparent 75%, #3d4b6c 0),
                linear-gradient(-45deg, transparent 75%, #3d4b6c 0);
              background-color: #34425f;
              background-position: 0 0, 0 5px, 5px -5px, -5px 0;
              background-size: 10px 10px;
              margin-bottom: 5px;
            }

            .image-box {
              cursor: pointer;
              width: 234px;
              height: 211px;
              img {
                padding: 20px;
                object-fit: contain;
              }

              .image_name {
                background-color: $bg-color;
                padding: 8px;
                width: 100%;
                position: relative;
                bottom: -26px;
              }

              .delete-icon {
                display: flex;
                justify-content: flex-end;
                position: relative;
                top: 1rem;
                width: fit-content;
                left: 11.8rem;
                background: white;
                border-radius: 50px;
                color: $bg-color;
                cursor: pointer;

                &:hover {
                  background: rgb(184, 167, 167);
                }
              }

              &:hover {
                .image_name {
                  background-color: #717e9f;
                }
              }
            }
          }
        }
      }

      .step_1 {
        @include accordion-setting;
        .Accordion.Header {
          background-color: $cloud-color !important;
          border: none;
          .accordion-body {
            color: $white-color;
            .top_sec {
              h5 {
                margin-top: 10px;
                font-family: "Inter";
                font-size: 16px !important;
                font-weight: 600 !important;
                line-height: 24px !important;
                text-align: left;
                color: #141a29;
              }
              p {
                font-size: 14px !important;
                line-height: 20px !important;
                font-weight: 600 !important;
                color: #141a29;
                text-align: left;
              }
            }
            .input_sec {
              margin-top: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 10px;
              .inner {
                .label {
                  font-size: 14px;
                  font-weight: 600;
                  color: #141a29;
                }
                .input_main {
                  flex-basis: 75%;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                  input,
                  select {
                    margin-bottom: 0px;
                    width: 100%;
                    background-color: $off-white-color;
                    border: 1px solid #b8b8b8;
                    border-radius: 4px;
                    color: #141a29 !important;
                    padding: 4px 10px;
                    font-size: 13px;

                    &::placeholder {
                      color: #141a29;
                    }

                    &:focus {
                      outline: none;
                      border: 1px solid $active-color;
                    }
                  }

                  span {
                    font-size: 12px;
                    margin-top: 5px;
                    color: #141a29;
                  }
                }
              }
            }
            .border_bottom {
              border-bottom: 1px solid #696969;
            }

            .top_new_sec {
              .inner_sec5 {
                display: flex;
                gap: 15px;
                input,
                select {
                  margin-bottom: 0px;
                  width: 100%;
                  background-color: $navy-blue-color;
                  border-radius: 4px;
                  color: $white-color !important;
                  padding: 4px 10px;
                  font-size: 13px;

                  &::placeholder {
                    color: #141a29;
                  }

                  &:focus {
                    outline: none;
                    border: 1px solid $active-color;
                  }
                }

                .skip {
                  background-color: #ff6f61;
                  color: $white;
                  border-radius: 3px;
                  border: none;
                  padding: 3px 8px;
                }

                .test {
                  background-color: $cloud-color;
                  color: $white;
                  border-radius: 3px;
                  border: none;
                  padding: 3px 8px;
                }
              }
            }
          }

          .dp_block {
            .inner {
              flex-direction: column;

              span {
                font-size: 12px;
                line-height: 18px;
                margin-bottom: 15px;
                color: #141a29;
              }
            }
          }
        }

        .title_sec {
          background-color: $sky-cloud-color;
          font-size: 14px;
          font-weight: 600;
          padding: 6px 4px;
          margin-bottom: 15px;
          color: $white-color;
        }

        .mid_sec {
          padding: 0px 15px 20px 15px;
          p {
            font-family: "Inter";
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            text-align: left;
            color: #141a29;
          }
        }

        .body_sec_2 {
          .input_sec {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 5px 15px;
            margin-top: 0px;
            // flex-direction: column;
            .title {
              flex-basis: 30%;

              svg {
                font-size: 14px;
                margin-left: 3px;
              }
              p {
                display: flex;
                align-items: center;
                font-family: "Inter";
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                text-align: left;
              }
            }

            .input_field_new {
              flex-basis: 70%;

              input {
                margin-bottom: 0px;
                width: 100%;
                background-color: $off-white-color;
                border: 1px solid #b8b8b8;
                border-radius: 4px;
                color: #141a29 !important;
                padding: 4px 10px;
                font-family: "Inter";
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                &::placeholder {
                  color: #141a29;
                }

                &:focus {
                  outline: none;
                  border: 1px solid $active-color;
                }
              }

              select {
                margin-bottom: 0px;
                width: 100%;
                background-color: $secondary-color;
                border: 1px solid $border-color-select;
                border-radius: 4px;
                color: #141a29 !important;
                padding: 4px 10px;
                font-size: 13px;

                &::placeholder {
                  color: $white-color;
                }

                &:focus {
                  outline: none;
                  border: 1px solid $active-color;
                }
              }
            }
          }
        }
      }

      .step_2 {
        margin-top: 15px;

        .body_sec_2 {
          .add_row {
            display: flex;
            justify-content: space-between;
            padding: 0px 15px;
            button {
              background-color: $green-color;
              border: none;
              padding: 5px 15px;
              color: $white-color;
              border-radius: 4px;
              font-size: 14px;
            }

            .val {
              margin-left: 5.4rem;
            }
          }
          .key_value_sec {
            display: block;
            position: relative;
            top: 4px;
            align-items: flex-end;
            padding-right: 15px;
            width: 100%;

            .input_sec {
              display: inline-grid;

              .title {
                margin-bottom: 15px;
              }
            }

            label {
              margin-bottom: 5px;
            }

            input {
              margin-bottom: 0px;
              width: 100%;
              background-color: $off-white-color;
              border: 1px solid #b8b8b8;
              border-radius: 4px;
              color: #141a29 !important;
              padding: 4px 10px;
              font-size: 13px;

              &::placeholder {
                color: #141a29;
              }

              &:focus {
                outline: none;
                border: 1px solid $active-color;
              }
            }

            .inner {
              display: flex;

              .remove_btn {
                position: relative;
                top: 10px;

                svg {
                  fill: #ff6f61;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

    .steps_sec {
      padding: 4px 0px 0px 15px !important;

      .enpoint_top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .top_btn_sec {
          display: flex;
          align-items: center;
          gap: 10rem;
          padding-right: 15px;
          .end_left {
            h5 {
              font-family: "Inter";
              font-size: 24px;
              font-weight: 600;
              line-height: 24px;
              text-align: left;
              margin-top: 24px;
            }
            p {
              font-family: "Inter";
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
            }
          }

          .end_right {
            button {
              background-color: $green-color;
              border: none;
              border-radius: 4px;
              color: #fff;
              font-size: 14px;
              padding: 4px 15px;
              display: flex;
              align-items: center;
              gap: 6px;
              font-weight: 600;

              @media (max-width: "1500px") {
                width: 180px;
                font-size: 12px;
              }

              svg {
                font-size: 16px;
              }

              &:hover {
                background-color: #717e9f;
              }
            }
          }
        }
      }
      .main_data_sec {
        .left_sec {
          .categories {
            margin-top: 30px;

            .title {
              font-size: 14px;
              font-weight: 600;
            }
            .inner_item {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
              .endpoint_data {
                display: flex;
                width: 350px;
                height: 62px;
                justify-content: space-between;
                align-items: center;
                background-color: $white-color;
                padding: 10px;
                border-radius: 4px;
                cursor: pointer;

                .cate_name {
                  flex-basis: 90%;
                  h5 {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 0px;
                    .method_name {
                      font-size: 12px;
                      background-color: $green-color;
                      padding: 4px 8px;
                      border-radius: 3px;
                      margin-left: 5px;
                      color: $white-color;
                    }
                  }

                  span {
                    font-size: 12px;
                  }
                }

                .actions {
                  flex-basis: 10%;
                  .popup_sec {
                    flex-basis: 10%;
                    text-align: center;

                    .options {
                      position: absolute;
                      padding: 6px 0px;
                      background: #fff;
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;
                      text-align: left;
                      border: 1px solid rgba(26, 35, 126, 0.2588235294);
                      border-radius: 6px;
                      // top: 257px;
                      width: 165px;

                      button {
                        border: none;
                        background-color: $off-white-color;
                        color: #141a29;
                        font-size: 14px;
                        text-align: left;
                        display: flex;
                        gap: 9px;
                        align-items: center;
                        height: 4px;
                        padding: 14px 9px;
                        border-radius: 2px;
                        margin: 6px;

                        svg {
                          font-size: 14px;
                        }

                        &:hover {
                          background-color: #ff6f61;
                          color: $white-color;
                        }
                      }

                      &::before {
                        content: "";
                        background-color: $white-color;
                        border: 1px solid #1a237e42;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        transform: rotate(45deg);
                        top: -9px;
                        left: 14px;
                        border-right: 0;
                        border-bottom: 0;
                      }
                    }
                    .delete-confirmation {
                      position: absolute;
                      padding: 10px;
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-start;
                      position: absolute;
                      background: #fff;
                      text-align: left;
                      border: 1px solid rgba(26, 35, 126, 0.2588235294);
                      margin-left: -5rem;

                      .btns_sec_delete {
                        display: flex;
                        gap: 5px;
                        margin-top: 6px;
                        button {
                          border: none;
                          outline: none;
                          background-color: $green-color;
                          color: $white-color;
                          font-size: 14px;
                          text-align: left;
                          display: flex;
                          gap: 5px;
                          align-items: center;
                          height: 25px;
                          padding: 4px 12px;
                          border-radius: 4px;

                          svg {
                            font-size: 14px;
                          }

                          &:first-child {
                            background-color: $red-bg;
                            border: none;
                            outline: none;
                          }
                        }
                      }
                      h5 {
                        font-family: "Inter";
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 24px;
                        text-align: center;
                      }
                      p {
                        font-family: "Inter";
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: center;
                      }

                      &::before {
                        content: "";
                        background-color: $white-color;
                        border: 1px solid #1a237e42;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        transform: rotate(45deg);
                        top: -7px;
                        left: 6rem;
                        border-right: 0;
                        border-bottom: 0;
                      }
                    }
                  }
                }

                &:hover {
                  background: #ffffff45;
                }
              }
            }
          }
        }
      }
    }

    .api_test {
      background-color: $navy-blue-color; // text-align: center;
      padding: 30px 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      h5 {
        margin-bottom: 0px;
      }

      svg {
        font-size: 20px;
        margin-bottom: -6px;
      }
    }
  }
  .body_sec_stylesheet {
    display: flex;
    color: $white;
    .left_bar_sheet {
      flex-basis: 18%;
      background-color: $secondary-color;
      align-items: baseline;
      padding: 15px;
      margin-top: 4px;
      p {
        font-size: 12px;
        line-height: 20px !important;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
      }
      h5 {
        position: relative;
        top: 1rem;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
      }

      h6 {
        font-weight: 600;
        margin-top: 15px;
      }

      .space_btm {
        padding-bottom: 16rem;
      }
      .add_custom_api {
        padding-top: 20px;
        width: 100%;
        height: 42px;
        cursor: pointer;

        .api_btn {
          display: flex;
          width: 100%;
          background-color: $white-color;
          color: #141a29;
          height: 42px;
          align-items: center;
          border-radius: 6px;
          padding-left: 5px;

          .title {
            p {
              font-size: 14px;
              padding-left: 10px;
              font-weight: 600;
            }
          }

          &:hover {
            background-color: #ffffff45;
          }
        }

        .hvr_bg {
          width: fit-content;
          display: flex;
          justify-content: center;
          position: relative;
          top: -32px;
          display: none;
          margin: 0 auto;
          right: -0.5rem;

          .circle {
            background-color: #fff;
            border-radius: 30px;
            width: 24px;
            display: flex;
            justify-content: center;
            height: 24px;
            align-items: center;

            svg {
              color: #0ac9c9;
              font-size: 18px;
            }
          }
        }

        &:hover {
          .hvr_bg {
            display: block;
          }
        }
      }

      .connect_service {
        margin-top: 30px;
        h5 {
          font-size: 14px;

          position: relative;
          top: 1rem;
        }

        .inner {
          display: flex;
          gap: 10px;
          margin-bottom: 10px;

          .add_custom_api {
            padding-top: 20px;
            width: 100%;
            height: 42px;
            cursor: pointer;

            .api_btn {
              display: flex;
              width: 100%;
              background-color: $white-color;
              color: #141a29;
              height: 42px;
              align-items: center;
              border-radius: 6px;
              padding-left: 5px;

              .title {
                p {
                  font-size: 14px;
                  padding-left: 10px;
                  font-weight: 600;
                }
              }

              &:hover {
                background-color: #ffffff45;
              }
            }

            .hvr_bg {
              width: fit-content;
              display: flex;
              justify-content: center;
              position: relative;
              top: -32px;
              display: none;
              margin: 0 auto;
              right: -0.5rem;

              .circle {
                background-color: #fff;
                border-radius: 30px;
                width: 24px;
                display: flex;
                justify-content: center;
                height: 24px;
                align-items: center;

                svg {
                  color: #0ac9c9;
                  font-size: 18px;
                }
              }
            }

            &:hover {
              .hvr_bg {
                display: block;
              }
            }
          }
        }
      }

      .import_sec {
        margin-top: 30px;
        .add_custom_api_2 {
          padding-top: 20px;
          width: 100%;
          height: 42px;
          cursor: pointer;

          .api_btn {
            display: flex;
            width: 100%;
            background-color: $white-color;
            height: 42px;
            align-items: center;
            border-radius: 6px;
            padding-left: 10px;
            color: #141a29;

            .title {
              p {
                font-size: 14px;
                padding-left: 10px;
                font-weight: 600;
              }
            }

            &:hover {
              background-color: #ffffff45;
            }
          }

          .hvr_bg {
            width: fit-content;
            display: flex;
            justify-content: center;
            position: relative;
            top: -32px;
            display: none;
            margin: 0 auto;
            right: -0.5rem;

            .circle {
              background-color: #fff;
              border-radius: 30px;
              width: 24px;
              display: flex;
              justify-content: center;
              height: 24px;
              align-items: center;

              svg {
                color: #0ac9c9;
                font-size: 18px;
              }
            }
          }

          &:hover {
            .hvr_bg {
              display: block;
            }
          }
        }
      }

      .connect_api {
        .guide_btn {
          background-color: $white-color;
          color: #141a29;
          padding: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          gap: 6px;
          font-size: 12px;
          font-weight: 600;
          position: relative;
          top: 15px;
          transition: 0.3s;

          svg {
            font-size: 14px;
          }

          &:hover {
            background-color: #ffffff36;
          }
        }
      }

      .resources {
        margin-top: 40px;
        .guide_btn {
          background-color: $white-color;
          color: #141a29;
          padding: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          gap: 6px;
          font-size: 12px;
          font-weight: 700;
          position: relative;
          top: 15px;
          transition: 0.3s;

          svg {
            font-size: 14px;
          }

          &:hover {
            background-color: #ffffff36;
          }
        }
      }

      .guide_btn {
        background-color: $bg-color;
        color: $white-color;
        padding: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        gap: 6px;
        font-size: 12px;
        font-weight: 600;
        position: relative;
        top: 15px;
        transition: 0.3s;

        svg {
          font-size: 14px;
        }

        &:hover {
          background-color: #ffffff36;
        }
      }
    }

    .main_bar_sheet {
      flex-basis: 82%;
      padding: 15px;
      background-color: $main-color;
      color: $white;
      height: 730px;
      overflow-y: scroll;
      .api_duplicateBtns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .api_services {
          width: 50%;
          h5 {
            font-size: 14px;

            margin-bottom: 10px;
            display: flex;
            align-items: center;
            gap: 10px;
          }
          h6 {
            margin: 0px;
            font-size: 12px;
          }
          p {
            margin: 0px;
            font-size: 10px;
          }

          .inner_blocks {
            display: flex !important;
            flex-wrap: wrap !important;
            gap: 7px;

            .api_blocks {
              display: flex;
              align-items: center;
              background-color: $bg-color;
              width: 530px;
              height: 58px;
              border-radius: 6px;
              padding-left: 15px;
              cursor: pointer;
              justify-content: space-between;

              @media (max-width: 1500px) {
                display: flex;
                align-items: center;
                background-color: #fff;
                width: 465px;
                color: #141a29;
                height: 58px;
                border-radius: 6px;
                padding-left: 15px;
                cursor: pointer;
                justify-content: space-between;
              }

              .left_data {
                display: flex;
                flex-basis: 90%;
                align-items: center;
                gap: 15px;
                .logo_Sec {
                  flex-basis: 8%;
                }

                .txt_sec {
                  flex-basis: 82%;
                  .top_sec {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    span {
                      font-size: 14px;
                      font-weight: 600;
                      color: #141a29;
                    }

                    .bg_change {
                      background-color: $secondary-color;
                      padding: 1px 10px;
                      border-radius: 4px;
                      font-size: 12px !important;
                      font-weight: 400 !important;
                    }
                  }
                  .btm_sec {
                    p {
                      word-wrap: break-word;
                    }
                  }
                }
              }

              .right_data {
                flex-basis: 10%;
                .popup_sec {
                  flex-basis: 10%;
                  text-align: center;
                  .options {
                    position: absolute;
                    padding: 10px;
                    background: $white-color;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    text-align: left;
                    border: 1px solid #1a237e42;
                    padding-top: 16px;
                    margin-left: -5rem;

                    button {
                      border: none;
                      background-color: transparent;
                      color: #141a29;
                      font-size: 14px;
                      text-align: left;
                      display: flex;
                      gap: 5px;
                      align-items: center;
                      height: 25px;
                      padding: 2px 6px;
                      border-radius: 2px;

                      svg {
                        font-size: 14px;
                      }

                      &:hover {
                        background-color: $green-color;
                        color: $white-color;
                      }
                    }

                    &::before {
                      content: "";
                      background-color: $white-color;
                      border: 1px solid #1a237e42;
                      width: 15px;
                      height: 15px;
                      position: absolute;
                      transform: rotate(45deg);
                      top: -7px;
                      left: 6rem;
                      border-right: 0;
                      border-bottom: 0;
                    }
                  }

                  .delete-confirmation {
                    position: absolute;
                    padding: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    position: absolute;
                    background: #fff;
                    text-align: left;
                    border: 1px solid rgba(26, 35, 126, 0.2588235294);
                    margin-left: -5rem;
                    .btns_sec_delete {
                      display: flex;
                      gap: 5px;
                      margin-top: 6px;
                      button {
                        border: none;
                        outline: none;
                        background-color: $green-color;
                        color: $white-color;
                        font-size: 14px;
                        text-align: left;
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        height: 25px;
                        padding: 4px 12px;
                        border-radius: 4px;

                        svg {
                          font-size: 14px;
                        }

                        &:first-child {
                          background-color: $red-bg;
                          border: none;
                          outline: none;
                        }
                      }
                    }
                    h5 {
                      font-family: "Inter";
                      font-size: 14px;
                      font-weight: 700;
                      line-height: 24px;
                      text-align: center;
                    }
                    p {
                      font-family: "Inter";
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                      text-align: center;
                    }

                    &::before {
                      content: "";
                      background-color: $white-color;
                      border: 1px solid #1a237e42;
                      width: 15px;
                      height: 15px;
                      position: absolute;
                      transform: rotate(45deg);

                      border-right: 0;
                      border-bottom: 0;
                      top: -7px;
                      left: 17px;
                    }
                  }
                }
              }

              &:hover {
                background-color: #ffffff45;
              }
            }
          }
        }
        .dupliBtns {
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;
          button {
            background-color: #3366ff;
            border: none;
            border-radius: 4px;
            color: #fff;
            font-size: 12px;
            padding: auto;
            display: flex;
            align-items: center;
            gap: 6px;
            font-weight: 600;
            text-transform: capitalize;
          }
        }
      }

      .image-uploader {
        display: flex;
        gap: 10px;
        margin-top: 10px;
        .upload-container {
          min-width: 225px;
          height: 210px;
          display: flex;
          align-items: center;
          flex-direction: column;
          background-color: $bg-color;
          justify-content: center;
          text-align: center;
          cursor: pointer;
          gap: 12px;

          svg {
            transform: rotate(180deg);
            font-size: 40px;
            color: $active-color;
          }

          .clr {
            color: $white;
            font-weight: 600;
          }

          //   &:hover {
          //     background-color: $main-color;
          //   }
        }

        .image-list {
          .all_images {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            margin-bottom: 0px;
            li {
              background-image: linear-gradient(
                  45deg,
                  #3d4b6c 25%,
                  transparent 0
                ),
                linear-gradient(-45deg, #3d4b6c 25%, transparent 0),
                linear-gradient(45deg, transparent 75%, #3d4b6c 0),
                linear-gradient(-45deg, transparent 75%, #3d4b6c 0);
              background-color: #34425f;
              background-position: 0 0, 0 5px, 5px -5px, -5px 0;
              background-size: 10px 10px;
              margin-bottom: 5px;
            }

            .image-box {
              cursor: pointer;
              width: 234px;
              height: 211px;
              img {
                padding: 20px;
                object-fit: contain;
              }

              .image_name {
                background-color: $bg-color;
                padding: 8px;
                width: 100%;
                position: relative;
                bottom: -26px;
              }

              .delete-icon {
                display: flex;
                justify-content: flex-end;
                position: relative;
                top: 1rem;
                width: fit-content;
                left: 11.8rem;
                background: white;
                border-radius: 50px;
                color: $bg-color;
                cursor: pointer;

                &:hover {
                  background: rgb(184, 167, 167);
                }
              }

              &:hover {
                .image_name {
                  background-color: #717e9f;
                }
              }
            }
          }
        }
      }

      .step_1 {
        @include accordion-setting;
        .Accordion.Header {
          background-color: $cloud-color !important;
          border: none;
          .accordion-body {
            color: $white-color;
            .top_sec {
              h5 {
                font-family: "Inter";
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                text-align: left;

                color: #141a29;
              }
              p {
                font-family: "Inter";
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;

                text-align: left;
              }
            }

            .top_new_sec {
              .inner_sec5 {
                display: flex;
                gap: 15px;
                input,
                select {
                  margin-bottom: 0px;
                  width: 100%;
                  background-color: $navy-blue-color;
                  border-radius: 4px;
                  color: $white-color !important;
                  padding: 4px 10px;
                  font-size: 13px;

                  &::placeholder {
                    color: $white-color;
                  }

                  &:focus {
                    outline: none;
                    border: 1px solid $active-color;
                  }
                }

                .skip {
                  background-color: rgb(201 13 79);
                  color: $white;
                  border-radius: 3px;
                  border: none;
                  padding: 3px 8px;
                }

                .test {
                  background-color: $active-color;
                  color: $white;
                  border-radius: 3px;
                  border: none;
                  padding: 3px 8px;
                }
              }
            }

            .input_sec {
              margin-top: 15px;
              .inner {
                display: flex;
                // align-items: center;
                .label {
                  flex-basis: 25%;
                  font-size: 14px;
                  font-weight: 600;
                }
                .input_main {
                  flex-basis: 75%;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;

                  input,
                  select {
                    margin-bottom: 0px;
                    width: 100%;
                    background-color: $secondary-color;
                    border: 1px solid $border-color-select;
                    border-radius: 4px;
                    color: $white-color !important;
                    padding: 4px 10px;
                    font-size: 13px;
                    outline: none;

                    &::placeholder {
                      color: #141a29;
                    }

                    &:focus {
                      outline: none;
                      border: 1px solid $active-color;
                    }
                  }

                  span {
                    font-size: 12px;
                    margin-top: 5px;
                  }
                }
              }
            }

            .dp_block {
              .inner {
                flex-direction: column;

                span {
                  font-size: 12px;
                  line-height: 18px;
                  margin-bottom: 15px;
                }
              }
            }
          }
        }
        .title_sec {
          background-color: $secondary-color;
          font-size: 14px;
          font-weight: 600;
          padding: 6px 4px;
          margin-bottom: 15px;
          svg {
          }
        }

        .mid_sec {
          padding: 0px 15px 20px 15px;
        }

        .body_sec_2 {
          .input_sec {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 5px 15px;
            margin-top: 0px;
            .title {
              flex-basis: 30%;

              svg {
                font-size: 14px;
                margin-left: 3px;
              }
              p {
                display: flex;
                align-items: center;
              }
            }

            .input_field_new {
              flex-basis: 70%;

              input {
                margin-bottom: 0px;
                width: 100%;
                background-color: $secondary-color;
                border: 1px solid $border-color-select;
                border-radius: 4px;
                color: $white-color !important;
                padding: 4px 10px;
                font-size: 13px;

                &::placeholder {
                  color: $white-color;
                }

                &:focus {
                  outline: none;
                  border: 1px solid $active-color;
                }
              }

              select {
                margin-bottom: 0px;
                width: 100%;
                background-color: $secondary-color;
                border: 1px solid $border-color-select;
                border-radius: 4px;
                color: $white-color !important;
                padding: 4px 10px;
                font-size: 13px;

                &::placeholder {
                  color: $white-color;
                }

                &:focus {
                  outline: none;
                  border: 1px solid $active-color;
                }
              }
            }
          }
        }
      }

      .step_2 {
        margin-top: 15px;

        .body_sec_2 {
          .add_row {
            display: flex;
            justify-content: space-between;
            padding: 0px 15px;
            button {
              background-color: $secondary-color;
              border: none;
              padding: 5px 15px;
              color: $white-color;
              border-radius: 4px;
              font-size: 14px;
            }

            .val {
              margin-left: 5.4rem;
            }
          }
          .key_value_sec {
            display: block;
            position: relative;
            top: 4px;
            align-items: flex-end;
            padding-right: 15px;
            width: 100%;

            .input_sec {
              display: inline-grid;

              .title {
                margin-bottom: 15px;
              }
            }

            label {
              margin-bottom: 5px;
            }

            input {
              margin-bottom: 0px;
              width: 100%;
              background-color: $secondary-color;
              border: 1px solid $border-color-select;
              border-radius: 4px;
              color: $white-color !important;
              padding: 4px 10px;
              font-size: 13px;

              &::placeholder {
                color: $white-color;
              }

              &:focus {
                outline: none;
                border: 1px solid $active-color;
              }
            }

            .inner {
              display: flex;

              .remove_btn {
                position: relative;
                top: 10px;

                svg {
                  fill: #ff6f61;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

    .steps_sec {
      padding: 4px 0px 0px 15px !important;

      .enpoint_top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .top_btn_sec {
          display: flex;
          align-items: center;
          gap: 10rem;
          .end_right {
            button {
              background-color: $active-color;
              border: none;
              border-radius: 4px;
              color: #fff;
              font-size: 14px;
              padding: 4px 15px;
              display: flex;
              align-items: center;
              gap: 6px;
              font-weight: 600;

              svg {
                font-size: 16px;
              }

              &:hover {
                background-color: #717e9f;
              }
            }
          }
        }
      }
      .main_data_sec {
        .left_sec {
          .categories {
            margin-top: 30px;

            .title {
              font-size: 14px;
              font-weight: 600;
            }
            .inner_item {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
              .endpoint_data {
                display: flex;
                width: 350px;
                height: 62px;
                justify-content: space-between;
                align-items: center;
                background-color: $white-color;
                padding: 10px;
                border-radius: 4px;
                cursor: pointer;

                .cate_name {
                  flex-basis: 90%;
                  h5 {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 0px;
                    .method_name {
                      font-size: 12px;
                      background-color: $green-color;
                      padding: 4px 8px;
                      border-radius: 3px;
                      margin-left: 5px;
                      color: $white-color;
                    }
                  }

                  span {
                    font-size: 12px;
                  }
                }

                .actions {
                  flex-basis: 10%;
                  .popup_sec {
                    flex-basis: 10%;
                    text-align: center;

                    .options {
                      position: absolute;
                      padding: 10px;
                      background: $white-color;
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-start;
                      text-align: left;
                      border: 1px solid $white-color;
                      padding-top: 16px;

                      button {
                        border: none;
                        // background-color: transparent;
                        color: #141a29;
                        font-size: 14px;
                        text-align: left;
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        height: 25px;
                        padding: 2px 6px;
                        border-radius: 4px;

                        svg {
                          font-size: 14px;
                        }

                        &:hover {
                          background-color: $green-color;
                          color: $white-color;
                        }
                      }

                      &::before {
                        content: "";
                        background-color: $white-color;
                        border: 1px solid #1a237e42;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        transform: rotate(45deg);
                        border-right: 0;
                        border-bottom: 0;
                        top: -7px;
                        left: 17px;
                      }
                    }

                    .delete-confirmation {
                      position: absolute;
                      padding: 10px;
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-start;
                      position: absolute;
                      background: #fff;
                      text-align: left;
                      border: 1px solid rgba(26, 35, 126, 0.2588235294);
                      margin-left: -5rem;
                      .btns_sec_delete {
                        display: flex;
                        gap: 5px;
                        margin-top: 6px;
                        button {
                          border: none;
                          outline: none;
                          background-color: $green-color;
                          color: $white-color;
                          font-size: 14px;
                          text-align: left;
                          display: flex;
                          gap: 5px;
                          align-items: center;
                          height: 25px;
                          padding: 4px 12px;
                          border-radius: 4px;

                          svg {
                            font-size: 14px;
                          }

                          &:first-child {
                            background-color: $red-bg;
                            border: none;
                            outline: none;
                          }
                        }
                      }
                      h5 {
                        font-family: "Inter";
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 24px;
                        text-align: center;
                      }
                      p {
                        font-family: "Inter";
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: center;
                      }

                      &::before {
                        content: "";
                        background-color: $white-color;
                        border: 1px solid #1a237e42;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        transform: rotate(45deg);

                        border-right: 0;
                        border-bottom: 0;
                        top: -7px;
                        left: 17px;
                      }
                    }
                  }
                }

                &:hover {
                  background: #ffffff45;
                }
              }
            }
          }
        }
      }
    }

    .api_test {
      background-color: $secondary-color;
      text-align: center;
      padding: 30px 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      h5 {
        margin-bottom: 0px;
      }

      svg {
        font-size: 60px;
        margin-bottom: -6px;
      }
    }
  }

  .react-responsive-modal-closeButton {
    fill: $white;
  }
  // .done_btn {
  //   background-color: $navy-blue-color;
  //   display: flex;
  //   justify-content: center;
  //   padding: 0 15px;
  //   .main_btn {
  //     padding: 15px 0;
  //     .btn_close {
  //       background-color: $navy-blue-color;
  //       color: $white;
  //       padding: 8px 20px;
  //       border: 1px solid #bbb;
  //       border-radius: 4px;
  //       font-weight: 600;
  //       text-align: center;

  //       &:hover {
  //         border-color: 1px solid $navy-blue-color;
  //         background-color: $white-color;
  //         color: $navy-blue-color;
  //       }

  //       svg {
  //         font-size: 20px;
  //         margin-right: 5px;
  //       }
  //     }
  //   }
  // }
}

.done_btn2 {
  background-color: $navy-blue-color;
  display: flex;
  justify-content: flex-end;
  padding: 0 15px;

  .main_btn {
    padding: 15px 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 12px;
    .btn_close {
      background-color: $green-color;
      color: $white;
      padding: 8px 25px;
      border-radius: 4px;
      font-weight: 600;
      border: none;
      outline: none;

      svg {
        font-size: 20px;
        margin-right: 5px;
      }
    }

    .btn_save {
      background-color: #ff6f61;
      color: $white;
      padding: 8px 25px;
      border-radius: 4px;
      border: none;
      box-shadow: 1px 1px 10px 1px #00000021;
      font-weight: 600;
    }
  }
}

::-webkit-scrollbar {
  display: none;
}

.image_assets_Delete_Modal {
  width: 320px;
  height: 220px;
  text-align: center;
  background-color: $main-color;
  color: $white;
  overflow-y: hidden;
  padding: 15px 0px;
  border-radius: 10px;
  .bld {
    font-weight: 600;
    margin: 16px 0 10px 0;
    font-size: 16px;
  }

  .btns2 {
    padding: 5px 12px;
    border-radius: 4px;
    margin-right: 5px;
    margin-top: 8px;
    border: none;
  }

  .delete {
    background-color: #fb434a;
    color: $white;
  }

  .react-responsive-modal-closeButton {
    fill: $white;
  }
}

.api_cloud_endpoint_modal {
  max-width: 1400px;
  width: 100%;
  padding: 0px;
  max-height: 90vh;

  @media (max-width: 1500px) {
    max-width: 1200px;
    height: 85vh;
  }

  @media (max-width: 1350px) {
    max-width: 975px;
    height: 85vh;
  }

  .top_heads {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    padding-left: 12px;
    padding-bottom: 12px;
    background-color: $navy-blue-color;
    color: $white;
    border-bottom: 1px solid white;
    h3 {
      font-family: "Inter";
      font-size: 20px !important;
      font-weight: 600 !important;
      line-height: 22px !important;

      text-align: left !important;
    }
    .question_icon {
      margin-right: 55px;
      margin-bottom: 12px;
    }
  }

  .body_secs {
    display: flex;
    color: $white;
    .left_bars {
      flex-basis: 26%;
      background-color: $navy-blue-color;
      align-items: baseline;
      padding: 15px;

      @media (max-width: "1500px") {
        padding: 5px 15px 30px 15px;
      }
      p {
        font-family: "Inter";
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 24px;
      }

      .resourcess {
        margin-top: 40px;
        .guide_btns {
          color: $white-color;
          padding: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          gap: 6px;
          font-size: 12px;
          font-weight: 700;
          position: relative;
          top: 15px;
          transition: 0.3s;

          svg {
            font-size: 14px;
          }

          &:hover {
            background-color: $cloud-color;
          }
        }
      }
      h5 {
        font-family: "Inter";
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left;

        position: relative;
        top: 1rem;
      }

      h6 {
        font-family: "Inter";
        font-size: 16px !important;
        font-weight: 600 !important;
        line-height: 24px !important;
        text-align: left;

        margin-top: 8px;
      }

      .space_btms {
        padding-bottom: 14rem;
        text-align: left;
      }
      .connect_apis {
        .guide_btns {
          background-color: $green-color;
          color: $white-color;
          padding: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          gap: 6px;
          font-size: 12px;
          font-weight: 600;
          position: relative;
          top: 15px;
          transition: 0.3s;

          svg {
            font-size: 14px;
          }

          &:hover {
            background-color: #ffffff36;
          }
        }
      }

      .resourcess {
        margin-top: 40px;
        .guide_btns {
          background-color: $white-color;
          color: #141a29;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          gap: 6px;
          font-size: 12px;
          font-weight: 600;
          position: relative;
          top: 15px;
          transition: 0.3s;
          margin-bottom: 8px;

          svg {
            font-size: 14px;
          }

          &:hover {
            background-color: #ffffff36;
          }
        }
      }

      .guide_btns {
        background-color: $white-color;
        color: #141a29;
        padding: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        gap: 6px;
        font-size: 12px;
        font-weight: 600;
        position: relative;
        top: 15px;
        transition: 0.3s;

        svg {
          font-size: 14px;
        }

        &:hover {
          background-color: #ffffff36;
        }
      }
      .add_custom_api {
        padding-top: 20px;
        width: 100%;
        height: 42px;
        cursor: pointer;

        .api_btn {
          display: flex;
          width: 100%;
          background-color: $white-color;
          color: #141a29;
          height: 42px;
          align-items: center;
          border-radius: 6px;
          padding-left: 5px;

          font-family: "Inter";
          font-size: 16px;
          font-weight: 600;
          line-height: 14px;
          text-align: left;

          .title {
            p {
              font-size: 14px;
              padding-left: 10px;
              font-weight: 600;
            }
          }

          &:hover {
            background-color: #ffffff45;
          }
        }

        .hvr_bg {
          width: fit-content;
          display: flex;
          justify-content: center;
          position: relative;
          top: -32px;
          display: none;
          margin: 0 auto;
          right: -0.5rem;

          .circle {
            background-color: #fff;
            border-radius: 30px;
            width: 24px;
            display: flex;
            justify-content: center;
            height: 24px;
            align-items: center;

            svg {
              color: #0ac9c9;
              font-size: 18px;
            }
          }
        }

        &:hover {
          .hvr_bg {
            display: block;
          }
        }
      }

      .connect_service {
        margin-top: 30px;

        @media (max-width: "1500px") {
          margin-top: 12px;
        }
        h5 {
          font-size: 14px;

          position: relative;
          top: 1rem;
        }

        .inner {
          display: flex;
          gap: 10px;
          margin-bottom: 6px;

          .add_custom_api {
            padding-top: 20px;
            width: 100%;
            height: 42px;
            cursor: pointer;

            .api_btn {
              display: flex;
              width: 100%;
              background-color: $white-color;
              height: 42px;
              align-items: center;
              border-radius: 6px;
              padding-left: 5px;
              color: #141a29;
              .title {
                p {
                  font-size: 14px;
                  padding-left: 10px;
                  font-weight: 600;
                }
              }

              &:hover {
                background-color: #ffffff45;
              }
            }

            .hvr_bg {
              width: fit-content;
              display: flex;
              justify-content: center;
              position: relative;
              top: -32px;
              display: none;
              margin: 0 auto;
              right: -0.5rem;

              .circle {
                background-color: #fff;
                border-radius: 30px;
                width: 24px;
                display: flex;
                justify-content: center;
                height: 24px;
                align-items: center;

                svg {
                  color: #0ac9c9;
                  font-size: 18px;
                }
              }
            }

            &:hover {
              .hvr_bg {
                display: block;
              }
            }
          }
        }
      }

      .import_sec {
        margin-top: 15px;
        .add_custom_api_2 {
          padding-top: 20px;
          width: 100%;
          height: 42px;
          cursor: pointer;

          .api_btn {
            display: flex;
            width: 100%;
            background-color: $white-color;
            color: #141a29;
            height: 42px;
            align-items: center;
            border-radius: 6px;
            padding-left: 10px;

            .title {
              p {
                font-size: 14px;
                padding-left: 10px;
                font-weight: 600;
              }
            }

            &:hover {
              background-color: #ffffff45;
            }
          }

          .hvr_bg {
            width: fit-content;
            display: flex;
            justify-content: center;
            position: relative;
            top: -32px;
            display: none;
            margin: 0 auto;
            right: -0.5rem;

            .circle {
              background-color: #fff;
              border-radius: 30px;
              width: 24px;
              display: flex;
              justify-content: center;
              height: 24px;
              align-items: center;

              svg {
                color: #0ac9c9;
                font-size: 18px;
              }
            }
          }

          &:hover {
            .hvr_bg {
              display: block;
            }
          }
        }
      }
    }

    .main_bar {
      flex-basis: 85%;
      padding: 15px;
      background-color: $off-white-color;
      color: #141a29;
      height: 760px;
      overflow-y: scroll;

      @media (max-width: "1500px") {
        height: 783px;
      }
      .step_1 {
        @include accordion-setting;
        .Accordion.Header {
          background-color: $cloud-color !important;
          border: none;
          .accordion-body {
            color: $white-color;
            .top_secs {
              h5 {
                margin-top: 10px;
                font-family: "Inter";
                font-size: 16px !important;
                font-weight: 600 !important;
                line-height: 24px !important;
                text-align: left;
                color: #141a29;
              }
              p {
                font-size: 14px !important;
                line-height: 20px !important;
                font-weight: 600 !important;
                color: #141a29;
                text-align: left;
              }
            }
            .input_secs {
              margin-top: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 10px;
              .inners {
                .labels {
                  font-size: 14px;
                  font-weight: 600;
                  color: #141a29;
                }
                .input_mains {
                  flex-basis: 75%;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                  input,
                  select {
                    margin-bottom: 0px;
                    width: 100%;
                    background-color: $off-white-color;
                    border: 1px solid #b8b8b8;
                    border-radius: 4px;
                    color: #141a29 !important;
                    padding: 4px 10px;
                    font-size: 13px;

                    &::placeholder {
                      color: #141a29;
                    }

                    &:focus {
                      outline: none;
                      border: 1px solid $active-color;
                    }
                  }

                  span {
                    font-size: 12px;
                    margin-top: 5px;
                    color: #141a29;
                  }
                }
              }
            }
          }
          .border_bottom {
            border-bottom: 1px solid #696969;
          }

          .top_new_sec {
            .inner_sec5 {
              display: flex;
              gap: 15px;
              input,
              select {
                margin-bottom: 0px;
                width: 100%;
                background-color: $navy-blue-color;
                border-radius: 4px;
                color: $white-color !important;
                padding: 4px 10px;
                font-size: 13px;

                &::placeholder {
                  color: #141a29;
                }

                &:focus {
                  outline: none;
                  border: 1px solid $active-color;
                }
              }

              .skip {
                background-color: #ff6f61;
                color: $white;
                border-radius: 3px;
                border: none;
                padding: 3px 8px;
              }

              .test {
                background-color: $cloud-color;
                color: $white;
                border-radius: 3px;
                border: none;
                padding: 3px 8px;
              }
            }
          }

          .dp_block {
            .inner {
              flex-direction: column;

              span {
                font-size: 12px;
                line-height: 18px;
                margin-bottom: 15px;
                color: #141a29;
              }
            }
          }
        }

        .title_sec {
          background-color: $sky-cloud-color;
          font-size: 14px;
          font-weight: 600;
          padding: 6px 4px;
          margin-bottom: 15px;
          color: $white-color;
        }

        .mid_sec {
          padding: 0px 15px 20px 15px;
          p {
            font-family: "Inter";
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            text-align: left;
            color: #141a29;
          }
        }

        .body_sec_2 {
          .input_sec {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 5px 15px;
            margin-top: 0px;
            // flex-direction: column;
            .title {
              flex-basis: 30%;

              svg {
                font-size: 14px;
                margin-left: 3px;
              }
              p {
                display: flex;
                align-items: center;
                font-family: "Inter";
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                text-align: left;
              }
            }

            .input_field_new {
              flex-basis: 70%;

              input {
                margin-bottom: 0px;
                width: 100%;
                background-color: $off-white-color;
                border: 1px solid #b8b8b8;
                border-radius: 4px;
                color: #141a29 !important;
                padding: 4px 10px;
                font-family: "Inter";
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                &::placeholder {
                  color: #141a29;
                }

                &:focus {
                  outline: none;
                  border: 1px solid $active-color;
                }
              }

              select {
                margin-bottom: 0px;
                width: 100%;
                background-color: $secondary-color;
                border: 1px solid $border-color-select;
                border-radius: 4px;
                color: #141a29 !important;
                padding: 4px 10px;
                font-size: 13px;

                &::placeholder {
                  color: $white-color;
                }

                &:focus {
                  outline: none;
                  border: 1px solid $active-color;
                }
              }
            }
          }
        }
      }

      .api_services {
        h5 {
          font-size: 14px;

          position: relative;
          margin-bottom: 15px;
        }

        .inner_blocks {
          display: flex !important;
          flex-wrap: wrap !important;
          gap: 7px;

          .api_blocks {
            display: flex;
            align-items: center;
            background-color: $white-color;
            width: 530px;
            color: #141a29;
            height: 58px;
            border-radius: 6px;
            padding-left: 15px;
            cursor: pointer;
            justify-content: space-between;

            @media ($breakpoint_xxl) {
              width: 460px;
            }

            @media (max-width: "1500px") {
              width: 430px;
            }

            .left_data {
              display: flex;
              flex-basis: 90%;
              align-items: center;
              gap: 15px;
              .logo_Sec {
                flex-basis: 8%;
              }

              .txt_sec {
                flex-basis: 82%;
                .top_sec {
                  display: flex;
                  gap: 15px;
                  align-items: center;
                  span {
                    font-size: 14px;
                    font-weight: 600;
                    color: #141a29;
                  }
                  .bg_change {
                    background-color: $secondary-color;
                    padding: 1px 10px;
                    border-radius: 4px;
                    font-size: 12px !important;
                    font-weight: 400 !important;
                  }
                }
                .btm_sec {
                  p {
                    word-wrap: break-word;
                  }
                }
              }
            }

            .right_data {
              flex-basis: 10%;
              .popup_sec {
                flex-basis: 10%;
                text-align: center;

                .options {
                  position: absolute;
                  padding: 10px;
                  background: $white-color;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  text-align: left;
                  border: 1px solid #1a237e42;
                  padding-top: 16px;
                  margin-left: -5rem;

                  button {
                    border: none;
                    background-color: transparent;
                    color: #141a29;
                    font-size: 14px;
                    text-align: left;
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    height: 25px;
                    padding: 2px 6px;
                    border-radius: 2px;

                    svg {
                      font-size: 14px;
                    }

                    &:hover {
                      background-color: $green-color;
                      color: $white-color;
                    }
                  }

                  &::before {
                    content: "";
                    background-color: $white-color;
                    border: 1px solid #1a237e42;
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    transform: rotate(45deg);
                    top: -7px;
                    left: 6rem;
                    border-right: 0;
                    border-bottom: 0;
                  }
                }

                .delete-confirmation {
                  position: absolute;
                  padding: 10px;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  width: 200px;
                  margin-left: -10rem;
                  background: #fff;
                  text-align: left;
                  border: 1px solid rgba(26, 35, 126, 0.2588235294);
                  padding-top: 16px;

                  .btns_sec_delete {
                    display: flex;
                    gap: 12px;
                    margin-top: 7px;
                    margin-left: 12px;
                    button {
                      border: none;
                      outline: none;
                      background-color: $green-color;
                      color: $white-color;
                      font-size: 14px;
                      text-align: left;
                      display: flex;
                      gap: 5px;
                      align-items: center;
                      height: 25px;
                      padding: 4px 12px;
                      border-radius: 4px;

                      svg {
                        font-size: 14px;
                      }

                      &:first-child {
                        background-color: $red-bg;
                        border: none;
                        outline: none;
                      }
                    }
                  }
                  h5 {
                    font-family: "Inter";
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    text-align: center;
                  }
                  p {
                    font-family: "Inter";
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: center;
                  }
                  &::before {
                    content: "";
                    background-color: $white-color;
                    border: 1px solid #1a237e42;
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    transform: rotate(45deg);
                    top: -7px;
                    left: 6rem;
                    border-right: 0;
                    border-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }

      .image-uploader {
        display: flex;
        gap: 10px;
        margin-top: 10px;
        .upload-container {
          min-width: 225px;
          height: 210px;
          display: flex;
          align-items: center;
          flex-direction: column;
          background-color: $bg-color;
          justify-content: center;
          text-align: center;
          cursor: pointer;
          gap: 12px;

          svg {
            transform: rotate(180deg);
            font-size: 40px;
            color: $active-color;
          }

          .clr {
            color: $white;
            font-weight: 600;
          }

          //   &:hover {
          //     background-color: $main-color;
          //   }
        }

        .image-list {
          .all_images {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            margin-bottom: 0px;
            li {
              background-image: linear-gradient(
                  45deg,
                  #3d4b6c 25%,
                  transparent 0
                ),
                linear-gradient(-45deg, #3d4b6c 25%, transparent 0),
                linear-gradient(45deg, transparent 75%, #3d4b6c 0),
                linear-gradient(-45deg, transparent 75%, #3d4b6c 0);
              background-color: #34425f;
              background-position: 0 0, 0 5px, 5px -5px, -5px 0;
              background-size: 10px 10px;
              margin-bottom: 5px;
            }

            .image-box {
              cursor: pointer;
              width: 234px;
              height: 211px;
              img {
                padding: 20px;
                object-fit: contain;
              }

              .image_name {
                background-color: $bg-color;
                padding: 8px;
                width: 100%;
                position: relative;
                bottom: -26px;
              }

              .delete-icon {
                display: flex;
                justify-content: flex-end;
                position: relative;
                top: 1rem;
                width: fit-content;
                left: 11.8rem;
                background: white;
                border-radius: 50px;
                color: $bg-color;
                cursor: pointer;

                &:hover {
                  background: rgb(184, 167, 167);
                }
              }

              &:hover {
                .image_name {
                  background-color: #717e9f;
                }
              }
            }
          }
        }
      }

      .step_2 {
        margin-top: 15px;

        .body_sec_2 {
          .add_row {
            display: flex;
            justify-content: space-between;
            padding: 0px 15px;
            button {
              background-color: $green-color;
              border: none;
              padding: 5px 15px;
              color: $white-color;
              border-radius: 4px;
              font-size: 14px;
            }

            .val {
              margin-left: 5.4rem;
            }
          }
          .key_value_sec {
            display: block;
            position: relative;
            top: 4px;
            align-items: flex-end;
            padding-right: 15px;
            width: 100%;

            .input_sec {
              display: inline-grid;

              .title {
                margin-bottom: 15px;
              }
            }

            label {
              margin-bottom: 5px;
            }

            input {
              margin-bottom: 0px;
              width: 100%;
              background-color: $off-white-color;
              border: 1px solid #b8b8b8;
              border-radius: 4px;
              color: #141a29 !important;
              padding: 4px 10px;
              font-size: 13px;

              &::placeholder {
                color: #141a29;
              }

              &:focus {
                outline: none;
                border: 1px solid $active-color;
              }
            }

            .inner {
              display: flex;

              .remove_btn {
                position: relative;
                top: 10px;

                svg {
                  fill: #ff6f61;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      .api_step_z,
      .step_1 {
        .accordion {
          .accordion-item {
            background-color: $cloud-color !important;
            border: none;
            .accordion-header {
              color: #fff;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px; /* 87.5% */
              img {
                padding: 8px;
              }
              button {
                background-color: $cloud-color !important;
                color: $white-color;
                display: flex;
                padding-left: 40px;
                box-shadow: none;
                height: 44px;
                font-size: 14px;
                border-radius: 0;
                margin-top: 5px;
                &:after {
                  margin-right: auto;
                  left: 12px;
                  filter: brightness(60.5);
                  position: absolute;
                }

                &:focus {
                  box-shadow: none;
                }

                // &:first-child {
                //   margin-top: 0px;
                // }
              }
            }

            .accordion-collapse,
            .collapse,
            .show {
              .accordion-body {
                background-color: $off-white-color !important;
                padding: 10px 15px 5px 15px;

                // @media ($breakpoint_xxl) {
                //   padding: 15px 15px 10px 8px;
                // }
                @media (max-width: 1399px) {
                  padding: 10px 10px 5px 8px;
                }

                .input_secz {
                  margin-top: 15px;
                  font-family: "Inter";
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 24px;
                  text-align: left;

                  .innerz {
                    display: flex;
                    // align-items: center;
                    .labelz {
                      flex-basis: 25%;
                      font-size: 14px;
                      font-weight: 600;
                    }
                    .input_mainz {
                      flex-basis: 75%;
                      display: flex;
                      flex-direction: column;
                      gap: 10px;

                      input,
                      select {
                        margin-bottom: 0px;
                        width: 100%;
                        background-color: $off-white-color;
                        border: 1px solid #b8b8b8;
                        border-radius: 4px;
                        color: #141a29;
                        padding: 4px 10px;
                        font-size: 12px !important
                        ;
                        outline: none;

                        &::placeholder {
                          color: #141a29;
                        }

                        &:focus {
                          outline: none;
                          border: 1px solid $cloud-color;
                        }
                      }

                      span {
                        font-size: 12px;
                        margin-top: 5px;
                      }
                    }
                  }
                }
                .top_new_secs {
                  h5 {
                    font-family: "Inter";
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: left;
                  }
                  .inner_secs {
                    display: flex;
                    gap: 15px;
                    margin-bottom: 28px;
                    input,
                    select {
                      margin-bottom: 0px;
                      width: 100%;
                      background-color: $navy-blue-color;
                      border-radius: 4px;
                      color: $white-color !important;
                      padding: 4px 10px;
                      font-size: 13px;

                      &::placeholder {
                        color: $white-color;
                      }

                      &:focus {
                        outline: none;
                        border: 1px solid $active-color;
                      }
                    }

                    .skips {
                      background-color: #ff6f61;
                      color: $white;
                      border-radius: 3px;
                      border: none;
                      padding: 3px 8px;
                    }

                    .tests {
                      background-color: $cloud-color;
                      color: $white;
                      border-radius: 3px;
                      border: none;
                      padding: 3px 8px;
                    }
                  }
                }

                .dp_blocks {
                  .inners {
                    flex-direction: column;

                    span {
                      font-size: 12px;
                      line-height: 18px;
                      margin-bottom: 15px;
                    }
                    .input_mains {
                      input {
                        margin-bottom: 0px;
                        width: 100%;
                        background-color: $off-white-color;
                        border-radius: 4px;
                        color: #141a29 !important;
                        padding: 8px 10px;
                        font-size: 13px;
                        border: 1px solid #b8b8b8;
                        &::placeholder {
                          color: #141a29;
                        }

                        &:focus {
                          outline: none;
                          // border: 1px solid $active-color;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .accordion-button {
            &::after {
              width: 1rem;
              height: 0.9rem;
              background-size: 1rem;
            }
          }
        }

        .title_sec {
          background-color: $sky-cloud-color;
          font-size: 14px;
          font-weight: 600;
          padding: 6px 4px;
          margin-bottom: 15px;
          color: $white-color;
        }

        .mid_sec {
          padding: 0px 15px 20px 15px;
          p {
            font-family: "Inter";
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            text-align: left;
            color: #141a29;
          }
        }

        .body_sec_2 {
          .input_sec {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 5px 15px;
            margin-top: 0px;
            // flex-direction: column;
            .title {
              flex-basis: 30%;

              svg {
                font-size: 14px;
                margin-left: 3px;
              }
              p {
                display: flex;
                align-items: center;
                font-family: "Inter";
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                text-align: left;
              }
            }

            .input_field_new {
              flex-basis: 70%;

              input {
                margin-bottom: 0px;
                width: 100%;
                background-color: $off-white-color;
                border: 1px solid #b8b8b8;
                border-radius: 4px;
                color: #141a29 !important;
                padding: 4px 10px;
                font-family: "Inter";
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                &::placeholder {
                  color: #141a29;
                }

                &:focus {
                  outline: none;
                  border: 1px solid $active-color;
                }
              }

              select {
                margin-bottom: 0px;
                width: 100%;
                background-color: $secondary-color;
                border: 1px solid $border-color-select;
                border-radius: 4px;
                color: #141a29 !important;
                padding: 4px 10px;
                font-size: 13px;

                &::placeholder {
                  color: $white-color;
                }

                &:focus {
                  outline: none;
                  border: 1px solid $active-color;
                }
              }
            }
          }
        }
      }
    }

    .steps_sec {
      padding: 4px 0px 0px 15px !important;

      .enpoint_top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .top_btn_sec {
          display: flex;
          align-items: center;
          gap: 10rem;
          padding-right: 15px;
          .end_left {
            h5 {
              font-family: "Inter";
              font-size: 24px;
              font-weight: 600;
              line-height: 24px;
              text-align: left;
            }
            p {
              font-family: "Inter";
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
            }
          }

          .end_right {
            button {
              background-color: $green-color;
              border: none;
              border-radius: 4px;
              color: #fff;
              font-size: 14px;
              padding: 4px 15px;
              display: flex;
              align-items: center;
              gap: 6px;
              font-weight: 600;

              svg {
                font-size: 16px;
              }

              &:hover {
                background-color: #717e9f;
              }
            }
          }
        }
      }
      .main_data_sec {
        .left_sec {
          .categories {
            margin-top: 30px;

            .title {
              font-size: 14px;
              font-weight: 600;
            }
            .inner_item {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
              .endpoint_data {
                display: flex;
                width: 350px;
                height: 62px;
                justify-content: space-between;
                align-items: center;
                background-color: $white-color;
                padding: 10px;
                border-radius: 4px;
                cursor: pointer;

                .cate_name {
                  flex-basis: 90%;
                  h5 {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 0px;
                    .method_name {
                      font-size: 12px;
                      background-color: $green-color;
                      padding: 4px 8px;
                      border-radius: 3px;
                      margin-left: 5px;
                      color: $white-color;
                    }
                  }

                  span {
                    font-size: 12px;
                  }
                }

                .actions {
                  flex-basis: 10%;
                  .popup_sec {
                    flex-basis: 10%;
                    text-align: center;

                    .options {
                      position: absolute;
                      padding: 10px;
                      background: $white-color;
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-start;
                      text-align: left;
                      border: 1px solid #1a237e42;
                      padding-top: 16px;
                      margin-left: -5rem;

                      button {
                        border: none;
                        background-color: transparent;
                        color: #141a29;
                        font-size: 14px;
                        text-align: left;
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        height: 25px;
                        padding: 2px 6px;
                        border-radius: 2px;

                        svg {
                          font-size: 14px;
                        }

                        &:hover {
                          background-color: $green-color;
                          color: $white-color;
                        }
                      }

                      &::before {
                        content: "";
                        background-color: $white-color;
                        border: 1px solid #1a237e42;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        transform: rotate(45deg);
                        top: -7px;
                        left: 6rem;
                        border-right: 0;
                        border-bottom: 0;
                      }
                    }
                    .delete-confirmation {
                      position: absolute;
                      padding: 10px;
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-start;
                      position: absolute;
                      background: #fff;
                      text-align: left;
                      border: 1px solid rgba(26, 35, 126, 0.2588235294);
                      margin-left: -5rem;
                      .btns_sec_delete {
                        display: flex;
                        gap: 5px;
                        margin-top: 6px;
                        button {
                          border: none;
                          outline: none;
                          background-color: $green-color;
                          color: $white-color;
                          font-size: 14px;
                          text-align: left;
                          display: flex;
                          gap: 5px;
                          align-items: center;
                          height: 25px;
                          padding: 4px 12px;
                          border-radius: 4px;

                          svg {
                            font-size: 14px;
                          }

                          &:first-child {
                            background-color: $red-bg;
                            border: none;
                            outline: none;
                          }
                        }
                      }
                      h5 {
                        font-family: "Inter";
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 24px;
                        text-align: center;
                      }
                      p {
                        font-family: "Inter";
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: center;
                      }

                      &::before {
                        content: "";
                        background-color: $white-color;
                        border: 1px solid #1a237e42;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        transform: rotate(45deg);
                        top: -7px;
                        left: 6rem;
                        border-right: 0;
                        border-bottom: 0;
                      }
                    }
                  }
                }

                &:hover {
                  background: #ffffff45;
                }
              }
            }
          }
        }
      }
    }

    .api_test {
      background-color: $navy-blue-color; // text-align: center;
      padding: 30px 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      h5 {
        margin-bottom: 0px;
      }

      svg {
        font-size: 20px;
        margin-bottom: -6px;
      }
    }
  }
  .body_sec_stylesheet {
    display: flex;
    color: $white;
    .left_bar_sheet {
      flex-basis: 18%;
      background-color: $secondary-color;
      align-items: baseline;
      padding: 15px;
      margin-top: 4px;
      p {
        font-size: 12px;
        line-height: 20px !important;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
      }
      h5 {
        position: relative;
        top: 1rem;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
      }

      h6 {
        font-weight: 600;
        margin-top: 15px;
      }

      .space_btm {
        padding-bottom: 16rem;
      }
      .add_custom_api {
        padding-top: 20px;
        width: 100%;
        height: 42px;
        cursor: pointer;

        .api_btn {
          display: flex;
          width: 100%;
          background-color: $white-color;
          color: #141a29;
          height: 42px;
          align-items: center;
          border-radius: 6px;
          padding-left: 5px;

          .title {
            p {
              font-size: 14px;
              padding-left: 10px;
              font-weight: 600;
            }
          }

          &:hover {
            background-color: #ffffff45;
          }
        }

        .hvr_bg {
          width: fit-content;
          display: flex;
          justify-content: center;
          position: relative;
          top: -32px;
          display: none;
          margin: 0 auto;
          right: -0.5rem;

          .circle {
            background-color: #fff;
            border-radius: 30px;
            width: 24px;
            display: flex;
            justify-content: center;
            height: 24px;
            align-items: center;

            svg {
              color: #0ac9c9;
              font-size: 18px;
            }
          }
        }

        &:hover {
          .hvr_bg {
            display: block;
          }
        }
      }

      .connect_service {
        margin-top: 30px;
        h5 {
          font-size: 14px;

          position: relative;
          top: 1rem;
        }

        .inner {
          display: flex;
          gap: 10px;
          margin-bottom: 10px;

          .add_custom_api {
            padding-top: 20px;
            width: 100%;
            height: 42px;
            cursor: pointer;

            .api_btn {
              display: flex;
              width: 100%;
              background-color: $white-color;
              color: #141a29;
              height: 42px;
              align-items: center;
              border-radius: 6px;
              padding-left: 5px;

              .title {
                p {
                  font-size: 14px;
                  padding-left: 10px;
                  font-weight: 600;
                }
              }

              &:hover {
                background-color: #ffffff45;
              }
            }

            .hvr_bg {
              width: fit-content;
              display: flex;
              justify-content: center;
              position: relative;
              top: -32px;
              display: none;
              margin: 0 auto;
              right: -0.5rem;

              .circle {
                background-color: #fff;
                border-radius: 30px;
                width: 24px;
                display: flex;
                justify-content: center;
                height: 24px;
                align-items: center;

                svg {
                  color: #0ac9c9;
                  font-size: 18px;
                }
              }
            }

            &:hover {
              .hvr_bg {
                display: block;
              }
            }
          }
        }
      }

      .import_sec {
        margin-top: 30px;
        .add_custom_api_2 {
          padding-top: 20px;
          width: 100%;
          height: 42px;
          cursor: pointer;

          .api_btn {
            display: flex;
            width: 100%;
            background-color: $white-color;
            height: 42px;
            align-items: center;
            border-radius: 6px;
            padding-left: 10px;
            color: #141a29;

            .title {
              p {
                font-size: 14px;
                padding-left: 10px;
                font-weight: 600;
              }
            }

            &:hover {
              background-color: #ffffff45;
            }
          }

          .hvr_bg {
            width: fit-content;
            display: flex;
            justify-content: center;
            position: relative;
            top: -32px;
            display: none;
            margin: 0 auto;
            right: -0.5rem;

            .circle {
              background-color: #fff;
              border-radius: 30px;
              width: 24px;
              display: flex;
              justify-content: center;
              height: 24px;
              align-items: center;

              svg {
                color: #0ac9c9;
                font-size: 18px;
              }
            }
          }

          &:hover {
            .hvr_bg {
              display: block;
            }
          }
        }
      }

      .connect_apis {
        .guide_btns {
          color: $white-color;
          padding: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          gap: 6px;
          font-size: 12px;
          font-weight: 600;
          position: relative;
          top: 15px;
          transition: 0.3s;

          svg {
            font-size: 14px;
          }

          &:hover {
            background-color: $cloud-color;
          }
        }
      }

      .guide_btns {
        color: $white-color;
        padding: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        gap: 6px;
        font-size: 12px;
        font-weight: 600;
        position: relative;
        top: 15px;
        transition: 0.3s;

        svg {
          font-size: 14px;
        }

        &:hover {
          background-color: $cloud-color;
        }
      }
    }

    .main_bar_sheet {
      flex-basis: 82%;
      padding: 15px;
      background-color: $main-color;
      color: $white;
      height: 730px;
      overflow-y: scroll;
      .api_duplicateBtns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .api_services {
          width: 50%;
          h5 {
            font-size: 14px;

            margin-bottom: 10px;
            display: flex;
            align-items: center;
            gap: 10px;
          }
          h6 {
            margin: 0px;
            font-size: 12px;
          }
          p {
            margin: 0px;
            font-size: 10px;
          }

          .inner_blocks {
            display: flex !important;
            flex-wrap: wrap !important;
            gap: 7px;

            .api_blocks {
              display: flex;
              align-items: center;
              background-color: $bg-color;
              width: 530px;
              height: 58px;
              border-radius: 6px;
              padding-left: 15px;
              cursor: pointer;
              justify-content: space-between;

              .left_data {
                display: flex;
                flex-basis: 90%;
                align-items: center;
                gap: 15px;
                .logo_Sec {
                  flex-basis: 8%;
                }

                .txt_sec {
                  flex-basis: 82%;
                  .top_sec {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    span {
                      font-size: 14px;
                      font-weight: 600;
                      color: #141a29;
                    }

                    .bg_change {
                      background-color: $secondary-color;
                      padding: 1px 10px;
                      border-radius: 4px;
                      font-size: 12px !important;
                      font-weight: 400 !important;
                    }
                  }
                  .btm_sec {
                    p {
                      word-wrap: break-word;
                    }
                  }
                }
              }

              .right_data {
                flex-basis: 10%;
                .popup_sec {
                  flex-basis: 10%;
                  text-align: center;
                  .options {
                    position: absolute;
                    padding: 10px;
                    background: $white-color;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    text-align: left;
                    border: 1px solid #1a237e42;
                    padding-top: 16px;
                    margin-left: -5rem;

                    button {
                      border: none;
                      background-color: transparent;
                      color: #141a29;
                      font-size: 14px;
                      text-align: left;
                      display: flex;
                      gap: 5px;
                      align-items: center;
                      height: 25px;
                      padding: 2px 6px;
                      border-radius: 2px;

                      svg {
                        font-size: 14px;
                      }

                      &:hover {
                        background-color: $green-color;
                        color: $white-color;
                      }
                    }

                    &::before {
                      content: "";
                      background-color: $white-color;
                      border: 1px solid #1a237e42;
                      width: 15px;
                      height: 15px;
                      position: absolute;
                      transform: rotate(45deg);
                      top: -7px;
                      left: 6rem;
                      border-right: 0;
                      border-bottom: 0;
                    }
                  }

                  .delete-confirmation {
                    position: absolute;
                    padding: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    position: absolute;
                    background: #fff;
                    text-align: left;
                    border: 1px solid rgba(26, 35, 126, 0.2588235294);
                    margin-left: -5rem;
                    .btns_sec_delete {
                      display: flex;
                      gap: 5px;
                      margin-top: 6px;
                      button {
                        border: none;
                        outline: none;
                        background-color: $green-color;
                        color: $white-color;
                        font-size: 14px;
                        text-align: left;
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        height: 25px;
                        padding: 4px 12px;
                        border-radius: 4px;

                        svg {
                          font-size: 14px;
                        }

                        &:first-child {
                          background-color: $red-bg;
                          border: none;
                          outline: none;
                        }
                      }
                    }
                    h5 {
                      font-family: "Inter";
                      font-size: 14px;
                      font-weight: 700;
                      line-height: 24px;
                      text-align: center;
                    }
                    p {
                      font-family: "Inter";
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                      text-align: center;
                    }

                    &::before {
                      content: "";
                      background-color: $white-color;
                      border: 1px solid #1a237e42;
                      width: 15px;
                      height: 15px;
                      position: absolute;
                      transform: rotate(45deg);

                      border-right: 0;
                      border-bottom: 0;
                      top: -7px;
                      left: 17px;
                    }
                  }
                }
              }

              &:hover {
                background-color: #ffffff45;
              }
            }
          }
        }
        .dupliBtns {
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;
          button {
            background-color: #3366ff;
            border: none;
            border-radius: 4px;
            color: #fff;
            font-size: 12px;
            padding: auto;
            display: flex;
            align-items: center;
            gap: 6px;
            font-weight: 600;
            text-transform: capitalize;
          }
        }
      }

      .image-uploader {
        display: flex;
        gap: 10px;
        margin-top: 10px;
        .upload-container {
          min-width: 225px;
          height: 210px;
          display: flex;
          align-items: center;
          flex-direction: column;
          background-color: $bg-color;
          justify-content: center;
          text-align: center;
          cursor: pointer;
          gap: 12px;

          svg {
            transform: rotate(180deg);
            font-size: 40px;
            color: $active-color;
          }

          .clr {
            color: $white;
            font-weight: 600;
          }

          //   &:hover {
          //     background-color: $main-color;
          //   }
        }

        .image-list {
          .all_images {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            margin-bottom: 0px;
            li {
              background-image: linear-gradient(
                  45deg,
                  #3d4b6c 25%,
                  transparent 0
                ),
                linear-gradient(-45deg, #3d4b6c 25%, transparent 0),
                linear-gradient(45deg, transparent 75%, #3d4b6c 0),
                linear-gradient(-45deg, transparent 75%, #3d4b6c 0);
              background-color: #34425f;
              background-position: 0 0, 0 5px, 5px -5px, -5px 0;
              background-size: 10px 10px;
              margin-bottom: 5px;
            }

            .image-box {
              cursor: pointer;
              width: 234px;
              height: 211px;
              img {
                padding: 20px;
                object-fit: contain;
              }

              .image_name {
                background-color: $bg-color;
                padding: 8px;
                width: 100%;
                position: relative;
                bottom: -26px;
              }

              .delete-icon {
                display: flex;
                justify-content: flex-end;
                position: relative;
                top: 1rem;
                width: fit-content;
                left: 11.8rem;
                background: white;
                border-radius: 50px;
                color: $bg-color;
                cursor: pointer;

                &:hover {
                  background: rgb(184, 167, 167);
                }
              }

              &:hover {
                .image_name {
                  background-color: #717e9f;
                }
              }
            }
          }
        }
      }

      .step_1 {
        .Accordion.Header {
          background-color: $cloud-color !important;
          border: none;
          .accordion-body {
            color: $white-color;
            .top_secs {
              h5 {
                font-family: "Inter";
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                text-align: left;

                color: #141a29;
              }
              p {
                font-family: "Inter";
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;

                text-align: left;
              }
              .input_secs {
                margin-top: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                .inners {
                  .labels {
                    font-size: 14px;
                    font-weight: 600;
                    color: #141a29;
                  }
                  .input_mains {
                    flex-basis: 75%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    input,
                    select {
                      margin-bottom: 0px;
                      width: 100%;
                      background-color: $off-white-color;
                      border: 1px solid #b8b8b8;
                      border-radius: 4px;
                      color: #141a29 !important;
                      padding: 4px 10px;
                      font-size: 13px;

                      &::placeholder {
                        color: #141a29;
                      }

                      &:focus {
                        outline: none;
                        border: 1px solid $active-color;
                      }
                    }

                    span {
                      font-size: 12px;
                      margin-top: 5px;
                      color: #141a29;
                    }
                  }
                }
              }
            }
          }
        }
        .title_sec {
          background-color: $secondary-color;
          font-size: 14px;
          font-weight: 600;
          padding: 6px 4px;
          margin-bottom: 15px;
        }

        .mid_sec {
          padding: 0px 15px 20px 15px;
        }

        .body_sec_2 {
          .input_sec {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 5px 15px;
            margin-top: 0px;
            .title {
              flex-basis: 30%;

              svg {
                font-size: 14px;
                margin-left: 3px;
              }
              p {
                display: flex;
                align-items: center;
              }
            }

            .input_field_new {
              flex-basis: 70%;

              input {
                margin-bottom: 0px;
                width: 100%;
                background-color: $secondary-color;
                border: 1px solid $border-color-select;
                border-radius: 4px;
                color: $white-color !important;
                padding: 4px 10px;
                font-size: 13px;

                &::placeholder {
                  color: $white-color;
                }

                &:focus {
                  outline: none;
                  border: 1px solid $active-color;
                }
              }

              select {
                margin-bottom: 0px;
                width: 100%;
                background-color: $secondary-color;
                border: 1px solid $border-color-select;
                border-radius: 4px;
                color: $white-color !important;
                padding: 4px 10px;
                font-size: 13px;

                &::placeholder {
                  color: $white-color;
                }

                &:focus {
                  outline: none;
                  border: 1px solid $active-color;
                }
              }
            }
          }
        }
      }

      .step_2 {
        margin-top: 15px;

        .body_sec_2 {
          .add_row {
            display: flex;
            justify-content: space-between;
            padding: 0px 15px;
            button {
              background-color: $secondary-color;
              border: none;
              padding: 5px 15px;
              color: $white-color;
              border-radius: 4px;
              font-size: 14px;
            }

            .val {
              margin-left: 5.4rem;
            }
          }
          .key_value_sec {
            display: block;
            position: relative;
            top: 4px;
            align-items: flex-end;
            padding-right: 15px;
            width: 100%;

            .input_sec {
              display: inline-grid;

              .title {
                margin-bottom: 15px;
              }
            }

            label {
              margin-bottom: 5px;
            }

            input {
              margin-bottom: 0px;
              width: 100%;
              background-color: $secondary-color;
              border: 1px solid $border-color-select;
              border-radius: 4px;
              color: $white-color !important;
              padding: 4px 10px;
              font-size: 13px;

              &::placeholder {
                color: $white-color;
              }

              &:focus {
                outline: none;
                border: 1px solid $active-color;
              }
            }

            .inner {
              display: flex;

              .remove_btn {
                position: relative;
                top: 10px;

                svg {
                  fill: #ff6f61;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

    .steps_sec {
      padding: 4px 0px 0px 15px !important;

      .enpoint_top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .top_btn_sec {
          display: flex;
          align-items: center;
          gap: 10rem;
          .end_right {
            button {
              background-color: $active-color;
              border: none;
              border-radius: 4px;
              color: #fff;
              font-size: 14px;
              padding: 4px 15px;
              display: flex;
              align-items: center;
              gap: 6px;
              font-weight: 600;

              svg {
                font-size: 16px;
              }

              &:hover {
                background-color: #717e9f;
              }
            }
          }
        }
      }
      .main_data_sec {
        .left_sec {
          .categories {
            margin-top: 30px;

            .title {
              font-size: 14px;
              font-weight: 600;
            }
            .inner_item {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
              .endpoint_data {
                display: flex;
                width: 350px;
                height: 62px;
                justify-content: space-between;
                align-items: center;
                background-color: $white-color;
                padding: 10px;
                border-radius: 4px;
                cursor: pointer;

                .cate_name {
                  flex-basis: 90%;
                  h5 {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 0px;
                    .method_name {
                      font-size: 12px;
                      background-color: $green-color;
                      padding: 4px 8px;
                      border-radius: 3px;
                      margin-left: 5px;
                      color: $white-color;
                    }
                  }

                  span {
                    font-size: 12px;
                  }
                }

                .actions {
                  flex-basis: 10%;
                  .popup_sec {
                    flex-basis: 10%;
                    text-align: center;

                    .options {
                      position: absolute;
                      padding: 10px;
                      background: $white-color;
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-start;
                      text-align: left;
                      border: 1px solid $white-color;
                      padding-top: 16px;

                      button {
                        border: none;
                        // background-color: transparent;
                        color: #141a29;
                        font-size: 14px;
                        text-align: left;
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        height: 25px;
                        padding: 2px 6px;
                        border-radius: 4px;

                        svg {
                          font-size: 14px;
                        }

                        &:hover {
                          background-color: $green-color;
                          color: $white-color;
                        }
                      }

                      &::before {
                        content: "";
                        background-color: $white-color;
                        border: 1px solid #1a237e42;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        transform: rotate(45deg);
                        border-right: 0;
                        border-bottom: 0;
                        top: -7px;
                        left: 17px;
                      }
                    }

                    .delete-confirmation {
                      position: absolute;
                      padding: 10px;
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-start;
                      position: absolute;
                      background: #fff;
                      text-align: left;
                      border: 1px solid rgba(26, 35, 126, 0.2588235294);
                      margin-left: -5rem;
                      .btns_sec_delete {
                        display: flex;
                        gap: 5px;
                        margin-top: 6px;
                        button {
                          border: none;
                          outline: none;
                          background-color: $green-color;
                          color: $white-color;
                          font-size: 14px;
                          text-align: left;
                          display: flex;
                          gap: 5px;
                          align-items: center;
                          height: 25px;
                          padding: 4px 12px;
                          border-radius: 4px;

                          svg {
                            font-size: 14px;
                          }

                          &:first-child {
                            background-color: $red-bg;
                            border: none;
                            outline: none;
                          }
                        }
                      }
                      h5 {
                        font-family: "Inter";
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 24px;
                        text-align: center;
                      }
                      p {
                        font-family: "Inter";
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: center;
                      }

                      &::before {
                        content: "";
                        background-color: $white-color;
                        border: 1px solid #1a237e42;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        transform: rotate(45deg);

                        border-right: 0;
                        border-bottom: 0;
                        top: -7px;
                        left: 17px;
                      }
                    }
                  }
                }

                &:hover {
                  background: #ffffff45;
                }
              }
            }
          }
        }
      }
    }

    .api_test {
      background-color: $secondary-color;
      text-align: center;
      padding: 30px 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      h5 {
        margin-bottom: 0px;
      }

      svg {
        font-size: 60px;
        margin-bottom: -6px;
      }
    }
  }

  .react-responsive-modal-closeButton {
    fill: $white;
  }
}

.api_cloud_endpoint_update_modal {
  max-width: 1400px;
  width: 100%;
  padding: 0px;
  max-height: 90vh;

  @media (max-width: 1500px) {
    max-width: 1200px;
    height: 85vh;
  }

  @media (max-width: 1350px) {
    max-width: 975px;
    height: 85vh;
  }

  .top_headz {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    padding-left: 12px;
    background-color: $navy-blue-color;
    color: $white;
    border-bottom: 1px solid #ffff;
    h3 {
      h3 {
        font-family: "Inter";
        font-size: 20px !important;
        font-weight: 600 !important;
        line-height: 22px !important;

        text-align: left !important;
      }
    }

    span {
      background-color: $white-color;
      color: #1a237e;

      padding: 10px 24px;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      /* text-align: center; */
      /* padding-left: 10px; */
      margin-right: 54px;
      margin-bottom: 10px;
      border-radius: 4px;
      gap: 10px;
      display: flex;
    }
    .question_iconz {
      margin-right: 55px;
      margin-bottom: 12px;
    }
  }

  .body_secz {
    display: flex;
    color: $white;
    .left_barz {
      flex-basis: 26%;
      background-color: $navy-blue-color;
      align-items: baseline;
      padding: 15px;

      @media (max-width: "1500px") {
        padding: 5px 15px 30px 15px;
      }
      p {
        font-family: "Inter";
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left;

        text-align: left;
      }
      h5 {
        font-family: "Inter";
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left;
        position: relative;
        top: 1rem;
      }

      h6 {
        font-family: "Inter";
        font-size: 16px !important;
        font-weight: 600 !important;
        line-height: 24px !important;
        text-align: left;
        margin-top: 8px;
      }
      .connect_apiz {
        .guide_btnz {
          background-color: $green-color;
          color: $white-color;
          padding: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          gap: 6px;
          font-size: 12px;
          font-weight: 600;
          position: relative;
          top: 15px;
          transition: 0.3s;

          svg {
            font-size: 14px;
          }

          &:hover {
            background-color: #ffffff36;
          }
        }
      }
      .resourcesz {
        margin-top: 40px;
        .guide_btnz {
          background-color: $white-color;
          color: #141a29;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          gap: 6px;
          font-size: 12px;
          font-weight: 700;
          position: relative;
          top: 15px;
          transition: 0.3s;
          margin-bottom: 8px;

          svg {
            font-size: 14px;
          }

          &:hover {
            background-color: #ffffff36;
          }
        }
      }
      .guide_btnz {
        background-color: $white-color;
        color: #141a29;
        padding: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        gap: 6px;
        font-size: 12px;
        font-weight: 600;
        position: relative;
        top: 15px;
        transition: 0.3s;

        svg {
          font-size: 14px;
        }

        &:hover {
          background-color: #ffffff36;
        }
      }
      .space_btmz {
        padding-bottom: 2rem;
      }

      // .add_custom_api {
      //   padding-top: 20px;
      //   width: 100%;
      //   height: 42px;
      //   cursor: pointer;

      //   .api_btn {
      //     display: flex;
      //     width: 100%;
      //     background-color: $white-color;
      //     color: #141a29;
      //     height: 42px;
      //     align-items: center;
      //     border-radius: 6px;
      //     padding-left: 5px;

      //     font-family: "Inter";
      //     font-size: 16px;
      //     font-weight: 600;
      //     line-height: 14px;
      //     text-align: left;

      //     .title {
      //       p {
      //         font-size: 14px;
      //         padding-left: 10px;
      //         font-weight: 600;
      //       }
      //     }

      //     &:hover {
      //       background-color: #ffffff45;
      //     }
      //   }

      //   .hvr_bg {
      //     width: fit-content;
      //     display: flex;
      //     justify-content: center;
      //     position: relative;
      //     top: -32px;
      //     display: none;
      //     margin: 0 auto;
      //     right: -0.5rem;

      //     .circle {
      //       background-color: #fff;
      //       border-radius: 30px;
      //       width: 24px;
      //       display: flex;
      //       justify-content: center;
      //       height: 24px;
      //       align-items: center;

      //       svg {
      //         color: #0ac9c9;
      //         font-size: 18px;
      //       }
      //     }
      //   }

      //   &:hover {
      //     .hvr_bg {
      //       display: block;
      //     }
      //   }
      // }

      // .connect_service {
      //   margin-top: 30px;

      //   @media (max-width: "1500px") {
      //     margin-top: 12px;
      //   }
      //   h5 {
      //     font-size: 14px;

      //     position: relative;
      //     top: 1rem;
      //   }

      //   .inner {
      //     display: flex;
      //     gap: 10px;
      //     margin-bottom: 6px;

      //     .add_custom_api {
      //       padding-top: 20px;
      //       width: 100%;
      //       height: 42px;
      //       cursor: pointer;

      //       .api_btn {
      //         display: flex;
      //         width: 100%;
      //         background-color: $white-color;
      //         height: 42px;
      //         align-items: center;
      //         border-radius: 6px;
      //         padding-left: 5px;
      //         color: #141a29;

      //         .title {
      //           p {
      //             font-size: 14px;
      //             padding-left: 10px;
      //             font-weight: 600;
      //           }
      //         }

      //         &:hover {
      //           background-color: #ffffff45;
      //         }
      //       }

      //       .hvr_bg {
      //         width: fit-content;
      //         display: flex;
      //         justify-content: center;
      //         position: relative;
      //         top: -32px;
      //         display: none;
      //         margin: 0 auto;
      //         right: -0.5rem;

      //         .circle {
      //           background-color: #fff;
      //           border-radius: 30px;
      //           width: 24px;
      //           display: flex;
      //           justify-content: center;
      //           height: 24px;
      //           align-items: center;

      //           svg {
      //             color: #0ac9c9;
      //             font-size: 18px;
      //           }
      //         }
      //       }

      //       &:hover {
      //         .hvr_bg {
      //           display: block;
      //         }
      //       }
      //     }
      //   }
      // }

      // .import_sec {
      //   margin-top: 15px;
      //   .add_custom_api_2 {
      //     padding-top: 20px;
      //     width: 100%;
      //     height: 42px;
      //     cursor: pointer;

      //     .api_btn {
      //       display: flex;
      //       width: 100%;
      //       background-color: $white-color;
      //       color: #141a29;
      //       height: 42px;
      //       align-items: center;
      //       border-radius: 6px;
      //       padding-left: 10px;

      //       .title {
      //         p {
      //           font-size: 14px;
      //           padding-left: 10px;
      //           font-weight: 600;
      //         }
      //       }

      //       &:hover {
      //         background-color: #ffffff45;
      //       }
      //     }

      //     .hvr_bg {
      //       width: fit-content;
      //       display: flex;
      //       justify-content: center;
      //       position: relative;
      //       top: -32px;
      //       display: none;
      //       margin: 0 auto;
      //       right: -0.5rem;

      //       .circle {
      //         background-color: #fff;
      //         border-radius: 30px;
      //         width: 24px;
      //         display: flex;
      //         justify-content: center;
      //         height: 24px;
      //         align-items: center;

      //         svg {
      //           color: #0ac9c9;
      //           font-size: 18px;
      //         }
      //       }
      //     }

      //     &:hover {
      //       .hvr_bg {
      //         display: block;
      //       }
      //     }
      //   }
      // }
    }

    .main_bar {
      flex-basis: 85%;
      padding: 15px;
      background-color: $off-white-color;
      color: #141a29;
      height: 738px;
      overflow-y: scroll;

      @media (max-width: "1500px") {
        height: 680px;
      }

      .api_services {
        h5 {
          font-size: 14px;

          position: relative;
          margin-bottom: 15px;
        }

        .inner_blocks {
          display: flex !important;
          flex-wrap: wrap !important;
          gap: 7px;

          .api_blocks {
            display: flex;
            align-items: center;
            background-color: $white-color;
            width: 530px;
            color: #141a29;
            height: 58px;
            border-radius: 6px;
            padding-left: 15px;
            cursor: pointer;
            justify-content: space-between;

            @media ($breakpoint_xxl) {
              width: 460px;
            }

            @media (max-width: "1500px") {
              width: 430px;
            }

            .left_data {
              display: flex;
              flex-basis: 90%;
              align-items: center;
              gap: 15px;
              .logo_Sec {
                flex-basis: 8%;
              }

              .txt_sec {
                flex-basis: 82%;
                .top_sec {
                  display: flex;
                  gap: 15px;
                  align-items: center;
                  span {
                    font-size: 14px;
                    font-weight: 600;
                    color: #141a29;
                  }
                  .bg_change {
                    background-color: $secondary-color;
                    padding: 1px 10px;
                    border-radius: 4px;
                    font-size: 12px !important;
                    font-weight: 400 !important;
                  }
                }
                .btm_sec {
                  p {
                    word-break: break-all;
                  }
                }
              }
            }

            .right_data {
              flex-basis: 10%;
              .popup_sec {
                flex-basis: 10%;
                text-align: center;

                .options {
                  position: absolute;
                  padding: 10px;
                  background: $white-color;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  text-align: left;
                  border: 1px solid #1a237e42;

                  margin-left: -5rem;

                  button {
                    border: none;
                    background-color: transparent;
                    color: #141a29;
                    font-size: 14px;
                    text-align: left;
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    height: 25px;
                    padding: 9px 18px;
                    border-radius: 2px;

                    svg {
                      font-size: 14px;
                    }

                    &:hover {
                      background-color: $green-color;
                      color: $white-color;
                    }
                  }

                  &::before {
                    content: "";
                    background-color: $white-color;
                    border: 1px solid #1a237e42;
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    transform: rotate(45deg);
                    top: -7px;
                    left: 6rem;
                    border-right: 0;
                    border-bottom: 0;
                  }
                }

                .delete-confirmation {
                  position: absolute;
                  padding: 10px;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  width: 200px;
                  margin-left: -10rem;
                  background: #fff;
                  text-align: left;
                  border: 1px solid rgba(26, 35, 126, 0.2588235294);
                  padding-top: 16px;

                  .btns_sec_delete {
                    display: flex;
                    gap: 12px;
                    margin-top: 7px;
                    margin-left: 12px;
                    button {
                      border: none;
                      outline: none;
                      background-color: $green-color;
                      color: $white-color;
                      font-size: 14px;
                      text-align: left;
                      display: flex;
                      gap: 5px;
                      align-items: center;
                      height: 25px;
                      padding: 4px 12px;
                      border-radius: 4px;

                      svg {
                        font-size: 14px;
                      }

                      &:first-child {
                        background-color: $red-bg;
                        border: none;
                        outline: none;
                      }
                    }
                  }
                  h5 {
                    font-family: "Inter";
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    text-align: center;
                  }
                  p {
                    font-family: "Inter";
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: center;
                  }
                  &::before {
                    content: "";
                    background-color: $white-color;
                    border: 1px solid #1a237e42;
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    transform: rotate(45deg);
                    top: -7px;
                    left: 166px;
                    border-right: 0;
                    border-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }

      .image-uploader {
        display: flex;
        gap: 10px;
        margin-top: 10px;
        .upload-container {
          min-width: 225px;
          height: 210px;
          display: flex;
          align-items: center;
          flex-direction: column;
          background-color: $bg-color;
          justify-content: center;
          text-align: center;
          cursor: pointer;
          gap: 12px;

          svg {
            transform: rotate(180deg);
            font-size: 40px;
            color: $active-color;
          }

          .clr {
            color: $white;
            font-weight: 600;
          }

          //   &:hover {
          //     background-color: $main-color;
          //   }
        }

        .image-list {
          .all_images {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            margin-bottom: 0px;
            li {
              background-image: linear-gradient(
                  45deg,
                  #3d4b6c 25%,
                  transparent 0
                ),
                linear-gradient(-45deg, #3d4b6c 25%, transparent 0),
                linear-gradient(45deg, transparent 75%, #3d4b6c 0),
                linear-gradient(-45deg, transparent 75%, #3d4b6c 0);
              background-color: #34425f;
              background-position: 0 0, 0 5px, 5px -5px, -5px 0;
              background-size: 10px 10px;
              margin-bottom: 5px;
            }

            .image-box {
              cursor: pointer;
              width: 234px;
              height: 211px;
              img {
                padding: 20px;
                object-fit: contain;
              }

              .image_name {
                background-color: $bg-color;
                padding: 8px;
                width: 100%;
                position: relative;
                bottom: -26px;
              }

              .delete-icon {
                display: flex;
                justify-content: flex-end;
                position: relative;
                top: 1rem;
                width: fit-content;
                left: 11.8rem;
                background: white;
                border-radius: 50px;
                color: $bg-color;
                cursor: pointer;

                &:hover {
                  background: rgb(184, 167, 167);
                }
              }

              &:hover {
                .image_name {
                  background-color: #717e9f;
                }
              }
            }
          }
        }
      }
      .api_step_z,
      .step_1 {
        .accordion {
          .accordion-item {
            background-color: $cloud-color !important;
            border: none;
            .accordion-header {
              color: #fff;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px; /* 87.5% */
              img {
                padding: 8px;
              }
              button {
                background-color: $cloud-color !important;
                color: $white-color;
                display: flex;
                padding-left: 40px;
                box-shadow: none;
                height: 44px;
                font-size: 14px;
                border-radius: 0;
                margin-top: 5px;
                &:after {
                  margin-right: auto;
                  left: 12px;
                  filter: brightness(60.5);
                  position: absolute;
                }

                &:focus {
                  box-shadow: none;
                }
              }
            }

            .accordion-collapse,
            .collapse,
            .show {
              .accordion-body {
                background-color: $off-white-color !important;
                padding: 10px 15px 5px 15px;

                @media (max-width: 1399px) {
                  padding: 10px 10px 5px 8px;
                }

                .input_secz {
                  margin-top: 15px;
                  font-family: "Inter";
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 24px;
                  text-align: left;

                  .innerz {
                    display: flex;
                    .labelz {
                      flex-basis: 25%;
                      font-size: 14px;
                      font-weight: 600;
                    }
                    .input_mainz {
                      flex-basis: 75%;
                      display: flex;
                      flex-direction: column;
                      gap: 10px;

                      input,
                      select {
                        margin-bottom: 0px;
                        width: 100%;
                        background-color: $off-white-color;
                        border: 1px solid #b8b8b8;
                        border-radius: 4px;
                        color: #141a29 !important;
                        padding: 4px 10px;
                        font-size: 13px;
                        outline: none;

                        &::placeholder {
                          color: #141a29;
                        }

                        &:focus {
                          outline: none;
                          border: 1px solid $cloud-color;
                        }
                      }

                      span {
                        font-size: 12px;
                        margin-top: 5px;
                      }
                    }
                  }
                }
                .top_new_secz {
                  h5 {
                    font-family: "Inter";
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: left;
                  }
                  .inner_secs {
                    display: flex;
                    gap: 15px;
                    margin-bottom: 28px;
                    input,
                    select {
                      margin-bottom: 0px;
                      width: 100%;
                      background-color: $navy-blue-color;
                      border-radius: 4px;
                      color: $white-color !important;
                      padding: 4px 10px;
                      font-size: 13px;

                      &::placeholder {
                        color: $white-color;
                      }

                      &:focus {
                        outline: none;
                        border: 1px solid $active-color;
                      }
                    }

                    .skips {
                      background-color: #ff6f61;
                      color: $white;
                      border-radius: 3px;
                      border: none;
                      padding: 3px 8px;
                    }

                    .tests {
                      background-color: $cloud-color;
                      color: $white;
                      border-radius: 3px;
                      border: none;
                      padding: 3px 8px;
                    }
                  }
                }

                .dp_blockz {
                  .innerz {
                    flex-direction: column;

                    span {
                      font-size: 12px;
                      line-height: 18px;
                      margin-bottom: 15px;
                    }
                    .input_mainz {
                      input {
                        margin-bottom: 0px;
                        width: 100%;
                        background-color: $off-white-color;
                        border-radius: 4px;
                        color: #141a29 !important;
                        padding: 8px 10px;
                        font-size: 13px;
                        border: 1px solid #b8b8b8;
                        &::placeholder {
                          color: #141a29;
                        }

                        &:focus {
                          outline: none;
                          // border: 1px solid $active-color;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .accordion-button {
            &::after {
              width: 1rem;
              height: 0.9rem;
              background-size: 1rem;
            }
          }
        }

        .title_sec {
          background-color: $sky-cloud-color;
          font-size: 14px;
          font-weight: 600;
          padding: 6px 4px;
          margin-bottom: 15px;
          color: $white-color;
        }

        .mid_sec {
          padding: 0px 15px 20px 15px;
          p {
            font-family: "Inter";
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            text-align: left;
            color: #141a29;
          }
        }

        .body_sec_2 {
          .input_sec {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 5px 15px;
            margin-top: 0px;
            // flex-direction: column;
            .title {
              flex-basis: 30%;

              svg {
                font-size: 14px;
                margin-left: 3px;
              }
              p {
                display: flex;
                align-items: center;
                font-family: "Inter";
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                text-align: left;
              }
            }

            .input_field_new {
              flex-basis: 70%;

              input {
                margin-bottom: 0px;
                width: 100%;
                background-color: $off-white-color;
                border: 1px solid #b8b8b8;
                border-radius: 4px;
                color: #141a29 !important;
                padding: 4px 10px;
                font-family: "Inter";
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                &::placeholder {
                  color: #141a29;
                }

                &:focus {
                  outline: none;
                  border: 1px solid $active-color;
                }
              }

              select {
                margin-bottom: 0px;
                width: 100%;
                background-color: $secondary-color;
                border: 1px solid $border-color-select;
                border-radius: 4px;
                color: #141a29 !important;
                padding: 4px 10px;
                font-size: 13px;

                &::placeholder {
                  color: $white-color;
                }

                &:focus {
                  outline: none;
                  border: 1px solid $active-color;
                }
              }
            }
          }
        }
      }
      .step_1 {
        .Accordion.Header {
          background-color: $cloud-color !important;
          border: none;
          .accordion-body {
            color: $white-color;
            .top_secz {
              h5 {
                font-family: "Inter";
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                text-align: left;

                color: #141a29;
              }
              p {
                font-family: "Inter";
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;

                text-align: left;
              }
            }
          }
        }
        .title_sec {
          background-color: $secondary-color;
          font-size: 14px;
          font-weight: 600;
          padding: 6px 4px;
          margin-bottom: 15px;
        }

        .mid_sec {
          padding: 0px 15px 20px 15px;
        }

        .body_sec_2 {
          .input_sec {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 5px 15px;
            margin-top: 0px;
            .title {
              flex-basis: 30%;

              svg {
                font-size: 14px;
                margin-left: 3px;
              }
              p {
                display: flex;
                align-items: center;
              }
            }

            .input_field_new {
              flex-basis: 70%;

              input {
                margin-bottom: 0px;
                width: 100%;
                background-color: $secondary-color;
                border: 1px solid $border-color-select;
                border-radius: 4px;
                color: $white-color !important;
                padding: 4px 10px;
                font-size: 13px;

                &::placeholder {
                  color: $white-color;
                }

                &:focus {
                  outline: none;
                  border: 1px solid $active-color;
                }
              }

              select {
                margin-bottom: 0px;
                width: 100%;
                background-color: $secondary-color;
                border: 1px solid $border-color-select;
                border-radius: 4px;
                color: $white-color !important;
                padding: 4px 10px;
                font-size: 13px;

                &::placeholder {
                  color: $white-color;
                }

                &:focus {
                  outline: none;
                  border: 1px solid $active-color;
                }
              }
            }
          }
        }
      }
      .step_2 {
        margin-top: 15px;

        .body_sec_2 {
          .add_row {
            display: flex;
            justify-content: space-between;
            padding: 0px 15px;
            button {
              background-color: $green-color;
              border: none;
              padding: 5px 15px;
              color: $white-color;
              border-radius: 4px;
              font-size: 14px;
            }

            .val {
              margin-left: 5.4rem;
            }
          }
          .key_value_sec {
            display: block;
            position: relative;
            top: 4px;
            align-items: flex-end;
            padding-right: 15px;
            width: 100%;

            .input_sec {
              display: inline-grid;

              .title {
                margin-bottom: 15px;
              }
            }

            label {
              margin-bottom: 5px;
            }

            input {
              margin-bottom: 0px;
              width: 100%;
              background-color: $off-white-color;
              border: 1px solid #b8b8b8;
              border-radius: 4px;
              color: #141a29 !important;
              padding: 4px 10px;
              font-size: 13px;

              &::placeholder {
                color: #141a29;
              }

              &:focus {
                outline: none;
                border: 1px solid $active-color;
              }
            }

            .inner {
              display: flex;

              .remove_btn {
                position: relative;
                top: 10px;

                svg {
                  fill: #ff6f61;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

    .steps_sec {
      padding: 4px 0px 0px 15px !important;

      .enpoint_top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .top_btn_sec {
          display: flex;
          align-items: center;
          gap: 10rem;
          padding-right: 15px;
          .end_left {
            h5 {
              font-family: "Inter";
              font-size: 24px;
              font-weight: 600;
              line-height: 24px;
              text-align: left;
            }
            p {
              font-family: "Inter";
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
            }
          }

          .end_right {
            button {
              background-color: $green-color;
              border: none;
              border-radius: 4px;
              color: #fff;
              font-size: 14px;
              padding: 4px 15px;
              display: flex;
              align-items: center;
              gap: 6px;
              font-weight: 600;

              svg {
                font-size: 16px;
              }

              &:hover {
                background-color: #717e9f;
              }
            }
          }
        }
      }
      .main_data_sec {
        .left_sec {
          .categories {
            margin-top: 30px;

            .title {
              font-size: 14px;
              font-weight: 600;
            }
            .inner_item {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
              .endpoint_data {
                display: flex;
                width: 350px;
                height: 62px;
                justify-content: space-between;
                align-items: center;
                background-color: $white-color;
                padding: 10px;
                border-radius: 4px;
                cursor: pointer;

                .cate_name {
                  flex-basis: 90%;
                  h5 {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 0px;
                    .method_name {
                      font-size: 12px;
                      background-color: $green-color;
                      padding: 4px 8px;
                      border-radius: 3px;
                      margin-left: 5px;
                      color: $white-color;
                    }
                  }

                  span {
                    font-size: 12px;
                  }
                }

                .actions {
                  flex-basis: 10%;
                  .popup_sec {
                    flex-basis: 10%;
                    text-align: center;

                    .options {
                      position: absolute;
                      padding: 10px;
                      background: $white-color;
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-start;
                      text-align: left;
                      border: 1px solid #1a237e42;
                      padding-top: 16px;
                      margin-left: -5rem;

                      button {
                        border: none;
                        background-color: transparent;
                        color: #141a29;
                        font-size: 14px;
                        text-align: left;
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        height: 25px;
                        padding: 2px 6px;
                        border-radius: 2px;

                        svg {
                          font-size: 14px;
                        }

                        &:hover {
                          background-color: $green-color;
                          color: $white-color;
                        }
                      }

                      &::before {
                        content: "";
                        background-color: $white-color;
                        border: 1px solid #1a237e42;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        transform: rotate(45deg);
                        top: -7px;
                        left: 6rem;
                        border-right: 0;
                        border-bottom: 0;
                      }
                    }
                    .delete-confirmation {
                      position: absolute;
                      padding: 10px;
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-start;
                      position: absolute;
                      background: #fff;
                      text-align: left;
                      border: 1px solid rgba(26, 35, 126, 0.2588235294);
                      margin-left: -5rem;
                      .btns_sec_delete {
                        display: flex;
                        gap: 5px;
                        margin-top: 6px;
                        button {
                          border: none;
                          outline: none;
                          background-color: $green-color;
                          color: $white-color;
                          font-size: 14px;
                          text-align: left;
                          display: flex;
                          gap: 5px;
                          align-items: center;
                          height: 25px;
                          padding: 4px 12px;
                          border-radius: 4px;

                          svg {
                            font-size: 14px;
                          }

                          &:first-child {
                            background-color: $red-bg;
                            border: none;
                            outline: none;
                          }
                        }
                      }
                      h5 {
                        font-family: "Inter";
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 24px;
                        text-align: center;
                      }
                      p {
                        font-family: "Inter";
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: center;
                      }

                      &::before {
                        content: "";
                        background-color: $white-color;
                        border: 1px solid #1a237e42;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        transform: rotate(45deg);
                        top: -7px;
                        left: 6rem;
                        border-right: 0;
                        border-bottom: 0;
                      }
                    }
                  }
                }

                &:hover {
                  background: #ffffff45;
                }
              }
            }
          }
        }
      }
    }

    .api_test {
      background-color: $navy-blue-color; // text-align: center;
      padding: 30px 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      h5 {
        margin-bottom: 0px;
      }

      svg {
        font-size: 20px;
        margin-bottom: -6px;
      }
    }
  }
  .body_sec_stylesheet {
    display: flex;
    color: $white;
    .left_bar_sheet {
      flex-basis: 18%;
      background-color: $secondary-color;
      align-items: baseline;
      padding: 15px;
      margin-top: 4px;
      p {
        font-size: 12px;
        line-height: 20px !important;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
      }
      h5 {
        position: relative;
        top: 1rem;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
      }

      h6 {
        font-weight: 600;
        margin-top: 15px;
      }

      .space_btm {
        padding-bottom: 16rem;
      }
      .add_custom_api {
        padding-top: 20px;
        width: 100%;
        height: 42px;
        cursor: pointer;

        .api_btn {
          display: flex;
          width: 100%;
          background-color: $white-color;
          color: #141a29;
          height: 42px;
          align-items: center;
          border-radius: 6px;
          padding-left: 5px;

          .title {
            p {
              font-size: 14px;
              padding-left: 10px;
              font-weight: 600;
            }
          }

          &:hover {
            background-color: #ffffff45;
          }
        }

        .hvr_bg {
          width: fit-content;
          display: flex;
          justify-content: center;
          position: relative;
          top: -32px;
          display: none;
          margin: 0 auto;
          right: -0.5rem;

          .circle {
            background-color: #fff;
            border-radius: 30px;
            width: 24px;
            display: flex;
            justify-content: center;
            height: 24px;
            align-items: center;

            svg {
              color: #0ac9c9;
              font-size: 18px;
            }
          }
        }

        &:hover {
          .hvr_bg {
            display: block;
          }
        }
      }

      .connect_service {
        margin-top: 30px;
        h5 {
          font-size: 14px;

          position: relative;
          top: 1rem;
        }

        .inner {
          display: flex;
          gap: 10px;
          margin-bottom: 10px;

          .add_custom_api {
            padding-top: 20px;
            width: 100%;
            height: 42px;
            cursor: pointer;

            .api_btn {
              display: flex;
              width: 100%;
              background-color: $white-color;
              color: #141a29;
              height: 42px;
              align-items: center;
              border-radius: 6px;
              padding-left: 5px;

              .title {
                p {
                  font-size: 14px;
                  padding-left: 10px;
                  font-weight: 600;
                }
              }

              &:hover {
                background-color: #ffffff45;
              }
            }

            .hvr_bg {
              width: fit-content;
              display: flex;
              justify-content: center;
              position: relative;
              top: -32px;
              display: none;
              margin: 0 auto;
              right: -0.5rem;

              .circle {
                background-color: #fff;
                border-radius: 30px;
                width: 24px;
                display: flex;
                justify-content: center;
                height: 24px;
                align-items: center;

                svg {
                  color: #0ac9c9;
                  font-size: 18px;
                }
              }
            }

            &:hover {
              .hvr_bg {
                display: block;
              }
            }
          }
        }
      }

      .import_sec {
        margin-top: 30px;
        .add_custom_api_2 {
          padding-top: 20px;
          width: 100%;
          height: 42px;
          cursor: pointer;

          .api_btn {
            display: flex;
            width: 100%;
            background-color: $white-color;
            height: 42px;
            align-items: center;
            border-radius: 6px;
            padding-left: 10px;
            color: #141a29;

            .title {
              p {
                font-size: 14px;
                padding-left: 10px;
                font-weight: 600;
              }
            }

            &:hover {
              background-color: #ffffff45;
            }
          }

          .hvr_bg {
            width: fit-content;
            display: flex;
            justify-content: center;
            position: relative;
            top: -32px;
            display: none;
            margin: 0 auto;
            right: -0.5rem;

            .circle {
              background-color: #fff;
              border-radius: 30px;
              width: 24px;
              display: flex;
              justify-content: center;
              height: 24px;
              align-items: center;

              svg {
                color: #0ac9c9;
                font-size: 18px;
              }
            }
          }

          &:hover {
            .hvr_bg {
              display: block;
            }
          }
        }
      }

      .connect_api {
        .guide_btn {
          background-color: $white-color;
          color: #141a29;
          padding: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          gap: 6px;
          font-size: 12px;
          font-weight: 600;
          position: relative;
          top: 15px;
          transition: 0.3s;

          svg {
            font-size: 14px;
          }

          &:hover {
            background-color: #ffffff36;
          }
        }
      }

      .resources {
        margin-top: 40px;
        .guide_btn {
          background-color: $white-color;
          color: #141a29;
          padding: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          gap: 6px;
          font-size: 12px;
          font-weight: 700;
          position: relative;
          top: 15px;
          transition: 0.3s;

          svg {
            font-size: 14px;
          }

          &:hover {
            background-color: #ffffff36;
          }
        }
      }

      .guide_btn {
        background-color: $bg-color;
        color: $white-color;
        padding: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        gap: 6px;
        font-size: 12px;
        font-weight: 600;
        position: relative;
        top: 15px;
        transition: 0.3s;

        svg {
          font-size: 14px;
        }

        &:hover {
          background-color: #ffffff36;
        }
      }
    }

    .main_bar_sheet {
      flex-basis: 82%;
      padding: 15px;
      background-color: $main-color;
      color: $white;
      height: 730px;
      overflow-y: scroll;
      .api_duplicateBtns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .api_services {
          width: 50%;
          h5 {
            font-size: 14px;

            margin-bottom: 10px;
            display: flex;
            align-items: center;
            gap: 10px;
          }
          h6 {
            margin: 0px;
            font-size: 12px;
          }
          p {
            margin: 0px;
            font-size: 10px;
          }

          .inner_blocks {
            display: flex !important;
            flex-wrap: wrap !important;
            gap: 7px;

            .api_blocks {
              display: flex;
              align-items: center;
              background-color: $bg-color;
              width: 530px;
              height: 58px;
              border-radius: 6px;
              padding-left: 15px;
              cursor: pointer;
              justify-content: space-between;

              .left_data {
                display: flex;
                flex-basis: 90%;
                align-items: center;
                gap: 15px;
                .logo_Sec {
                  flex-basis: 8%;
                }

                // .txt_sec {
                //   flex-basis: 82%;
                //   .top_sec {
                //     display: flex;
                //     gap: 15px;
                //     align-items: center;
                //     span {
                //       font-size: 14px;
                //       font-weight: 600;
                //       color: #141a29;
                //     }

                //     .bg_change {
                //       background-color: $secondary-color;
                //       padding: 1px 10px;
                //       border-radius: 4px;
                //       font-size: 12px !important;
                //       font-weight: 400 !important;
                //     }
                //   }
                //   .btm_sec {
                //     p {
                //       word-wrap: break-word;
                //     }
                //   }
                // }
              }

              .right_data {
                flex-basis: 10%;
                .popup_sec {
                  flex-basis: 10%;
                  text-align: center;
                  .options {
                    position: absolute;
                    padding: 10px;
                    background: $white-color;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    text-align: left;
                    border: 1px solid #1a237e42;
                    padding-top: 16px;
                    margin-left: -5rem;

                    button {
                      border: none;
                      background-color: transparent;
                      color: #141a29;
                      font-size: 14px;
                      text-align: left;
                      display: flex;
                      gap: 5px;
                      align-items: center;
                      height: 25px;
                      padding: 2px 6px;
                      border-radius: 2px;

                      svg {
                        font-size: 14px;
                      }

                      &:hover {
                        background-color: $green-color;
                        color: $white-color;
                      }
                    }

                    &::before {
                      content: "";
                      background-color: $white-color;
                      border: 1px solid #1a237e42;
                      width: 15px;
                      height: 15px;
                      position: absolute;
                      transform: rotate(45deg);
                      top: -7px;
                      left: 6rem;
                      border-right: 0;
                      border-bottom: 0;
                    }
                  }

                  .delete-confirmation {
                    position: absolute;
                    padding: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    position: absolute;
                    background: #fff;
                    text-align: left;
                    border: 1px solid rgba(26, 35, 126, 0.2588235294);
                    margin-left: -5rem;
                    .btns_sec_delete {
                      display: flex;
                      gap: 5px;
                      margin-top: 6px;
                      button {
                        border: none;
                        outline: none;
                        background-color: $green-color;
                        color: $white-color;
                        font-size: 14px;
                        text-align: left;
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        height: 25px;
                        padding: 4px 12px;
                        border-radius: 4px;

                        svg {
                          font-size: 14px;
                        }

                        &:first-child {
                          background-color: $red-bg;
                          border: none;
                          outline: none;
                        }
                      }
                    }
                    h5 {
                      font-family: "Inter";
                      font-size: 14px;
                      font-weight: 700;
                      line-height: 24px;
                      text-align: center;
                    }
                    p {
                      font-family: "Inter";
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                      text-align: center;
                    }

                    &::before {
                      content: "";
                      background-color: $white-color;
                      border: 1px solid #1a237e42;
                      width: 15px;
                      height: 15px;
                      position: absolute;
                      transform: rotate(45deg);

                      border-right: 0;
                      border-bottom: 0;
                      top: -7px;
                      left: 17px;
                    }
                  }
                }
              }

              &:hover {
                background-color: #ffffff45;
              }
            }
          }
        }
        .dupliBtns {
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;
          button {
            background-color: #3366ff;
            border: none;
            border-radius: 4px;
            color: #fff;
            font-size: 12px;
            padding: auto;
            display: flex;
            align-items: center;
            gap: 6px;
            font-weight: 600;
            text-transform: capitalize;
          }
        }
      }

      .image-uploader {
        display: flex;
        gap: 10px;
        margin-top: 10px;
        .upload-container {
          min-width: 225px;
          height: 210px;
          display: flex;
          align-items: center;
          flex-direction: column;
          background-color: $bg-color;
          justify-content: center;
          text-align: center;
          cursor: pointer;
          gap: 12px;

          svg {
            transform: rotate(180deg);
            font-size: 40px;
            color: $active-color;
          }

          .clr {
            color: $white;
            font-weight: 600;
          }
        }

        .image-list {
          .all_images {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            margin-bottom: 0px;
            li {
              background-image: linear-gradient(
                  45deg,
                  #3d4b6c 25%,
                  transparent 0
                ),
                linear-gradient(-45deg, #3d4b6c 25%, transparent 0),
                linear-gradient(45deg, transparent 75%, #3d4b6c 0),
                linear-gradient(-45deg, transparent 75%, #3d4b6c 0);
              background-color: #34425f;
              background-position: 0 0, 0 5px, 5px -5px, -5px 0;
              background-size: 10px 10px;
              margin-bottom: 5px;
            }

            .image-box {
              cursor: pointer;
              width: 234px;
              height: 211px;
              img {
                padding: 20px;
                object-fit: contain;
              }

              .image_name {
                background-color: $bg-color;
                padding: 8px;
                width: 100%;
                position: relative;
                bottom: -26px;
              }

              .delete-icon {
                display: flex;
                justify-content: flex-end;
                position: relative;
                top: 1rem;
                width: fit-content;
                left: 11.8rem;
                background: white;
                border-radius: 50px;
                color: $bg-color;
                cursor: pointer;

                &:hover {
                  background: rgb(184, 167, 167);
                }
              }

              &:hover {
                .image_name {
                  background-color: #717e9f;
                }
              }
            }
          }
        }
      }

      .step_1 {
        @include accordion-setting;
        .Accordion.Header {
          background-color: $cloud-color !important;
          border: none;
          .accordion-body {
            color: $white-color;
            .top_sec {
              h5 {
                font-family: "Inter";
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                text-align: left;

                color: #141a29;
              }
              p {
                font-family: "Inter";
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;

                text-align: left;
              }
            }

            .top_new_sec {
              .inner_sec5 {
                display: flex;
                gap: 15px;
                input,
                select {
                  margin-bottom: 0px;
                  width: 100%;
                  background-color: $navy-blue-color;
                  border-radius: 4px;
                  color: $white-color !important;
                  padding: 4px 10px;
                  font-size: 13px;

                  &::placeholder {
                    color: $white-color;
                  }

                  &:focus {
                    outline: none;
                    border: 1px solid $active-color;
                  }
                }

                .skip {
                  background-color: rgb(201 13 79);
                  color: $white;
                  border-radius: 3px;
                  border: none;
                  padding: 3px 8px;
                }

                .test {
                  background-color: $active-color;
                  color: $white;
                  border-radius: 3px;
                  border: none;
                  padding: 3px 8px;
                }
              }
            }

            .input_sec {
              margin-top: 15px;
              .inner {
                display: flex;
                // align-items: center;
                .label {
                  flex-basis: 25%;
                  font-size: 14px;
                  font-weight: 600;
                }
                .input_main {
                  flex-basis: 75%;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;

                  input,
                  select {
                    margin-bottom: 0px;
                    width: 100%;
                    background-color: $secondary-color;
                    border: 1px solid $border-color-select;
                    border-radius: 4px;
                    color: $white-color !important;
                    padding: 4px 10px;
                    font-size: 13px;
                    outline: none;

                    &::placeholder {
                      color: #141a29;
                    }

                    &:focus {
                      outline: none;
                      border: 1px solid $active-color;
                    }
                  }

                  span {
                    font-size: 12px;
                    margin-top: 5px;
                  }
                }
              }
            }

            .dp_block {
              .inner {
                flex-direction: column;

                span {
                  font-size: 12px;
                  line-height: 18px;
                  margin-bottom: 15px;
                }
              }
            }
          }
        }
        .title_sec {
          background-color: $secondary-color;
          font-size: 14px;
          font-weight: 600;
          padding: 6px 4px;
          margin-bottom: 15px;
          svg {
          }
        }

        .mid_sec {
          padding: 0px 15px 20px 15px;
        }

        .body_sec_2 {
          .input_sec {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 5px 15px;
            margin-top: 0px;
            .title {
              flex-basis: 30%;

              svg {
                font-size: 14px;
                margin-left: 3px;
              }
              p {
                display: flex;
                align-items: center;
              }
            }

            .input_field_new {
              flex-basis: 70%;

              input {
                margin-bottom: 0px;
                width: 100%;
                background-color: $secondary-color;
                border: 1px solid $border-color-select;
                border-radius: 4px;
                color: $white-color !important;
                padding: 4px 10px;
                font-size: 13px;

                &::placeholder {
                  color: $white-color;
                }

                &:focus {
                  outline: none;
                  border: 1px solid $active-color;
                }
              }

              select {
                margin-bottom: 0px;
                width: 100%;
                background-color: $secondary-color;
                border: 1px solid $border-color-select;
                border-radius: 4px;
                color: $white-color !important;
                padding: 4px 10px;
                font-size: 13px;

                &::placeholder {
                  color: $white-color;
                }

                &:focus {
                  outline: none;
                  border: 1px solid $active-color;
                }
              }
            }
          }
        }
      }

      .step_2 {
        margin-top: 15px;

        .body_sec_2 {
          .add_row {
            display: flex;
            justify-content: space-between;
            padding: 0px 15px;
            button {
              background-color: $secondary-color;
              border: none;
              padding: 5px 15px;
              color: $white-color;
              border-radius: 4px;
              font-size: 14px;
            }

            .val {
              margin-left: 5.4rem;
            }
          }
          .key_value_sec {
            display: block;
            position: relative;
            top: 4px;
            align-items: flex-end;
            padding-right: 15px;
            width: 100%;

            .input_sec {
              display: inline-grid;

              .title {
                margin-bottom: 15px;
              }
            }

            label {
              margin-bottom: 5px;
            }

            input {
              margin-bottom: 0px;
              width: 100%;
              background-color: $secondary-color;
              border: 1px solid $border-color-select;
              border-radius: 4px;
              color: $white-color !important;
              padding: 4px 10px;
              font-size: 13px;

              &::placeholder {
                color: $white-color;
              }

              &:focus {
                outline: none;
                border: 1px solid $active-color;
              }
            }

            .inner {
              display: flex;

              .remove_btn {
                position: relative;
                top: 10px;

                svg {
                  fill: #ff6f61;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

    .steps_sec {
      padding: 4px 0px 0px 15px !important;

      .enpoint_top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .top_btn_sec {
          display: flex;
          align-items: center;
          gap: 10rem;
          .end_right {
            button {
              background-color: $active-color;
              border: none;
              border-radius: 4px;
              color: #fff;
              font-size: 14px;
              padding: 4px 15px;
              display: flex;
              align-items: center;
              gap: 6px;
              font-weight: 600;

              svg {
                font-size: 16px;
              }

              &:hover {
                background-color: #717e9f;
              }
            }
          }
        }
      }
      .main_data_sec {
        .left_sec {
          .categories {
            margin-top: 30px;

            .title {
              font-size: 14px;
              font-weight: 600;
            }
            .inner_item {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
              .endpoint_data {
                display: flex;
                width: 350px;
                height: 62px;
                justify-content: space-between;
                align-items: center;
                background-color: $white-color;
                padding: 10px;
                border-radius: 4px;
                cursor: pointer;

                .cate_name {
                  flex-basis: 90%;
                  h5 {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 0px;
                    .method_name {
                      font-size: 12px;
                      background-color: $green-color;
                      padding: 4px 8px;
                      border-radius: 3px;
                      margin-left: 5px;
                      color: $white-color;
                    }
                  }

                  span {
                    font-size: 12px;
                  }
                }

                .actions {
                  flex-basis: 10%;
                  .popup_sec {
                    flex-basis: 10%;
                    text-align: center;

                    .options {
                      position: absolute;
                      padding: 10px;
                      background: $white-color;
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-start;
                      text-align: left;
                      border: 1px solid $white-color;
                      padding-top: 16px;

                      button {
                        border: none;
                        color: #141a29;
                        font-size: 14px;
                        text-align: left;
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        height: 25px;
                        padding: 2px 6px;
                        border-radius: 4px;

                        svg {
                          font-size: 14px;
                        }

                        &:hover {
                          background-color: $green-color;
                          color: $white-color;
                        }
                      }

                      &::before {
                        content: "";
                        background-color: $white-color;
                        border: 1px solid #1a237e42;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        transform: rotate(45deg);
                        border-right: 0;
                        border-bottom: 0;
                        top: -7px;
                        left: 17px;
                      }
                    }

                    .delete-confirmation {
                      position: absolute;
                      padding: 10px;
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-start;
                      position: absolute;
                      background: #fff;
                      text-align: left;
                      border: 1px solid rgba(26, 35, 126, 0.2588235294);
                      margin-left: -5rem;
                      .btns_sec_delete {
                        display: flex;
                        gap: 5px;
                        margin-top: 6px;
                        button {
                          border: none;
                          outline: none;
                          background-color: $green-color;
                          color: $white-color;
                          font-size: 14px;
                          text-align: left;
                          display: flex;
                          gap: 5px;
                          align-items: center;
                          height: 25px;
                          padding: 4px 12px;
                          border-radius: 4px;

                          svg {
                            font-size: 14px;
                          }

                          &:first-child {
                            background-color: $red-bg;
                            border: none;
                            outline: none;
                          }
                        }
                      }
                      h5 {
                        font-family: "Inter";
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 24px;
                        text-align: center;
                      }
                      p {
                        font-family: "Inter";
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: center;
                      }

                      &::before {
                        content: "";
                        background-color: $white-color;
                        border: 1px solid #1a237e42;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        transform: rotate(45deg);

                        border-right: 0;
                        border-bottom: 0;
                        top: -7px;
                        left: 17px;
                      }
                    }
                  }
                }

                &:hover {
                  background: #ffffff45;
                }
              }
            }
          }
        }
      }
    }

    .api_test {
      background-color: $secondary-color;
      text-align: center;
      padding: 30px 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      h5 {
        margin-bottom: 0px;
      }

      svg {
        font-size: 60px;
        margin-bottom: -6px;
      }
    }
  }

  .react-responsive-modal-closeButton {
    fill: $white;
  }
}

.add_row {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
  button {
    background-color: $navy-blue-color;
    border: none;
    padding: 5px 15px;
    color: $white-color;
    border-radius: 4px;
    font-size: 14px;
  }
  span {
    color: #141a29;
  }
  .val {
    color: #141a29;
    margin-left: 5.4rem;
  }
}

.key_value_sec {
  display: block;
  position: relative;
  top: 4px;
  align-items: flex-end;
  padding-right: 15px;
  width: 100%;

  .input_sec {
    display: inline-grid;

    .title {
      margin-bottom: 15px;
    }
  }

  label {
    margin-bottom: 5px;
  }

  input {
    margin-bottom: 0px;
    width: 100%;
    background-color: $navy-blue-color;
    border: 1px solid $navy-blue-color;
    border-radius: 4px;
    color: $white-color !important;
    padding: 4px 10px;
    font-size: 13px;

    &::placeholder {
      color: $white-color;
    }

    &:focus {
      outline: none;
      border: 1px solid $active-color;
    }
  }

  .inner {
    display: flex;

    .remove_btn {
      position: relative;
      top: 10px;
      color: #141a29;

      svg {
        color: #ff6f61;
        cursor: pointer;
      }
    }
  }
}

.error {
  color: red;
  font-size: 12px;
}

h6 {
  line-height: 1;
}

.datepickermodal {
  max-width: 320px;
  width: 100%;
  padding: 0px;
  // background-color: #141a29;

  .react-responsive-modal-closeButton {
    fill: $white;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
.addScreenModal {
  width: 100%;
  padding: 34px 23px;
  background-color: $white-color;
  min-width: 812px;
  min-height: 480px;
  border-radius: 10px;
  opacity: 0px;

  .heading-screen {
    color: #283249;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    margin-left: 15px;
  }

  .projectdivs {
    padding: 20px 19px;
    label {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      text-align: left;

      color: #141a29;
      margin-bottom: 15px;
    }
  }
  .subitScreenDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 42px;
    .submitScreen {
      width: 95%;
      padding: 16px;
      text-align: center;
      border-radius: 6px;
      background-color: $navy-blue-color;

      font-family: Montserrat;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      text-align: center;
      color: #ffff;
    }
  }

  .react-responsive-modal-closeButton {
    fill: #212529;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  .css-tj5bde-Svg {
    display: inline-block;
    fill: #000000;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
  }
}
.addScreenProject {
  width: 100%;
  padding: 34px 23px;
  background-color: $white-color;
  min-width: 812px;
  min-height: 350px;
  border-radius: 10px;
  opacity: 0px;

  .heading-screens {
    color: #283249;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    margin-left: 15px;
  }

  .projectdivx {
    padding: 20px 19px;
    label {
      font-family: "Montserrat";
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      text-align: left;
      color: #141a29;
      margin-bottom: 15px;
    }
    input {
      padding: 9px 18px;
      width: 100%;
      border-radius: 6px;
      border: 1.5px solid #c5c5ff;
      outline: none;
      background-color: #f8f8ff;
      ::placeholder {
        background: #000000;
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: 300;
        line-height: 22px;
        text-align: left;
      }
    }
  }
  .subitScreenDivs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    .submitScreens {
      width: 95%;
      padding: 16px;
      text-align: center;
      border-radius: 6px;
      background-color: $navy-blue-color;

      font-family: "Montserrat";
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      text-align: center;
      color: #ffff;
    }
  }

  .react-responsive-modal-closeButton {
    fill: #212529;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  .css-tj5bde-Svg {
    display: inline-block;
    fill: #000000;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
  }
}
