.save_custom_popup {
  background-color: $white-color;
  // position: fixed;
  // left: 26rem;
  // margin-top: 150px;
  padding: 15px 10px;
  // z-index: 999;
  border-radius: 3px;
  border: 1px solid #1a237e54;
  cursor: default;

  // @media ($breakpoint_xxl) {
  //   left: 18rem;
  // }

  // @media (max-width: "1500px") {
  //   left: 18rem;
  // }
  // @media (max-width: "1390px") {
  //   left: 15rem;
  // }

  h5 {
    color: #283249;

    font-family: Inter;

    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #283249;
  }

  input {
    width: 100%;
    background-color: transparent;
    height: 26px;
    font-size: 12px;
    padding: 5px 10px;
    color: #283249 !important;
    margin-bottom: 10px;
    border: 1px solid #1a237e54;
    &:focus-visible {
      //   outline-color: $active-color;
      outline: none;
      border: 1px solid #1a237e54;
      border-radius: 3px;
    }

    &::placeholder {
      font-size: 12px;
      color: #c9c6c6;
    }
  }

  .view_btn_sec {
    display: flex;
    justify-content: space-between;
    gap: 5px;

    .save_input_data {
      font-family: Inter;
      font-size: 13px;
      font-weight: 700;
      line-height: 15.73px;
      text-align: center;

      flex-basis: 50%;
      border: none;
      background-color: $navy-blue-color;
      border-radius: 3px;
      height: 24px;
      color: $white-color;
    }

    .cancel_popup {
      flex-basis: 50%;

      font-size: 13px;
      font-weight: 700;
      line-height: 15.73px;
      text-align: center;

      border: 1px solid #1a237e54;
      background-color: transparent;
      border-radius: 3px;
      height: 24px;
      color: #3c4c6e;
    }

    .save_input_data.disabled {
      color: #ffff; /* Change text color to match disabled state */
      cursor: no-drop;
    }
  }
}
