.main_profile {
  background-color: $off-white-color;
  // background-color: $black-color;
  padding: 50px 0;
  .head_sec {
    border-bottom: 1px solid #1a237e2b;
    padding-bottom: 24px;
    .head_inner {
      display: flex;
      align-items: center;
      gap: 10px;
      color: $white-color;

      h3 {
        margin-bottom: 0px;
        font-weight: 600;
        color: #141a29;
      }

      a {
        display: flex;
        align-items: center;
        gap: 15px;
        color: #141a29;
      }
      svg {
        color: #141a29;
      }
    }
  }

  .basic_info {
    padding: 50px 48px;
    background-color: $white-color;
    margin-top: 34px;
    border-radius: 8px;

    .left_sec {
      text-align: center;
      padding-right: 1000px;
      display: flex;
      align-items: center;
      .name_email {
        text-align: left;
        padding-left: 28px;
      }
      img {
        width: 120px;
        height: 125px;
        border-radius: 100px;
        border: 5px solid $white-color;
      }
    }
    .inner_info {
      display: flex;
      color: $white-color;
      align-items: center;

      .right_sec {
        width: 100%;

        .input_sec {
          p {
            font-size: 24px !important;
            font-weight: 600 !important;
            color: #141a29;
            text-align: left;
            margin-bottom: 18px;

            margin-top: 30px;
          }
          span {
            color: #141a29;
          }
          .inner_input {
            display: flex;
            gap: 20px;
            margin-bottom: 10px;
            .fields {
              flex-basis: 100%;

              label {
                display: inline-grid;
                width: 100%;
                gap: 8px;
                color: #141a29;
                font-family: "Montserrat";
                font-size: 16px;
                font-weight: 600;
                line-height: 22px;
                text-align: left;

                input {
                  border-radius: 4px;
                  padding: 10px;
                  background-color: #f8f8ff;
                  border: 1.5px solid #1a237e1c;

                  &:focus {
                    outline: none;
                    border: 1.5px solid #1a237e1c;
                  }
                }
              }
            }
          }
        }

        .submit_btn {
          button {
            background-color: $green-color;
            padding: 8px 26px;
            border-radius: 4px;
            color: $white-color;
            border: none;
            font-family: "Montserrat";
            font-size: 20px;
            font-weight: 700;
            line-height: 22px;
          }
        }
      }
    }
  }

  .basic_info2 {
    padding: 40px 50px;

    .right_sec2 {
      flex-basis: 100% !important;

      .mt {
        margin-top: 15px;
      }
    }
  }

  .chng_pswrd {
    .inner_info {
      .right_sec {
        flex-basis: 100% !important;

        .input_sec {
          .inner_input {
            margin-top: 15px !important;

            .fields2 {
              flex-basis: 50% !important;
            }
          }
        }
      }
    }
  }

  .ac_dtls {
    .inner_info_ac {
      color: #141a29;
      display: flex;
      flex-direction: column;
      gap: 15px;

      p {
        font-size: 24px !important;
        font-weight: 600 !important;
        color: #141a29;
        text-align: left;
      }
      .details {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
        .key {
          ul {
            display: flex;
            margin-bottom: 0px;
            align-items: center;

            li {
              &:first-child {
                font-weight: 600;
                margin-right: 5px;
                font-size: 20px;
              }
            }
            span {
              font-family: "Montserrat";
              font-size: 20px;
              font-weight: 500;
              line-height: 22px;
              text-align: left;
            }
          }
        }

        .password {
          ul {
            display: flex;
            margin-bottom: 0px;
            align-items: center;
            li {
              &:first-child {
                font-weight: 600;
                margin-right: 5px;
                font-size: 20px;
              }
            }
            span {
              font-family: "Montserrat";
              font-size: 20px;
              font-weight: 500;
              line-height: 22px;
              text-align: left;
            }
          }
        }
      }
    }
  }

  .delete_ac {
    .inner_delete_ac {
      color: #141a29;

      p {
        font-size: 24px !important;
        font-weight: 600 !important;
        margin-bottom: 15px;
        color: #141a29;
        text-align: left;
      }

      .delete {
        button {
          padding: 8px 22px;
          background-color: #ff6f61;
          border-radius: 4px;
          border: none;
          color: $white-color;
          margin-top: 15px;
          font-family: "Montserrat";
          font-size: 20px;
          font-weight: 700;
          line-height: 22px;
        }
      }
    }
  }
}
