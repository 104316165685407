.main-container {
  background: #fff;
  border: 1.5px solid #c5c5ff;
  padding: 15px;
  top: 50%;
  right: 1rem;
  /* left: 48px; */
  border-radius: 6px;
  display: flex;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
  width: 100%;
  max-width: 18.5% !important;
  z-index: 99;
  flex-wrap: wrap;
  position: fixed;

  @media ($breakpoint_xxl) {
    max-width: 24% !important;
  }
  .flex-icon-txt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;

    .title_sec {
      display: flex;
      align-items: center;
      gap: 6px;

      h5 {
        color: #141a29;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0px;
      }
    }

    .cross {
      svg {
        background-color: $red-bg;
        border-radius: 30px;
        padding: 2px;
        font-size: 20px;
        cursor: pointer;
        color: $white-color;
        position: relative;
        top: -20px;
        right: -18px;
      }
    }
  }
  .p-size {
    p {
      text-align: justify;
      color: #141a29;
      font-size: 14px;
      font-weight: 300;
    }
  }
  .divide-line {
    border: 1px solid #c7c7c7;
    width: 100%;
  }
  .flx-text-input {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: -4px;
    gap: 12px;
    p {
      margin-bottom: 12px;
      color: #141a29;
      font-size: 14px;
      font-weight: 300;
    }
    input {
      width: 185px;
      /* height: 28px; */
      font-size: 14px;
      border-radius: 4px;
      background: #f8f8ff;
      border: 1.5px solid #c5c5ff;
      outline: none;
      margin-top: 14px;
      margin-bottom: 0px;
      color: #141a29 !important;
      text-align: right;
      padding: 5px;
      &::placeholder {
        font-size: 14px;
        text-align: right;
        font-weight: 400;
        line-height: 14px;
        color: #141a29;
        margin-right: 6px;
      }
    }
  }
  .divider {
    border: 1px solid #c7c7c7;
    width: 100%;
  }
  .vl-slt-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 23px;
    margin-top: 8px;
    p {
      color: #141a29;
      font-size: 12px;
      font-weight: 300;
    }
  }
  .lw-txt {
    p {
      color: #141a29;
      font-size: 10px;
      font-weight: 300;
      text-align: justify;
    }
  }

  // &::after {
  //   z-index: -99;
  //   content: "";
  //   position: absolute;
  //   width: 0;
  //   height: 0;
  //   margin-left: 0;
  //   bottom: 0;
  //   top: calc(25% - 68px);
  //   left: 147px;
  //   box-sizing: border-box;
  //   border: 5px solid $secondary-color;
  //   border-color: transparent transparent $secondary-color $secondary-color;
  //   transform-origin: 0 0;
  //   transform: rotate(134deg);
  // }
  // &::before {
  //   z-index: 10;
  //   content: "";
  //   position: absolute;
  //   width: 0;
  //   height: 0;
  //   margin-left: 0;
  //   bottom: 0;
  //   top: calc(25% - 68px);
  //   left: 147px;
  //   box-sizing: border-box;
  //   border: 5px solid $secondary-color;
  //   border-color: transparent transparent $secondary-color $secondary-color;
  //   transform-origin: 0 0;
  //   transform: rotate(134deg);
  // }
}
