.main-scroll {
  // height: 300px;
  // overflow-y: scroll;

  .scrollable-comp {
    .style-bar {
      @include accordion-setting;
      margin-top: 5px;
    }
    .layout-bar {
      margin-top: 0px;

      .main-layout {
        color: $white-color;
        .top-sec {
          display: flex;
          .left-sec {
            display: flex;
            flex-basis: 60%;
            gap: 8px;

            .img-main {
              display: flex;
              align-items: center;

              span {
                background-color: $secondary-color;
                padding: 3px 5px;
                cursor: pointer;

                .btm {
                  transform: rotate(270deg);
                }

                .rgt {
                  transform: rotate(180deg);
                }

                &:first-child {
                  border-radius: 3px 0 0 3px;
                }

                &:last-child {
                  border-radius: 0 3px 3px 0;
                  // background-color: #202634;
                }
              }
            }
          }

          .right-sec {
            @include switch-setting;
            display: flex;
            flex-basis: 40%;
            align-items: center;
            justify-content: flex-end;
            gap: 5px;
          }
        }

        .align-sec {
          display: flex;
          align-items: center;
          margin-top: 4px;
          .lt-sec {
            flex-basis: 25%;
            p {
              font-family: "Inter";
              font-size: 14px !important;
              font-weight: 400 !important;
              line-height: 14px !important;
              text-align: left;

              margin-bottom: 0px;
              width: 50px;
              color: $main-color;
            }
          }

          // .rt-sec {
          //   display: flex;
          //   gap: 2px;
          //   flex-basis: 75%;
          //   justify-content: space-between;

          //   button {
          //     border-radius: 4px;
          //     background: $secondary-color;
          //     padding: 6px 49px;
          //     color: $white-color;
          //     border: none;

          //     p {
          //       text-align: end;
          //     }
          //   }

          //   span {
          //     svg {
          //       background-color: $secondary-color;
          //       padding: 4px;
          //       font-size: 28px;
          //       width: 44px;
          //       border: 1px solid $border-color-select;
          //       cursor: pointer;

          //       @media ($breakpoint_xxl) {
          //         width: 38px;
          //       }
          //     }

          //     .brd {
          //       border-radius: 3px 0 0 3px;
          //       background-color: $main-color;

          //       .brd1 {
          //         border-radius: 0 3px 3px 0;

          //         .btm {
          //           transform: rotate(180deg);
          //         }
          //       }
          //     }
          //   }
          // }
        }

        .justify-sec {
          margin-top: 5px !important;

          .brd2 {
            // background-color: $main-color !important;
          }

          // .brd {
          //   // background-color: $secondary-color !important;

          //   &:hover {
          //     background-color: $main-color !important;
          //   }
          // }
        }
      }

      .selected-item-sec {
        border-top: 1px solid $border-color-select !important;
        padding-top: 20px;

        p {
          margin-bottom: 0px;
          text-transform: uppercase;
        }
      }

      .content-sec {
        color: $white-color;
        .wrap-sec {
          display: flex;
          align-items: center;
          .lt-sec {
            flex-basis: 25%;
            p {
              width: 50px;
              margin-bottom: 0px;
              color: #141a29;
              font-size: 14px !important;
              font-weight: 500 !important;
              line-height: 14px !important;
              text-align: left;
            }
          }
          .rt-sec {
            display: flex;
            gap: 6px;
            flex-basis: 100%;
            justify-content: flex-end;

            span {
              padding: 4px 17px;
              color: #141a29;
              font-family: "Inter";
              font-size: 12px !important;
              font-weight: 500 !important;
              line-height: 14px !important;
              text-align: left;
              display: flex;
              align-items: center;
              height: 26px;
              border-bottom: 2px solid $white-color;
              @media (max-width: 1350px) {
                padding: 6px 10px;
              }
              &.active_link {
                font-family: "Inter";
                font-size: 12px !important;
                font-weight: 600 !important;
                line-height: 14px !important;
                text-align: left;
                color: $navy-blue-color;
                border-bottom: 3px solid $navy-blue-color;
              }
              &.no_active_link {
                border-bottom: 3px solid $white-color;
              }
            }
          }
        }

        .align-sec {
          display: flex;
          align-items: center;
          margin-top: 6px;
          .lt-sec {
            flex-basis: 25%;
            p {
              width: 50px;
              margin-bottom: 0px;
              color: #141a29;
            }
          }

          .rt-sec {
            display: flex;
            flex-basis: 100%;
            justify-content: flex-end;
            span {
              svg {
                background-color: $white-color;
                padding: 4px;
                font-size: 28px;
                width: 37px;
                cursor: pointer;
                border-radius: 3px;
                height: 26px;
                outline: none;
                border: none;
                color: $navy-blue-color;
                &:hover {
                  color: $white-color;
                  // background-color: $navy-blue-color;
                }

                @media (max-width: 1399px) {
                  width: 31px;
                }
              }
            }
          }
        }
      }
    }
    .background-bar {
      margin-top: 0px;
      .main-background {
        color: #141a29;
        .flex-side {
          display: flex;

          button {
            width: 36px;
            height: 36px;
            border-radius: 8px;
            border: 1px solid #283249;
            select {
              background: #283249;
              border-radius: 5px;
              width: 148px;
              height: 36px;
              border: none;
              color: white;
            }
          }
        }
      }
    }
    .size-bar {
      margin-top: 0px;
      .init_size {
        .main-size {
          display: flex;
          gap: 12px;
          justify-content: space-between;
          align-items: center;
          // margin-right: 10px !important;

          // @media (max-width: "1500px") {
          //   margin-right: 0px !important;
          // }
          @media (max-width: 1500px) {
            gap: 2px;
          }
        }
      }
    }
  }
}

.MuiBox-root.css-8atqhb {
  .css-1kt0o6l {
  }
}

.css-19kzrtu {
  padding: 0px 0px !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: transparent !important;
}

.css-1t4lqmc-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #fff !important;
  background: #283249;
}

.main-right .inner .setting-bar {
  color: transparent !important;
}

.css-1t4lqmc-MuiButtonBase-root-MuiTab-root {
  color: rgb(255 255 255 / 60%);
}
