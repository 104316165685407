.save-view-popup {
  background-color: $navy-blue-color;
  border-radius: 3px;
  width: 164px;
  z-index: 100;
  border-radius: 3px;
  cursor: default;
  top: 21px !important;
  @media (max-width: 1599px) {
    width: 164px;
  }
  .shareOptions {
    background-color: $navy-blue-color;
    padding: 2px 8px;
    border-radius: 6px;
    width: 100%;
    @media (max-width: 1599px) {
      width: 100%;
    }

    @media (max-width: 1499px) {
      position: absolute;
      right: -64px;
      width: 100%;
    }
    @media (max-width: 1399px) {
      width: 100%;
      position: absolute;
      right: -8px;
    }
    .arrowsvgs {
      position: absolute;
      top: -14px;
      right: 29px;

      @media (max-width: 1499px) {
        width: 34%;
        right: 24px;
      }
    }
    svg path {
      stroke: $navy-blue-color !important;
      fill: $navy-blue-color !important;
    }

    .shareUls {
      margin-bottom: 10px;
      .shareLis {
        color: #141a29;
        margin-bottom: 0px;
        padding: 4px 8px;
        background-color: #fff;
        margin-top: 10px;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        svg path {
          stroke: #3c4c6e;
          fill: #3c4c6e;
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .save_custom_btn {
  }

  .duplicate_btn {
  }

  .delete_btn {
  }

  .close_popup {
    svg {
      background-color: red;
      color: #fff;
      border: none;
      border-radius: 50px;
      position: absolute;
      right: -5px;
      font-size: 16px;
      top: -5px;
      cursor: pointer;
    }
  }
}
