.bg_sky {
  background: $gradient-bg-color;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 30px;

  // @media ($breckpoint_MD) {
  //   flex-wrap: wrap;
  // }
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;

    @media ($breckpoint_LG) {
      gap: 20px;
    }
    @media ($breckpoint_MD) {
      flex-direction: column;
      
    }

    .box-1 {
      flex-basis: 50%;

      span {
        color: $active-color;
        font-family: "Montserrat";
        font-size: 16px;
        font-weight: 600;
      }

      h2 {
        color: $black-color;
        font-family: "Montserrat";
        font-size: 49px;
        font-weight: 700;
        letter-spacing: -1.44px;
        text-transform: capitalize;
        @media ($breakpoint_xxm) {
          font-size: 40px;
        }
     
        // @media ($breakpoint_XXN) {
        //   font-size: 35px;
        // }

      }
        
      p {
        color: $skyblue-color;
        font-family: "Montserrat";
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.28px;
        text-transform: capitalize;
        line-height: 30px !important;
       

        @media ($breckpoint_MD) {
          font-size: 12px;
          letter-spacing: 0.20px;
        }
    }
   
      
    }

    .bg-img {
      flex-basis: 50%;
      display: flex;
      justify-content: right;

      img {
        max-width: 100%;
      }
    }
  }
}