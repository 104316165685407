.versionWrapper {
  .versionProject {
    color: #141a29;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 600;
    line-height: 14px;
    text-align: left;
    margin-bottom: 20px;
    padding: 20px;
  }
  .selectDiv {
    padding: 0px 20px;
  }
  .versionDetail {
    margin: 15px 0px;

    .pNAme {
      color: #1a237e;
      font-size: 14px;
      font-weight: 300;
      padding: 0px 20px;
      text-align: left;
    }
  }
  .versionsMainDiv {
    height: 450px;
    overflow: scroll;

    @media (max-height: 700px) {
      height: 300px;
    }
    @media (max-height: 620px) {
      height: 180px;
    }
    .versionTime {
      position: relative;
      margin: 15px 0px;
      padding: 4px 20px;
      cursor: pointer;

      .p1 {
        color: #141a29;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 6px;
        text-align: left;
      }
      .p2 {
        color: #141a29;
        font-size: 16px;
        font-weight: 300;
        font-style: italic;
        margin-bottom: 6px;
        text-align: left;
      }
      .p3 {
        color: #141a29;
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 6px;
        text-align: left;
      }
      .p4 {
        text-align: left !important;
        span {
          color: #141a29;
        }
      }
      .dotsDiv {
        position: absolute;
        right: 0px;
        top: -18px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .dotdiv {
          padding: 5px 10px;
        }

        .dots3 {
          background-color: #3f4144;
          border-radius: 8px;
          padding: 8px;
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 4px;
          p {
            cursor: pointer;
            color: #141a29;
            text-align: left;
          }
        }
      }
    }
    .versions {
      position: relative;
      margin: 15px 0px;
      background-color: #c1c1c2;
      padding: 4px 20px;
      cursor: pointer;
      .p1 {
        color: #141a29;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 6px;
        text-align: left;
      }
      .p2 {
        color: #141a29;
        font-size: 16px;
        font-weight: 300;
        font-style: italic;
        margin-bottom: 6px;
        text-align: left;
      }
      .p3 {
        color: #141a29;
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 6px;
        text-align: left;
      }
      .p4 {
        text-align: left !important;

        span {
          color: #141a29;
          text-align: left;
        }
      }
      .dotsDiv {
        position: absolute;
        right: 0px;
        top: -18px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .dotdiv {
          padding: 10px;
        }

        .dots3 {
          background-color: #3f4144;
          border-radius: 8px;
          padding: 8px;
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 4px;
          p {
            cursor: pointer;
            color: #141a29;
            text-align: left;
          }
        }
      }
    }
    .versionss {
      position: relative;
      margin: 15px 0px;
      // background-color: #c1c1c2;
      padding: 4px 20px;
      cursor: pointer;
      .p1 {
        color: #141a29;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 6px;
        text-align: left;
      }
      .p2 {
        color: #141a29;
        font-size: 16px;
        font-weight: 300;
        font-style: italic;
        margin-bottom: 6px;
        text-align: left;
      }
      .p3 {
        color: #141a29;
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 6px;
        text-align: left;
      }
      .p4 {
        text-align: left !important;

        span {
          color: #141a29;
          text-align: left;
        }
      }
      .dotsDiv {
        position: absolute;
        right: 0px;
        top: -18px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .dotdiv {
          padding: 10px;
        }

        .dots3 {
          background-color: #3f4144;
          border-radius: 8px;
          padding: 8px;
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 4px;
          p {
            cursor: pointer;
            color: #141a29;
            text-align: left;
          }
        }
      }
    }
  }
}
