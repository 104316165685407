.section_fourth {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;

  @media ($breckpoint_MD) {
    flex-wrap: wrap;
  }

  .left {
    flex-basis: 50%;
    padding-top: 28px;

    @media ($breckpoint_MD) {
      flex-basis: 100%;
    }

    .box-1 {
      color: #00c;
      font-family: "Montserrat";
      font-size: 20px;
      font-weight: 600;
      padding: 12px 8px;
    }

    .box-2 {
      color: $black-color;
      font-family: "Montserrat";
      font-size: 61px;
      font-weight: 700;
      letter-spacing: -1.44px;
      text-transform: capitalize;

      @media ($breakpoint_xxm) {
        font-size: 40px;
      }
      @media ($breakpoint_XXN) {
        font-size: 35px;
      }
    }
    .box-3 p {
      color: $skyblue-color;
      font-family: "Montserrat";
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.24px;
      text-transform: capitalize;
    }
    .box-4 .big-size {
      color: $black-color;
      font-family: "Montserrat";
      font-size: 35px;
      font-weight: 600;
      text-transform: capitalize;
      margin-right: 6px;
    }
    .box-4 .small-size {
      color: $black-color;
      font-family: "Montserrat";
      font-size: 28px;
      font-weight: 600;
      text-transform: capitalize;

      @media($breckpoint_SM){
        font-size: 24px;
      }
    }
    .box-4 .bar_blue {
      color: $gradient-color;
      width: 70%;
    }
    .box-4 .bar_yellow {
      width: 70%;
    }
    .box-4 h5 {
      color: $lightblue-color;
      font-family: "Montserrat";
      font-size: 18px;
      font-weight: 500;
      text-transform: capitalize;
      margin-top: 12px;
    }
  }

  .right {
    flex-basis: 50%;

    @media ($breckpoint_MD) {
      flex-basis: 100%;
    }
  }
  .right img {
    max-width: 100%;
  }
}
